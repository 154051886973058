// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase,
  enableLogging
} from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// const appDev = initializeApp({
//     apiKey: "AIzaSyDAEvtCIIL61gVMu6MWuG3daOTMvrFveQs",
//     authDomain: "tga-remote-input.firebaseapp.com",
//     projectId: "tga-remote-input",
//     storageBucket: "tga-remote-input.appspot.com",
//     messagingSenderId: "612907850547",
//     appId: "1:612907850547:web:aff77464a11b3e4e62fc46",
//     measurementId: "G-P8L0012DMS",
    
//     databaseURL: "https://tga-remote-input-default-rtdb.firebaseio.com/"
// });

const appProd = initializeApp({
    apiKey: "AIzaSyDAEvtCIIL61gVMu6MWuG3daOTMvrFveQs",
    authDomain: "tga-remote-input.firebaseapp.com",
    projectId: "tga-remote-input",
    storageBucket: "tga-remote-input.appspot.com",
    messagingSenderId: "612907850547",
    appId: "1:612907850547:web:aff77464a11b3e4e62fc46",
    measurementId: "G-P8L0012DMS",
    
    databaseURL: "https://tga-remote-input.firebaseio.com/"
}, 'appProd');

const appData = initializeApp({
    apiKey: "AIzaSyDAEvtCIIL61gVMu6MWuG3daOTMvrFveQs",
    databaseURL: "https://tga-remote-input-6cf3f.asia-southeast1.firebasedatabase.app/"
  }, 'appData');


// enableLogging(true);
//which app instance / database is it logging though?
// turn off before submitting to app store

export const database = getDatabase(appProd);

export const databaseData = getDatabase(appData);


// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(appProd);