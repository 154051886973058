// later for each of parties, drag expos, and cruises and tours.. you are going to need to have logic where it's one or two...

import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';


import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonMenuButton
} from '@ionic/react';

import {
    globeOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    tvOutline,
    logoYoutube,
    logoSoundcloud,
    alertCircle,
    logoTiktok,
    arrowBack,
    calendarOutline,
    timeOutline,
    arrowForwardOutline,
    callOutline,
    chevronBackOutline,
    chevronForwardOutline,
    close
} from 'ionicons/icons';

// import NewWindP from './NewWindP';
import MapViewMultiC from './MapViewMultiC';


import '../theme/windowstyle.css';
import '../theme/newWindow.css';

import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';




const NewWindPP: React.FC<{
    selectedPerson: any;
    setSelectedPerson: any;
    selectedPersonInd: any;
    filteredPeopleLn: any;
    handleNextPP: any;
    handlePrevPP: any;
    people: any[];
    // gridNo: any;
    parties: any[];
    partiesCirc: any;
    dragExpos: any;
    dragTours: any[];
    cruises: any;
    // likedPeople: any;
    // setLikedPeople: any;
    // handleIncrement: any;
    // handleDecrement: any;
    citySelected: any;

    barsAndClubs: any;
    peopleOth: any;
    currentDateAndTime: any;
    handleIncrClick: any;

    handleCloseWindPP:any;
    handleChangePerson: any;
}> = (props) => {

    //when i handleCloseWindPP and im in a second or third window does this need to reset anything else?

    const [partiesPP, setPartiesPP] = useState<any[]>([]);
    const [partiesPPC, setPartiesPPC] = useState<any[]>([]);
    const [dragExposPP, setDragExposPP] = useState<any[]>([]);
    const [dragToursPP, setDragToursPP] = useState<any[]>([]);
    const [cruisesPP, setCruisesPP] = useState<any[]>([]);

    // const [isRepPartyOpenPP, setIsRepPartyOpenPP] = useState<any>(false);
    const [selectedPartyRepPP, setSelectedPartyRepPP] = useState<any>(null);

    // const [isRepDTOpenPP, setIsRepDTOpenPP] = useState<any>(false);
    const [selectedDTRepPP, setSelectedDTRepPP] = useState<any>(null);

    const [selectedDERepPP, setSelectedDERepPP] = useState<any>(null);

    const [selectedCRepPP, setSelectedCRepPP] = useState<any>(null);



    const [isRepBCOpenPP, setIsRepBCOpenPP] = useState(false);
    const [selectedBCRepPP, setSelectedBCRepPP] = useState<any>(null);
    const [imgOrMapPP, setImgOrMapPP] = useState<any>("img");


    const mapContainerPP = useRef<any>(null);

    const handleChangePersonPart2 = (selPerson: any) => {
        props.handleChangePerson(selPerson);
        setSelectedPartyRepPP(null);
        setSelectedDTRepPP(null);
        setSelectedDERepPP(null);
        setSelectedCRepPP(null);
        setImgOrMapPP("img");
    }

    const handleCloseWindowPart2 = () => {
        //only party wind rep doesnt need to be closed technically because the component gets unmounted therefore resetted the selected rep party.
        props.handleCloseWindPP;
        setSelectedDTRepPP(null);
    }


    const handleImgOrMapPP = (viewClicked: string) => {
        if (viewClicked === imgOrMapPP) return;
        if (viewClicked === "img") setImgOrMapPP("img");
        if (viewClicked === "map") setImgOrMapPP("map");
      };
      //where you go to people-->party-->BC this then covers 



    const handleOpenWindPartyRepPP = (party:any) => {
        setSelectedPartyRepPP(party);
        setSelectedDTRepPP(null);
        setSelectedDERepPP(null);
        setSelectedCRepPP(null);
        // setImgOrMap("img");
        // dont think above needs to be explicitly set. cf newwindP. yeh it doesnt because newwindP will set itself (which will also cover going to third BC rep window)
        // setIsRepPartyOpenPP(true);
      }

      const handleOpenWindDTRepPP = (party:any) => {
        setSelectedDTRepPP(party);
        setSelectedPartyRepPP(null);
        setSelectedDERepPP(null);
        setSelectedCRepPP(null);
        // setImgOrMap("img");
        // dont think above needs to be explicitly set. cf newwindP. yeh it doesnt because newwindP will set itself (which will also cover going to third BC rep window)
        // setIsRepPartyOpenPP(true);
      }

      const handleOpenWindDERepPP = (party:any) => {
        // console.log('yess');
        setSelectedDERepPP(party);
        setSelectedDTRepPP(null);
        setSelectedPartyRepPP(null);
        setSelectedCRepPP(null);
        // setImgOrMap("img");
        // dont think above needs to be explicitly set. cf newwindP. yeh it doesnt because newwindP will set itself (which will also cover going to third BC rep window)
        // setIsRepPartyOpenPP(true);
      }

      const handleOpenWindCRepPP = (party:any) => {
        setSelectedCRepPP(party);
        setSelectedDERepPP(null);
        setSelectedDTRepPP(null);
        setSelectedPartyRepPP(null);
      }

    const handleClBackWindPartyRepPP = () => {
        // setIsRepPartyOpenPP(false);
        // setImgOrMapRepPartyPP("img");
        setSelectedPartyRepPP(null);
        setImgOrMapPP("img"); 
      }

      const handleClBackWindDTRepPP = () => {
        // setIsRepPartyOpenPP(false);
        // setImgOrMapRepPartyPP("img");
        setSelectedDTRepPP(null);
        setImgOrMapPP("img"); 
      }

      const handleClBackWindDERepPP = () => {
        // setIsRepPartyOpenPP(false);
        // setImgOrMapRepPartyPP("img");
        setSelectedDERepPP(null);
        setImgOrMapPP("img"); 
      }

      const handleClBackWindCRepPP = () => {
        // setIsRepPartyOpenPP(false);
        // setImgOrMapRepPartyPP("img");
        setSelectedCRepPP(null);
        setImgOrMapPP("img"); 
      }

      const handleClBackWindBCRepPP = () => {
        setIsRepBCOpenPP(false);
        setSelectedBCRepPP(null);
        setImgOrMapPP("img"); 
      }

      const handleOpenWindBCRepPP = (barclub:any) => {
        setSelectedBCRepPP(barclub);
        setImgOrMapPP("img");
        //i think the above only needs to be set since shared between party wind and rep BC wind. so if you're in map in party wind and you go to BC wind then it will show image first.
        setIsRepBCOpenPP(true);
      }



      const handleKeyDownPP = (e:any) => {
        if (isRepBCOpenPP || selectedPartyRepPP || selectedDTRepPP || selectedDERepPP || selectedCRepPP) {
            return;
        }
        console.log(e.key, 'key code');
        if (e.key === "ArrowLeft") {
            // setImgOrMapPP("img");
            props.handlePrevPP();
        }
        if (e.key === "ArrowRight") {
            console.log("arrow right key")
            // setImgOrMapPP("img");
            props.handleNextPP();
            
        }
        
      }

      const handleClickBackPP = (e:any) => {
        e.stopPropagation();
        props.handlePrevPP();
      }

      const handleClickFwdPP = (e:any) => {
        e.stopPropagation();
        props.handleNextPP();
      }





      const handleClickLinkPP = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedPerson.website, '_system', 'location=yes');
        props.handleIncrClick("people", props.selectedPerson.hash);
    }

    const handleClickLinkPPParty = (e:any) => {
        e.stopPropagation();
        window.open(selectedPartyRepPP.website, '_system', 'location=yes');
        props.handleIncrClick("party", selectedPartyRepPP.hash);
    }

    const handleClickLinkPPDT = (e:any) => {
        e.stopPropagation();
        window.open(selectedDTRepPP.website, '_system', 'location=yes');
        props.handleIncrClick("dragtours", selectedDTRepPP.hash);
    }

    const handleClickLinkPPDE = (e:any) => {
        e.stopPropagation();
        window.open(selectedDERepPP.website, '_system', 'location=yes');
        props.handleIncrClick("dragexpos", selectedDERepPP.hash);
    }

    const handleClickLinkPPC = (e:any) => {
        e.stopPropagation();
        window.open(selectedCRepPP.website, '_system', 'location=yes');
        props.handleIncrClick("dragexpos", selectedCRepPP.hash);
    }

    const handleClickLinkPPBC = (e:any) => {
        e.stopPropagation();
        window.open(selectedBCRepPP.website, '_system', 'location=yes');
        // props.handleIncrClick("party", selectedPartyRepPP.hash);
    }




    const handleClickLinkPPSC = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.soundcloud.com/${props.selectedPerson?.soundcloud}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

    const handleClickLinkPPInst = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${props.selectedPerson?.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

    const handleClickLinkPPInstBC = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${selectedBCRepPP.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

    const handleClickLinkPPTT = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.tiktok.com/@${props.selectedPerson?.spotify}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }


    


    useEffect(() => {
        const dataPartiesPP:any = [];
        for (let i = 0; i < props.parties.length; i++) {
            if (
                props.parties[i].entertainers !== ""
                &&
                (props.parties[i].entertainers.some((entertainer:any) => entertainer === props.selectedPerson.name))
                &&
                (dataPartiesPP.indexOf(props.parties[i] === -1))
            ) {
                dataPartiesPP.push(props.parties[i])
            }
        }
        setPartiesPP(dataPartiesPP);

        const dataPartiesPPC:any = [];
        for (let i = 0; i < props.partiesCirc.length; i++) {
            if (
                props.partiesCirc[i].entertainers !== ""
                &&
                (props.partiesCirc[i].entertainers.some((entertainer:any) => entertainer === props.selectedPerson.name))
                &&
                props.partiesCirc[i].facebook !== "cs"
                //not including 'coming soon' parties here
                &&
                (dataPartiesPPC.indexOf(props.partiesCirc[i] === -1))
            ) {
                dataPartiesPPC.push(props.partiesCirc[i])
            }
        }
        setPartiesPPC(dataPartiesPPC);
        
        const dataDragExposPP:any = [];
        for (let i = 0; i < props.dragExpos.length; i++) {
            if (
                props.dragExpos[i].entertainers !== ""
                &&
                (props.dragExpos[i].entertainers.some((entertainer:any) => entertainer === props.selectedPerson.name))
                &&
                (dataDragExposPP.indexOf(props.dragExpos[i] === -1))
            ) {
                dataDragExposPP.push(props.dragExpos[i])
            }
        }
        setDragExposPP(dataDragExposPP);

        const dataDragToursPP:any = [];
        for (let i = 0; i < props.dragTours.length; i++) {
            if (
                props.dragTours[i].entertainers !== ""
                &&
                (props.dragTours[i].entertainers.some((entertainer:any) => entertainer === props.selectedPerson.name))
                &&
                (dataDragToursPP.indexOf(props.dragTours[i] === -1))
            ) {
                dataDragToursPP.push(props.dragTours[i])
            }
        }
        setDragToursPP(dataDragToursPP);

        const dataCruisesPP:any = [];
        for (let i = 0; i < props.cruises.length; i++) {
            if (
                props.cruises[i].entertainers !== ""
                &&
                (props.cruises[i].entertainers.some((entertainer:any) => entertainer === props.selectedPerson.name))
                &&
                (dataCruisesPP.indexOf(props.cruises[i] === -1))
            ) {
                dataCruisesPP.push(props.cruises[i])
            }
        }
        setCruisesPP(dataCruisesPP);


      }, [props.parties, props.partiesCirc, props.dragExpos, props.dragTours, props.cruises, props.selectedPerson]);

    //   still need to add drag tours


    useEffect(() => {

        

        if (imgOrMapPP !== "map") {
            return
        }

        // const mapObj = isRepBCOpenPP ? selectedBCRepPP : selectedPartyRepPP;
        let mapObj: any;
        if (selectedPartyRepPP && !isRepBCOpenPP) {
            mapObj = selectedPartyRepPP;
        } else if (selectedDTRepPP && !isRepBCOpenPP) {
            mapObj = selectedDTRepPP;
        } else if (selectedDERepPP && !isRepBCOpenPP) {
            mapObj = selectedDERepPP;
        } else if (selectedCRepPP && !isRepBCOpenPP) {
            return;
            //using multiMapC component now
        } else if (isRepBCOpenPP) {
            mapObj = selectedBCRepPP;
        }

        //below was when i was doing cruise map as well
        // const cntr:any = (selectedCRepPP && !isRepBCOpenPP) ? [mapObj.start_destination, mapObj.finish_destination] : [mapObj.lng, mapObj.lat];
        // const zm = (selectedCRepPP && !isRepBCOpenPP) ? mapObj.timeframe : 13;

        const mapP = new mapboxgl.Map({
            container: mapContainerPP.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [mapObj.lng, mapObj.lat],
            zoom: 13,
            attributionControl: false
        });

        

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([mapObj.lng, mapObj.lat]).addTo(mapP);

        

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapP(mapP);

        return () => mapP.remove();

    },[imgOrMapPP]);


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDownPP);
    
        return function () {
            document.removeEventListener('keydown', handleKeyDownPP);
            console.log('key down listener removed!!!');
        };
    }, [props.selectedPersonInd, props.filteredPeopleLn, isRepBCOpenPP, selectedPartyRepPP, selectedDTRepPP, selectedDERepPP, selectedCRepPP]);
//review dependencies


    return(
        <>
        {
        (!selectedPartyRepPP && !selectedDTRepPP && !selectedDERepPP && !selectedCRepPP)
        &&
        <div className="testwindowabs">
            <div className="windowcontainerNWwitharr">

                                                         <div className="clsabvarr">
                                                                        <div className="abvarrcls"
                                                                                onClick={props.handleCloseWindPP}
                                                                                
                                                                                >
                                                                                        <IonIcon icon={close} className="clsicon"></IonIcon>
                                                                                </div>
                                                                </div>
            <div className="arrowcontl"
                                onClick={props.handleCloseWindPP}
                                >
                                   
                                    <div
                                    className={props.selectedPersonInd === 0 ? "arrowcirc csrnot": "arrowcirc"}
                                    onClick={handleClickBackPP}
                                    
                                    >
                                            <IonIcon icon={chevronBackOutline}
                                            className={props.selectedPersonInd === 0 ? "arricn opac3" : "arricn"}
                                            ></IonIcon>
                                    </div>
                                
            </div>
            <div className="contmiddle">
                <div className="newwinimgcont">


                                                
                {
                    
                    props.selectedPerson?.image_src
                    &&
                    <img className="windowimgboxNW" src={props.selectedPerson?.image_src}
                    onError={(e: any) => e.target.src = props.selectedPerson?.image_url}
                    ></img>
                }
                {
                    
                    !props.selectedPerson?.image_src
                    &&
                    <img className="windowimgboxNW" src={props.selectedPerson?.image_url}
                    ></img>
                }


                                {/* {
                                    isRepPartyOpenPP
                                    &&
                                    <div className="segmentconrepP"
                                            onClick={handleClBackWindPartyRepPP}
                                            >
                                                <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                </div>
                                } */}

                


                



            </div>
            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedPerson?.name}
                                    </div>
                                </div>

                            {
                            (props.selectedPerson.description !== "" || props.selectedPerson.extra_info !== "")
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {props.selectedPerson?.description}
                                    </div>
                                    {
                                    props.selectedPerson.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{props.selectedPerson.extra_info}</div>
                                    </div>
                                    }
                            </div>
                            }

                            {/* could put tv shows at bottom of about section */}


                        {
                            props.selectedPerson?.tv_shows.length > 0
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Credits
                                    </div>  
                                    {
                                        props.selectedPerson?.tv_shows.map((ele: any, index: number) => {
                                            return <div key={index} className="tvshowlistcont">
                                                        <div className="tvshowlistitem">
                                                            <IonIcon icon={tvOutline} size="small" color="primary" />
                                                        </div>
                                                        <div className="tvshowlistitem">{ele}</div>
                                                </div>
                                        })
                                    }
                            </div>
                            }

                        

                            
                            
                            

                            {/* {
                            props.parties.filter(party => party.entertainers !== "").filter(party => party.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            props.dragExpos.filter((dragexpo: any) => dragexpo.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            props.cruises.filter((cruise: any) => cruise.entertainers !== "").filter((cruise: any) => cruise.entertainers.some((entertainer: any) => entertainer === selectedPerson.name)).length > 0
                            &&
                            <div className="partywindowheading">
                                Upcoming appearances
                            </div>
                            } */}

                            {
                            partiesPP.length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Parties{` (${props.citySelected})`}
                            </div>
                            }
                            {/* note how this heading is within the original main section hence slightly different code than below */}
                            </div>
                            {/* since ive said upcoming maybe remove those that have started? or not..but just dont bother with live thing. */}

                            {
                            (partiesPP.length > 0)
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                            >
                                        {
                                        partiesPP.map((party, index) => {
                                            return <div key={index} className="gridviewcardstd"
                                            onClick={() => handleOpenWindPartyRepPP(party)}
                                            >
                                                            {
                                                                party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_src} alt="" 
                                                                onError={(e: any) => e.target.src = party.image_url}

                                                                />

                                                            }
                                                             {
                                                                !party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_url} alt="" 
                                                                />

                                                            }       
                                                                    <div className={party.tickets_available ? "gridviewcardimagecover" : "gridviewcardimagecoversoldout"}>

                                                                        {
                                                                        (party.tickets_available && party.price > 0 && party.price !== 999)
                                                                        &&
                                                                        <div className="gridviewpricebubblealt"> 
                                                                            <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price}
                                                                        </div>
                                                                        }
                                                                        {/* i havent changed other cards yet (ie those in today row) */}
                                                                        {
                                                                        party.tickets_available && party.price === 0
                                                                        &&
                                                                        <div className="gridviewpricebubblefree"> 
                                                                            Free
                                                                        </div>
                                                                        }
                                                                        {
                                                                        !party.tickets_available
                                                                        &&
                                                                        <div className="gridviewpricebubblesoldout"> 
                                                                            Sold out
                                                                        </div>
                                                                        }

                                                                        <div className="gridviewbottomcontainer">
                                                                        <div className="gridviewtitlecontainer">
                                                                                {
                                                                                    party.name_short === ""
                                                                                    &&
                                                                                    <div className="gridviewtitlemodp">{party.name}</div>
                                                                                }
                                                                                {
                                                                                    party.name_short !== ""
                                                                                    &&
                                                                                    <div className="gridviewtitlemodp">{party.name_short}</div>
                                                                                }
                                                                        </div>
                                                                            <div className="gridviewtimecontainer">
                                                                                <div className="gridviewtimebubble">
                                                                                    {/* <div className="gridviewtimebubbletop">{party.start_date}{" "}{party.start_month.slice(0,3)}</div>
                                                                                    <div className="gridviewtimebubblebot">{party.start_time}</div> */}
                                                                                    <div className="gridviewtimebubbletop">{party.start_date}</div>
                                                                                    <div className="gridviewtimebubblemon">{party.start_month.slice(0,3)}</div>
                                                                                </div> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                        })}
                            </div>
                            }
                            

                            




                            {
                            partiesPPC.length > 0
                            &&
                            <div className="windowmainsetwidthNW">
                                
                                    <div className="windowmainheadingstd">
                                    Circuit Festivals
                                    </div>
                                
                             </div>
                            }
                            {
                            (partiesPPC.length > 0)
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                            >
                                        {
                                        partiesPPC.map((party, index) => {
                                            return <div key={index} className="gridviewcardstd"
                                            onClick={() => handleOpenWindPartyRepPP(party)}
                                            >
                                                            {
                                                                party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_src} alt="" 
                                                                onError={(e: any) => e.target.src = party.image_url}

                                                                />

                                                            }
                                                             {
                                                                !party.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={party.image_url} alt="" 
                                                                />

                                                            }       
                                                                    <div className={party.tickets_available ? "gridviewcardimagecover" : "gridviewcardimagecoversoldout"}>

                                                                    <div className="gridviewpricebubblerev"> 
                                                                        {party.city}
                                                                    </div>


                                                                    {
                                                                    (party.tickets_available && party.price > 0 && party.price !== 999)
                                                                    &&
                                                                    <div className="gridviewpricebubblealt"> 
                                                                        {/* <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price} */}
                                                                        {party.currency_symbol}{party.price}+
                                                                    </div>
                                                                    }
                                                                    {/* i havent changed other cards yet (ie those in today row) */}
                                                                    {
                                                                    party.tickets_available && party.price === 0
                                                                    &&
                                                                    <div className="gridviewpricebubblefree"> 
                                                                        Free
                                                                    </div>
                                                                    }
                                                                    {
                                                                    !party.tickets_available
                                                                    &&
                                                                    <div className="gridviewpricebubblesoldout"> 
                                                                        Sold out
                                                                    </div>
                                                                    }

                                                                        <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontainer">
                                                                                {/* currently dont need time logic that you have above for normal parites because I THINK (check) circ parties drop off as soon as they start */}
                                                                                {/* <div className="gridviewtitle">{party.name}</div> */}
                                                                                {
                                                                                    party.name_short === ""
                                                                                    &&
                                                                                    <div className="gridviewtitlemodp">
                                                                                    {party.name}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    party.name_short !== ""
                                                                                    &&
                                                                                    <div className="gridviewtitlemodp">{party.name_short}</div>
                                                                                }
                                                                            </div>
                                                                            <div className="gridviewtimecontainer">
                                                                                <div className="gridviewtimebubble">
                                                                                    {/* <div className="gridviewtimebubbletop">{party.start_date}{" "}{party.start_month.slice(0,3)}</div>
                                                                                    <div className="gridviewtimebubblebot">{party.start_time}</div> */}
                                                                                    <div className="gridviewtimebubbletop">{party.start_date}{" "}</div>
                                                                                    <div className="gridviewtimebubblemon">{party.start_month.slice(0,3)}</div>
                                                                                </div> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>         
                                        })}
                            </div>
                            }
                            

                            {/* should really just combine these headers and then content conditionals */}
                            {
                            dragExposPP.length > 0
                            &&
                            <div className="windowmainsetwidthNW">
                                
                                    <div className="windowmainheadingstd">
                                    Drag Cons
                                    </div>
                                
                             </div>
                            }

                            {
                            (dragExposPP.length > 0)
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                            >
                                {
                                dragExposPP.map((dragexpo: any, index: any) => {
                                        return <div key={index} className="gridviewcardstd"
                                        onClick={() => handleOpenWindDERepPP(dragexpo)}
                                        >
                                                            {
                                                                dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                onError={(e: any) => e.target.src = dragexpo.image_url}
                                                                />
                                                            }
                                                            {
                                                                !dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_url} alt=""
                                                                />
                                                            }
                                                                    
                                                                    
                                                                    <div className="gridviewcardimagecover">
                                                                        
                                                                        <div className="gridviewsuburbbubble">
                                                                            {dragexpo.datestring}
                                                                            {/* {dragexpo.finish_year.slice(-2)} */}
                                                                        </div>

                                                                        <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontaineroth">
                                                                                <div className="gridviewtitlemodp">{dragexpo.name}</div>
                                                                            </div>
                                                                            <div className="gridviewtimecontaineroth">
                                                                                {/* im reusing the formatting from in progress events below... */}
                                                                                <div className="gridviewtimebubblelive">
                                                                                    <div className="livefrom">from</div>
                                                                                    <div className="colorpi">{dragexpo.currency_symbol}{dragexpo.starting_price}</div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
        
                                        })
                                }
                             </div>
                            }

                            
                            {
                            dragToursPP.length > 0
                            &&
                            <div className="windowmainsetwidthNW">
                                
                                    <div className="windowmainheadingstd">
                                    Drag Tours
                                    </div>
                                
                             </div>
                            }

                            {
                            (dragToursPP.length > 0)
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                            >
                                {
                                dragToursPP.map((dragexpo: any, index: any) => {
                                        return <div key={index} className="gridviewcardstd"
                                        onClick={() => handleOpenWindDTRepPP(dragexpo)}
                                        >
                                                            {
                                                                dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_src} alt=""
                                                                onError={(e: any) => e.target.src = dragexpo.image_url}
                                                                />
                                                            }
                                                             {
                                                                !dragexpo.image_src
                                                                &&
                                                                <img className="gridviewcardimage" src={dragexpo.image_url} alt=""
                                                                />
                                                            }       
                                                                    <div className="gridviewcardimagecover">
                                                                        
                                                                        {/* <div className="gridviewsuburbbubble">
                                                                            {dragexpo.datestring}{" '"}{dragexpo.finish_year.slice(-2)}
                                                                        </div> */}

                                                                        <div className="gridviewdatebubble">
                                                                        {/* {dragexpo.datestring}{" '"}{dragexpo.finish_year.slice(-2)} */}
                                                                            <div className="topdate">{dragexpo.start_date}</div>
                                                                            
                                                                            <div className="botdate">{dragexpo.start_month.slice(0,3)}</div>

                                                                        {/* <div>{"'"}{dragexpo.start_year.slice(-2)}</div> */}
                                                                    </div>

                                                                        <div className="gridviewbottomcontainer">
                                                                            <div className="gridviewtitlecontaineroth">
                                                                                <div className="gridviewtitlemodp">{dragexpo.name}</div>
                                                                            </div>
                                                                            <div className="gridviewtimecontaineroth">
                                                                                {/* im reusing the formatting from in progress events below... */}
                                                                                <div className="gridviewtimebubblelive">
                                                                                    <div className="livefrom">from</div>
                                                                                    <div className="colorpi">{dragexpo.currency_symbol}{dragexpo.starting_price}</div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                        })
                                }
                             </div>
                            }

                            
                            {
                                cruisesPP.length > 0
                                &&
                                <div className="windowmainsetwidthNW">
                                    <div className="windowmainheadingstd">
                                    Cruises
                                    </div>
                                </div>
                            }

                            {
                            (cruisesPP.length > 0)
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                            >
                            {
                            cruisesPP.map((cruise: any, index: number) => {
                                    return <div key={index} className="gridviewcardstd"
                                    onClick={() => handleOpenWindCRepPP(cruise)}
                                    >
                                                    {
                                                        cruise.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={cruise.image_src} alt=""
                                                        onError={(e: any) => e.target.src = cruise.image_url}
                                                        />

                                                    }
                                                    {
                                                        !cruise.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={cruise.image_url} alt=""
                                                        />

                                                    }
                                                    <div className="gridviewcardimagecover">
                                                        <div className="gridviewsuburbbubble">
                                                            {cruise.datestring}
                                                            {/* {" '"}{cruise.finish_year.slice(-2)} */}
                                                        </div>
                                                        <div className="gridviewbottomcontainermod">               
                                                            <div className="gridviewtitlecontainerfull">
                                                            <div className="gridviewtitlefullsm">{cruise.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                })
                            }
                            </div>
                            }


                    <div className="floatingbuttoncont">
                        

                        
                        

                            {
                                props.selectedPerson?.soundcloud !== ""
                                &&
                                <div className="socialmediabtn"
                                onClick={()=>window.open(`https://www.soundcloud.com/${props.selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                props.selectedPerson?.soundcloud === ""
                                &&
                                <div className="socialmediabtn"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                >
                                <div className="floatbtnskewsocialmed csrnot">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx" />
                                </div>
                            </div>
                            }

                        

                        {
                            props.selectedPerson?.website !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={()=>window.open(props.selectedPerson?.website, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson?.website === ""
                            &&
                            <div className="socialmediabtn"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninsta"
                        onClick={()=>window.open(`https://www.instagram.com/${props.selectedPerson?.instagram}`, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        
                        {
                            props.selectedPerson?.spotify !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={()=>window.open(`https://www.tiktok.com/@${props.selectedPerson?.spotify}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson?.spotify === ""
                            &&
                            <div className="socialmediabtn btnopac"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                            
                            
                        
         
                        </div>

                        <div className="spaceforbtn"></div>
                            
                            

                        

                    </IonContent>
                    </div>

                </div>
                                <div className="arrowcontr"
                                    onClick={props.handleCloseWindPP}
                                    >
                                    
                                    <div
                                    className={props.selectedPersonInd === (props.filteredPeopleLn - 1) ? "arrowcirc csrnot": "arrowcirc"}
                                    onClick={handleClickFwdPP}
                                    >
                                            <IonIcon icon={chevronForwardOutline}
                                            className={props.selectedPersonInd === (props.filteredPeopleLn - 1) ? "arricn opac3" : "arricn"}
                                            ></IonIcon>
                                    </div>
                                        
                                </div>

                    
                </div>

                <div className="windbotcont" onClick={props.handleCloseWindPP}>
                    <div className="floatingbuttoncontweb">

                {
                                props.selectedPerson.soundcloud !== ""
                                &&
                                <div className="socialmediabtnwebBC"
                                onClick={handleClickLinkPPSC}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                props.selectedPerson.soundcloud === ""
                                &&
                                <div className="socialmediabtnwebBC"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                onClick={(e) => e.stopPropagation()}
                                >
                                <div className="floatbtnskewsocialmed csrnot">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx" />
                                </div>
                            </div>
                            }

                        

                        {
                            props.selectedPerson.website !== ""
                            &&
                            <div className="socialmediabtnwebBC"
                            onClick={handleClickLinkPP}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson.website === ""
                            &&
                            <div className="socialmediabtnwebBC"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninstawebBC"
                        onClick={handleClickLinkPPInst}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        {/* spotify now equals tiktok */}
                        {
                            props.selectedPerson.spotify !== ""
                            &&
                            <div className="socialmediabtnwebBC"
                            onClick={handleClickLinkPPTT}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson.spotify === ""
                            &&
                            <div className="socialmediabtnwebBC"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        </div>
                    </div>
            </div>
            }
            {
                selectedPartyRepPP
                &&
                // <NewWindP
                //   selectedParty={selectedPartyRepPP}
                //   setSelectedParty={setSelectedPartyRepPP}
                //   gridNo={props.gridNo}
                //   barsAndClubs={props.barsAndClubs}
                //   peopleOth={props.peopleOth}
                //   currentDateAndTime={props.currentDateAndTime}
                //   handleIncrClick={props.handleIncrClick}
                //   fromCal={true}
                //   handleCloseWind={props.handleCloseWindPP}
                //   />


                //doing own version of newWindP below to make going back to change person easier
                  <>
            
            
                {
                    !isRepBCOpenPP
                    &&
                    <div className="testwindowabs"
                    onClick={props.handleCloseWindPP}
                    >
                    
                    
                        <div className="windowcontainerNW"
                        onClick={(e) => e.stopPropagation()}
                        >
                            
                        {/* <div className="arrowtest"></div> */}
                                <div className="newwinimgcont">


                                            
                                                    {
                                                        imgOrMapPP==="img"
                                                        &&
                                                        selectedPartyRepPP.image_src
                                                        &&
                                                        <img className="windowimgboxNW" src={selectedPartyRepPP.image_src}
                                                        onError={(e: any) => e.target.src = selectedPartyRepPP.image_url}
                                                        ></img>
                                                    }
                                                    {
                                                        imgOrMapPP==="img"
                                                        &&
                                                        !selectedPartyRepPP.image_src
                                                        &&
                                                        <img className="windowimgboxNW" src={selectedPartyRepPP.image_url}
                                                        ></img>
                                                    }

                                                    {
                                                        imgOrMapPP==="map"
                                                        &&
                                                        // <div className="mapcontainer">
                                                            <div ref={mapContainerPP} className="mapcontainer" />
                                                        // </div>
                                                    }


                                                    <div className="segmentconNW">
                                                        <div className={imgOrMapPP === "img" ? "segon" : "segoff"}
                                                        onClick={()=>handleImgOrMapPP("img")}
                                                        >IMAGE</div>
                                                        <div className={imgOrMapPP === "map" ? "segon" : "segoff"}
                                                        onClick={()=>handleImgOrMapPP("map")}
                                                        >MAP</div>
                                                    </div>

                                                    <div className="bckicncont"
                                                onClick={handleClBackWindPartyRepPP}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>

                                                    
                                                    
                                                

                                </div>
                                
                                <div className="newwininfocont">
                                <IonContent className="contentpad">

                                
                                

                                
                                
                                        <div className="windowmainsetwidthNW">


                                        {
                                                selectedPartyRepPP.price !== 0 && selectedPartyRepPP.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightdd">
                                                                <div className="titlerightfromdd">from</div>
                                                                <div className="titlerightmargNW">{selectedPartyRepPP.currency_symbol}{selectedPartyRepPP.price}</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                </div>

                                        }
                                        
                                        {
                                                selectedPartyRepPP.price === 0 && selectedPartyRepPP.tickets_available
                                                &&
                                        <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightfreedd">
                                                                <div>FREE</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                            </div>
                                            }

                                                    {
                                                !selectedPartyRepPP.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightsoldoutdd">
                                                                <div className="">SOLD OUT</div>
                                                                {/* <div className="">OUT</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                </div>
                                                    }



                                            

                                                

                                            

                                            {
                                                selectedPartyRepPP.price !== 999
                                                &&
                                                <div className="windowtitleboxNW">

                                                

                                                <div className="windowtitleshortNW">
                                                    {selectedPartyRepPP.name}
                                                </div>
                                                
                                            
                                                {
                                                selectedPartyRepPP.price !== 0 && selectedPartyRepPP.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightNW">
                                                        <div className="titlerightfromNW">from</div>
                                                        <div className="titlerightmargNW">{selectedPartyRepPP.currency_symbol}{selectedPartyRepPP.price}</div>
                                                    </div>
                                                </div>
                                                }
                                            
                                                {
                                                selectedPartyRepPP.price === 0 && selectedPartyRepPP.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightfreeNW">
                                                        <div>FREE</div>
                                                    </div>
                                                </div>
                                                }

                                                
                                                {
                                                !selectedPartyRepPP.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightsoldoutNW">
                                                        <div className="">SOLD</div>
                                                        <div className="">OUT</div>
                                                    </div>
                                                </div>
                                                }
                                                </div>
                                            }   

                                            {
                                            selectedPartyRepPP.price === 999
                                            &&
                                            <div className="windowtitleboxNW">
                                                <div className="windowtitlefullNW">
                                                    {selectedPartyRepPP.name}
                                                </div>
                                            </div>
                                            }

                                               


                                            {
                                            !selectedPartyRepPP.tickets_available
                                            &&
                                            <div className="windowmainboxstd">
                                                <div className="windowmaintextboxstd soldoutbox">
                                                    Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                                </div>
                                            </div>
                                            }


                                            {/* {
                                            selectedParty.daterealstart > props.currentDateAndTime
                                            && */}
                                            <div className="windowdatetimecont">
                                                <div className="datetimebox">
                                                    <IonIcon icon={calendarOutline} className="datetimeicon" />
                                                    <div className="datebox">
                                                        <div className="datetimetext">
                                                            {selectedPartyRepPP.start_day.slice(0,3)}{", "}{selectedPartyRepPP.start_date}{" "}{selectedPartyRepPP.start_month.slice(0,3)}
                                                            {/* <br/>
                                                            2-11pm */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="datetimebox">
                                                    <IonIcon icon={timeOutline} className="datetimeicon" />
                                                    <div className="timebox">
                                                        <div className="datetimetext">
                                                            {selectedPartyRepPP.start_time}{" "}to{" "}{selectedPartyRepPP.finish_time}
                                                        </div>
                                                    </div>
                                                </div>                  
                                            </div>
                                            {/* } */}
                                            
                                            {
                                            (selectedPartyRepPP.description !== "" || selectedPartyRepPP.extra_info !== "")
                                            &&
                                            <div className="windowmainboxstd">
                                                <div className="windowmainheadingstd">
                                                    About
                                                </div>   
                                                <div className="windowmaintextboxstd">
                                                    {selectedPartyRepPP.description}
                                                </div>
                                                    {
                                                selectedPartyRepPP.extra_info !== ""
                                                &&
                                                <div className="windowmaintextboxext">
                                                    <div className="exclambox">
                                                        <IonIcon icon={alertCircle} className="exclam">
                                                        </IonIcon>
                                                    </div>
                                                    <div className="exclamtext">{selectedPartyRepPP.extra_info}</div>
                                                </div>
                                                }
                                            </div>
                                            }

                                            

                                            {/* <div className="partywindowdescriptionbox">
                                                <div className="partywindowheading">
                                                    Prices
                                                </div>   
                                                <div className="partywindowdescriptionpricescontainer">
                                                    <div className="partywindowpricebox">
                                                        <div className="ticketinfotextalt">{selectedParty.price_type}</div>
                                                        <div className="ticketinfopricealt">{selectedParty.currency_symbol}{selectedParty.price}</div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            

                                            {/* <div className="windowmainboxstd"> */}
                                                
                                                {
                                                props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue).length > 0
                                                &&
                                                <div className="windowmainheadingstd">
                                                    Venue
                                                </div>
                                                }
                                            </div>
                                                {
                                                props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue).length > 0
                                                &&
                                                <div
                                                // className="windowmainboxvenue"
                                                className="windowgrid"
                                                // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                                >
                                                    <div className="gridviewcardstd"
                                                    onClick={() => handleOpenWindBCRepPP(props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0])}
                                                    >
                                                        {/* <Link to={`/barsandclubs/${props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].id}`} style={{ display: 'contents' }}> */}
                                                            {/* maybe i should do abovoe calc in body in formula. and wrap in use memo. same as below. ie calc would identify that matched venue */}
                                                            {
                                                            (props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0].image_src)
                                                            &&
                                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0].image_src} alt="" 
                                                            onError={(e: any) => e.target.src = selectedPartyRepPP.image_url}
                                                            />
                                                            }
                                                            {
                                                            !(props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0].image_src)
                                                            &&
                                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0].image_url} alt="" 
                                                            />
                                                            }
                                                            <div className="gridviewcardimagecover">
                                                                {/* <div className="gridviewsuburbbubble">
                                                                    {props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue)[0].suburb}
                                                                </div> */}
                                                                <div className="gridviewbottomcontainermid">               
                                                                    <div className="gridviewtitlecontainerfull">
                                                                    <div className="gridviewtitlefull">{props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue)[0].name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        {/* </Link>      */}
                                                    </div>
                                                </div>
                                                }
                                                <div className="windowmainsetwidthNW">
                                                
                                                {
                                                props.barsAndClubs.filter((ele:any) => ele.name === selectedPartyRepPP.venue).length === 0
                                                &&
                                                <div className="windowmainboxstd">
                                                    <div className="windowmainheadingstd">
                                                        Address
                                                    </div>
                                                    
                                                    <div className="windowmainvenuecardbox">
                                                        <div className="windowvenueleft">      
                                                            {
                                                            selectedPartyRepPP.venue !== ""
                                                            &&
                                                            <div>
                                                                <span className="addresshasvenuename">{selectedPartyRepPP.venue}</span>
                                                            </div>
                                                            }
                                                            {selectedPartyRepPP.suite_number === "" ? "" : <div>{selectedPartyRepPP.suite_number}</div>}
                                                            <div className="addressfirstrowflexbox">
                                                                
                                                                {selectedPartyRepPP.street_number === "" ? <div>{selectedPartyRepPP.street_name}</div> : <div className="limitaddresstooneline">{selectedPartyRepPP.street_number}{" "}{selectedPartyRepPP.street_name}</div>}
                                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                                            </div>
                                                            <div>{selectedPartyRepPP.suburb} {selectedPartyRepPP.postcode}</div>       
                                                        </div>
                                                        {/* <div className="windowvenueright">  
                                                            <img
                                                            src={mapimage}
                                                            alt=""
                                                            className="windowvenueimg" 
                                                            onClick={() => setIsWindowMapOpenP(true)}
                                                            />
                                                        </div> */}
                                                    </div>
                                                </div>
                                                }    
                                            {/* </div> */}

                                            
                                            
                                            {
                                            selectedPartyRepPP.entertainers !== ""
                                            &&
                                            props.peopleOth.filter((person: any) => selectedPartyRepPP.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                            &&
                                            <div className="windowmainheadingstd">
                                                Featured entertainers
                                            </div>
                                            }

                                            {/* end of set width main box */}
                                        </div>

                                        {/* can i move the below calcs up or some of them and memoize? */}

                                        {
                                        selectedPartyRepPP.entertainers !== ""
                                        &&
                                        props.peopleOth.filter((person: any) => selectedPartyRepPP.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                        &&
                                        <div className="windowgrid"
                                        // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                        >
                                            {props.peopleOth.filter((person: any) => selectedPartyRepPP.entertainers.some((entertainer: any) => entertainer === person.name))
                                            .map((person: any, index: any) => {
                                                return <div key={index} className="gridviewcardstd"
                                                onClick={()=>handleChangePersonPart2(person)}
                                                >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}
                                                                    />
                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt="" />
                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                    {/* {
                                                                    person.type[0] === "Drag queen"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedrag ">Drag</div>
                                                                    }      
                                                                    {
                                                                    person.type[0] === "DJ"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedj">DJ</div>
                                                                    }         
                                                                    {
                                                                    person.type[0] === "Singer"
                                                                    &&
                                                                    <div className="gridviewtypebubble typesing">Singer</div>
                                                                    }          
                                                                    {
                                                                    person.type[0] === "Other"
                                                                    &&
                                                                    <div className="gridviewtypebubble typeoth">Other</div>
                                                                    } */}

                                                                                <div className="gridviewtopcont">
                                                                            {
                                                                                person.type.map((typeparam:any, index:number) => {
                                                                                    return <div key={index} className="gridviewpricebubbletype"> 

                                                                                    {
                                                                                    typeparam === "Drag queen"
                                                                                    &&
                                                                                    <span className="tyre">DRAG</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "DJ"
                                                                                    &&
                                                                                    <span className="tybl">DJ</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Other"
                                                                                    &&
                                                                                    <span className="tyor">OTHER</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Singer"
                                                                                    &&
                                                                                    <span className="tygr">SINGER</span>
                                                                                    }

                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {/* {
                                                                            person.type[0] === "Drag queen"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyre"> 
                                                                                    DRAG
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "DJ"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tybl"> 
                                                                                    DJ
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Singer"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tygr"> 
                                                                                    SINGER
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Other"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyor"> 
                                                                                    OTHER
                                                                            </div>
                                                                            } */}
                                                                            </div>
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainer">               
                                                            
                                                                            <div className="gridviewtitlecontainer">
                                                                                {
                                                                                person.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name}
                                                                                </div>
                                                                                }
                                                                                {
                                                                                person.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name_short}
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            })}
                                        </div>
                                        }

                                        {/* {
                                        selectedParty.entertainers !== ""
                                        &&
                                        props.people.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                        &&
                                        <div className="swipercontainerstd gvgaddmarginbot">
                                            <Swiper
                                            slidesPerView={3.1}
                                            loop={false}
                                            modules={[IonicSlides]}
                                            slidesOffsetBefore={30}
                                            slidesOffsetAfter={30}
                                            >
                                            {props.people.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name))
                                            .map((person: any, index: any) => {
                                                return <SwiperSlide
                                                key={index}
                                                >
                                                    <div className="swipercard">
                                                        <Link to={`/people/${person.id}`} style={{ display: 'contents' }}>

                                                            <img className="gridviewcardimage" src={person.image_src} alt="" />                                          
                                                            <div className="gridviewcardimagecover">
                                                                <div className="gridviewbottomcontainer">
                                                                    <div className="gridviewtitlecontainerfull">
                                                                        <div className="gridviewtitlefull">{person.name}</div>
                                                                    </div>
                                                                    {
                                                                    person.type[0] === "Drag queen"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedrag ">Drag</div>
                                                                    }      
                                                                    {
                                                                    person.type[0] === "DJ"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedj">DJ</div>
                                                                    }         
                                                                    {
                                                                    person.type[0] === "Singer"
                                                                    &&
                                                                    <div className="gridviewtypebubble typesing">Singer</div>
                                                                    }          
                                                                    {
                                                                    person.type[0] === "Other"
                                                                    &&
                                                                    <div className="gridviewtypebubble typeoth">Other</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Link>            
                                                    </div>
                                                </SwiperSlide>
                                                })}
                                            </Swiper>
                                        </div>
                                        } */}

                                        <div className="windowmainsetwidthNW">
                                            <div className="windowdatetimecont">
                                                {/* using the above because same properties and values */}
                                                {
                                                selectedPartyRepPP.organiser !== ""
                                                &&
                                                <div
                                                style={selectedPartyRepPP.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                                >
                                                    <div className="windowmainheadingstd">
                                                        Organiser
                                                    </div>
                                                    <div className="windowmaintextboxstd extrapadorg">
                                                        {selectedPartyRepPP.organiser}
                                                    </div>
                                                </div>
                                                }
                                                {
                                                selectedPartyRepPP.ticket_provider !== ""
                                                &&
                                                <div
                                                style={selectedPartyRepPP.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                                >
                                                    <div className="windowmainheadingstd">
                                                        Ticket vendor
                                                    </div>
                                                    <div className="windowmaintextboxstd">
                                                        {selectedPartyRepPP.ticket_provider}
                                                    </div>
                                                </div>
                                                }
                                            </div>

                                            <div className="floatingbuttoncont">
                                                {
                                                (selectedPartyRepPP.price !== 0 && selectedPartyRepPP.tickets_available && selectedPartyRepPP.price !== 999 && selectedPartyRepPP.price_type !== "more" && !selectedPartyRepPP.global)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                ((selectedPartyRepPP.price === 0 || !selectedPartyRepPP.tickets_available || selectedPartyRepPP.price === 999 || selectedPartyRepPP.price_type === "more") && !selectedPartyRepPP.global)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                {
                                                selectedPartyRepPP.global
                                                &&
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskewsm">
                                                            MAKE A RESERVATION
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            
                                            
                                        </div>
                                        <div className="spaceforbtn"></div>
                                        
                                    </IonContent>
                                    </div>

                        </div>

                        <div className="windbotcont" onClick={props.handleCloseWindPP}>

                            {
                                            (selectedPartyRepPP.price !== 0 && selectedPartyRepPP.tickets_available && selectedPartyRepPP.price !== 999 && selectedPartyRepPP.price_type !== "more" && !selectedPartyRepPP.global)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPParty}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            ((selectedPartyRepPP.price === 0 || !selectedPartyRepPP.tickets_available || selectedPartyRepPP.price === 999 || selectedPartyRepPP.price_type === "more") && !selectedPartyRepPP.global)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPParty}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            {
                                            selectedPartyRepPP.global
                                            &&
                                            <div className="floatingbuttoncontweb">

                                            

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPParty}
                                                >
                                                    <div className="floatbtnskewsm">
                                                        MAKE A RESERVATION
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }
                        </div>                      


                    </div>
                    }


                </>
            }


 {/* Below is manual drag tour section */}

                {
                selectedDTRepPP
                &&
                <>
                {
                    !isRepBCOpenPP
                    &&
                    <div className="testwindowabs" onClick={props.handleCloseWindPP}>
                            <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                    <div className="newwinimgcont">


                                                
                
                                        {
                                            imgOrMapPP==="img"
                                            &&
                                            selectedDTRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedDTRepPP.image_src}
                                            onError={(e: any) => e.target.src = selectedDTRepPP.image_url}
                                            ></img>
                                        }
                                        {
                                            imgOrMapPP==="img"
                                            &&
                                            !selectedDTRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedDTRepPP.image_url}
                                            ></img>
                                        }

                                        {
                                            imgOrMapPP==="map"
                                            &&
                                            // <div className="mapcontainer">
                                                <div ref={mapContainerPP} className="mapcontainer" />
                                            // </div>
                                        }


                                        <div className="segmentconNW">
                                            <div className={imgOrMapPP === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("img")}
                                            >IMAGE</div>
                                            <div className={imgOrMapPP === "map" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("map")}
                                            >MAP</div>
                                        </div>
                
                                        <div className="bckicncont"
                                                onClick={handleClBackWindDTRepPP}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>

                



            </div>
            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                        {
                                                selectedDTRepPP.price !== 0 && selectedDTRepPP.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightdd">
                                                                <div className="titlerightfromdd">from</div>
                                                                <div className="titlerightmargNW">{selectedDTRepPP.currency_symbol}{selectedDTRepPP.starting_price}</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                </div>

                                        }
                                        
                                        {
                                                selectedDTRepPP.price === 0 && selectedDTRepPP.tickets_available
                                                &&
                                        <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightfreedd">
                                                                <div>FREE</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                            </div>
                                            }

                                                    {
                                                !selectedDTRepPP.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightsoldoutdd">
                                                                <div className="">SOLD OUT</div>
                                                                {/* <div className="">OUT</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                </div>
                                                    }

                            

                                

                                
                                    <div className="windowtitleboxNW">

                                    {/* {
                                    props.selectedDragExpo?.daterealstart <= props.currentDateAndTime
                                    &&
                                    <div
                                    className="livewindowbox">
                                        <div className="blinkingtextlivebg">LIVE</div>
                                    </div>
                                    } */}
                                    {/* not currently doing live for drag tours/expos */}

                                    <div className="windowtitleshortNW">
                                        {selectedDTRepPP.name}
                                    </div>
                                    
                                   
                                
                                    {/* {
                                    props.selectedDragExpo?.price === 0 && props.selectedDragExpo?.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightfreeNW">
                                            <div>FREE</div>
                                        </div>
                                    </div>
                                    } */}

                                    {
                                    selectedDTRepPP.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightNW">
                                            <div className="titlerightfromNW">from</div>
                                            <div className="titlerightmargNW">{selectedDTRepPP.currency_symbol}{selectedDTRepPP.starting_price}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    !selectedDTRepPP.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightsoldoutNW">
                                            <div className="">SOLD</div>
                                            <div className="">OUT</div>
                                        </div>
                                    </div>
                                    }
                                    </div>
                                

                                {/* {
                                props.selectedDragExpo?.price === 999
                                &&
                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedDragExpo?.name}
                                    </div>
                                </div>
                                } */}


                                {
                                !selectedDTRepPP.tickets_available
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmaintextboxstd soldoutbox">
                                        Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                    </div>
                                </div>
                                }


                             
                                <div className="windowdatetimecont">
                                    <div className="datetimebox">
                                        <IonIcon icon={calendarOutline} className="datetimeicon" />
                                        <div className="datebox">
                                            <div className="datetimetext">
                                                {selectedDTRepPP.start_day.slice(0,3)}{", "}{selectedDTRepPP.start_date}{" "}{selectedDTRepPP.start_month.slice(0,3)}{" '"}{selectedDTRepPP.start_year.slice(-2)}
                                                {/* <br/>
                                                2-11pm */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="datetimebox">
                                        <IonIcon icon={timeOutline} className="datetimeicon" />
                                        <div className="timebox">
                                            <div className="datetimetext">
                                                {selectedDTRepPP.start_time}{" "}to{" "}{selectedDTRepPP.finish_time}
                                            </div>
                                        </div>
                                    </div>                  
                                </div>

                                {/* <div className="windowmainboxstd"> */}
                                            <div className="windowmainheadingstd">
                                                Performers
                                                {/* make above conditional on length in terms of 's' */}
                                            </div>

                                        </div>

                                            <div className="windowgrid"
                                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                            >
                                                {
                                                    // put logic in useeffect
                                                    props.peopleOth.filter((person: any) => selectedDTRepPP.entertainers?.some((entertainer: any) => entertainer === person.name))
                                                    .map((person: any, index: any) => {
                                                        return <div key={index} className="gridviewcardstd"
                                                        onClick={() => handleChangePersonPart2(person)}
                                                        >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}

                                                                    />

                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt=""
                                                                    />

                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                {
                                                                person.type[0] === "Drag queen"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedrag ">Drag</div>
                                                                }      
                                                                {
                                                                person.type[0] === "DJ"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedj">DJ</div>
                                                                }         
                                                                {
                                                                person.type[0] === "Singer"
                                                                &&
                                                                <div className="gridviewtypebubbletour typesing">Singer</div>
                                                                }          
                                                                {
                                                                person.type[0] === "Other"
                                                                &&
                                                                <div className="gridviewtypebubbletour typeoth">Other</div>
                                                                }
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainer">               
                                                            
                                                                            <div className="gridviewtitlecontainerfull">
                                                                                <div className="gridviewtitlefull">
                                                                                    {person.name}
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>                       
                                                    })
                                                    }
                                                </div>
                                    {/* </div> */}
                                    <div className="windowmainsetwidthNW">
                                {
                                (selectedDTRepPP.description !== "" || selectedDTRepPP.extra_info !== "")
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {selectedDTRepPP.description}
                                    </div>
                                        {
                                    selectedDTRepPP.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedDTRepPP.extra_info}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {/* <div className="windowmainboxstd"> */}
                                
                                {
                                props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue).length > 0
                                &&
                               
                                    <div className="windowmainheadingstd">
                                    Venue
                                </div>
                                }
                                </div>
                                {
                                props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue).length > 0
                                &&
                                <div
                                // className="windowmainboxvenue"
                                className="windowgrid"
                                // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                >
                                    <div className="gridviewcardstd"
                                    onClick={()=>handleOpenWindBCRepPP(props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0])}
                                    >
                                        {/* <Link to={`/barsandclubs/${props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].id}`} style={{ display: 'contents' }}> */}
                                            {/* maybe i should do abovoe calc in body in formula. and wrap in use memo. same as below. ie calc would identify that matched venue */}
                                            {
                                            (props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].image_src} alt=""
                                            onError={(e: any) => e.target.src = props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].image_url}

                                            />
                                            }
                                            {
                                            !(props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].image_url} alt=""
                                            />
                                            }
                                            <div className="gridviewcardimagecover">
                                                {/* <div className="gridviewsuburbbubble">
                                                    {props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].suburb}
                                                </div> */}
                                                <div className="gridviewbottomcontainer">               
                                                    <div className="gridviewtitlecontainerfull">
                                                    <div className="gridviewtitlefull">{props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue)[0].name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </Link>        */}
                                    </div>
                                </div>
                             
                                }
                                <div className="windowmainsetwidthNW">
                                
                                {
                                    props.barsAndClubs.filter((ele:any) => ele.name === selectedDTRepPP.venue).length === 0
                                    &&
                                    <div className="windowmainboxstd">
                                        <div className="windowmainheadingstd">
                                            Address
                                        </div>
                                    
                                        <div className="windowmainvenuecardbox">
                                            <div className="windowvenueleft">      
                                                {
                                                selectedDTRepPP.venue !== ""
                                                &&
                                                <div>
                                                    <span className="addresshasvenuename">{selectedDTRepPP.venue}</span>
                                                </div>
                                                }
                                                {selectedDTRepPP.suite_number === "" ? "" : <div>{selectedDTRepPP.suite_number}</div>}
                                                <div className="addressfirstrowflexbox">
                                                    
                                                    {selectedDTRepPP.street_number === "" ? <div>{selectedDTRepPP.street_name}</div> : <div className="limitaddresstooneline">{selectedDTRepPP.street_number}{" "}{selectedDTRepPP.street_name}</div>}
                                                    {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                                </div>
                                                <div>{selectedDTRepPP.suburb} {selectedDTRepPP.postcode}</div>       
                                            </div>
                                            {/* <div className="windowvenueright">  
                                                <div className="viewmapbtorng" onClick={() => setIsWindowMapOpenDE(true)}>
                                                    View map
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                }   
                                

                                {/* </div> */}

                                <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                selectedDTRepPP.organiser !== ""
                                &&
                                <div
                                style={selectedDTRepPP.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {selectedDTRepPP.organiser}
                                    </div>
                                </div>
                                }
                                {
                                selectedDTRepPP.ticket_provider !== ""
                                &&
                                <div
                                style={selectedDTRepPP.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedDTRepPP.ticket_provider}
                                    </div>
                                </div>
                                }
                            </div>


                            <div className="floatingbuttoncont">
                                                {
                                                (selectedDTRepPP.tickets_available)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                (!selectedDTRepPP.tickets_available)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                
                            </div>



                        

                            
                            

                            
                            
                            

                        </div>

                        <div className="spaceforbtn"></div>
                    
                    </IonContent>
                    </div>

                

                    
                </div>


                <div className="windbotcont" onClick={props.handleCloseWindPP}>

                            {
                                            (selectedDTRepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPDT}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            (!selectedDTRepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPDT}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            
                        </div>
                    
                        

                    </div>

                }
                </>
                }






                {/* Below is manual drag expo section */}

                {
                selectedDERepPP
                &&
                <>
                {
                    !isRepBCOpenPP
                    &&
                    <div className="testwindowabs" onClick={props.handleCloseWindPP}>
                    <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>


                    <div className="newwinimgcont">
                                <IonContent>


                            <div className="gridviewgridmod"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 2}, calc(100% / ${props.gridNo - 2}))`}}
                            >
                                    {
                                        // put logic in useeffect
                                        props.people.filter((person: any) => selectedDERepPP.entertainers?.some((entertainer: any) => entertainer === person.name))
                                        .map((person: any, index: any) => {
                                            return <div key={index} className="gridviewcard"
                                            onClick={()=>handleChangePersonPart2(person)}
                                            >
                                                    {
                                                        person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_src} alt=""
                                                        onError={(e: any) => e.target.src = person.image_url}

                                                        />
                                                    }
                                                    {
                                                        !person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_url} alt=""
                                                        />
                                                    }
                                                    <div className="gridviewcardimagecover">
                                                        
                                                        
                                                        
                                                        <div className="gridviewbottomcontaineralt">               
                                                
                                                                <div className="gridviewtitlecontainerfull">
                                                                    <div className="gridviewtitlefull">
                                                                        {person.name}
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                
                                                                    
                                                                
                                        })
                                        }
                                    </div>

                                    </IonContent>   
                
                                        {/* {
                                            imgOrMapPP==="img"
                                            &&
                                            selectedDTRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedDTRepPP.image_src}
                                            onError={(e: any) => e.target.src = selectedDTRepPP.image_url}
                                            ></img>
                                        }
                                        {
                                            imgOrMapPP==="img"
                                            &&
                                            !selectedDTRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedDTRepPP.image_url}
                                            ></img>
                                        }

                                        {
                                            imgOrMapPP==="map"
                                            &&
                                                <div ref={mapContainerPP} className="mapcontainer" />
                                        }


                                        <div className="segmentconNW">
                                            <div className={imgOrMapPP === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("img")}
                                            >IMAGE</div>
                                            <div className={imgOrMapPP === "map" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("map")}
                                            >MAP</div>
                                        </div> */}
                
                                        <div className="bckicncont"
                                                onClick={handleClBackWindDERepPP}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                        </div>

                                        <div className="confmatt"
                                                
                                                >
                                                    CONFIRMED ATTENDEES
                                        </div>

                



            </div>
            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                                

                                
                                    <div className="windowtitleboxNW">

                                    {/* {
                                    props.selectedDragExpo?.daterealstart <= props.currentDateAndTime
                                    &&
                                    <div
                                    className="livewindowbox">
                                        <div className="blinkingtextlivebg">LIVE</div>
                                    </div>
                                    } */}
                                    {/* not currently doing live for drag tours/expos */}

                                    <div className="windowtitlefullNW">
                                        {selectedDERepPP.name}
                                    </div>
                                    
                                   
                                
                                    </div>
                                

                                {/* {
                                props.selectedDragExpo?.price === 999
                                &&
                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedDragExpo?.name}
                                    </div>
                                </div>
                                } */}


                                {
                                !selectedDERepPP.tickets_available
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmaintextboxstd soldoutbox">
                                        Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                    </div>
                                </div>
                                }


                             
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                            Dates & Pricing
                                    </div>
                                    {/* <Link to={`/cruises/${selectedCruise?.id}/map`} className="canichangelinkcruises"> */}
                                    {/* <div className="viewonmapcruises"> */}
                                        {/* <IonButton fill="outline" color="primary" size="small">Map view</IonButton> */}
                                        
                                        
                                        {/* <div className="mapviewbutton"
                                        onClick={() => setIsWindowMapOpenDE(true)}
                                        >View map</div> */}
                                    <div className="pricingflexboxDEcontainer">
                                        {
                                            selectedDERepPP.price_options.map((option: any, index: number) => {
                                                return <div key={index} className="pricingflexboxDE">
                                                        <div className="pricingoptionsDEtype"><div>{option[1]}</div>
                                                        {/* {option[2] !== "" ? <span className="">{", "}{option[2]}{" - "}{option[3]}</span> : <span></span>} */}
                                                        {option[2] !== "" ? <div className="priceopflexac"><div className="pinkballalt"></div>{option[2]}{" - "}{option[3]}</div> : <span></span>}
                                                        </div>
                                                        
                                                        <div className="pricingoptionsDEprice">{selectedDERepPP.currency_symbol}{option[0]}</div>
                                                    </div>
                                            })
                                        }

                                    </div>

                                </div>

                               
                          
                                

                                <div className="windowmainboxstd">
                                
                                
                                
                                
                                    <div className="windowmainheadingstd">
                                        Address
                                    </div>
                                   
                                    
                                    <div className="windowmainvenuecardbox">
                                        <div className="windowvenueleft">      
                                            {
                                            selectedDERepPP.venue !== ""
                                            &&
                                            <div>
                                                <span className="addresshasvenuename">{selectedDERepPP.venue}</span>
                                            </div>
                                            }
                                            {selectedDERepPP.suite_number === "" ? "" : <div>{selectedDERepPP.suite_number}</div>}
                                            <div className="addressfirstrowflexbox">
                                                
                                                {selectedDERepPP.street_number === "" ? <div>{selectedDERepPP.street_name}</div> : <div className="limitaddresstooneline">{selectedDERepPP.street_number}{" "}{selectedDERepPP.street_name}</div>}
                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                            </div>
                                            <div>{selectedDERepPP.suburb} {selectedDERepPP.postcode}</div>       
                                        </div>
                                        {/* <div className="windowvenueright">  
                                            <div className="viewmapbtorng" onClick={() => setIsWindowMapOpenDE(true)}>
                                                View map
                                            </div>
                                        </div> */}
                                    </div>
                                  

                                </div>

                                <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                selectedDERepPP.organiser !== ""
                                &&
                                <div
                                style={selectedDERepPP.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {selectedDERepPP.organiser}
                                    </div>
                                </div>
                                }
                                {
                                selectedDERepPP.ticket_provider !== ""
                                &&
                                <div
                                style={selectedDERepPP.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedDERepPP.ticket_provider}
                                    </div>
                                </div>
                                }
                            </div>

                            <div className="floatingbuttoncont">
                                                {
                                                (selectedDERepPP.tickets_available)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                (!selectedDERepPP.tickets_available)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                
                                            </div>
                            



                        

                            
                            

                            
                            
                            

                        </div>

                        <div className="spaceforbtn"></div>
                    
                    </IonContent>
                    </div>



                    

                

                    
                </div>


                <div className="windbotcont" onClick={props.handleCloseWindPP}>

                            {
                                            (selectedDERepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPDE}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            (!selectedDERepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPDE}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            
                        </div>
                    
                        

                    </div>

                }
                </>
                }

 {/* Below is manual cruise section */}

                {
                selectedCRepPP
                &&
                <>
                {
                    !isRepBCOpenPP
                    &&
                    <div className="testwindowabs" onClick={props.handleCloseWindPP}>
                            <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                    <div className="newwinimgcont">


                                                
                
                                        {
                                            imgOrMapPP==="img"
                                            &&
                                            selectedCRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedCRepPP.image_src}
                                            onError={(e: any) => e.target.src = selectedCRepPP.image_url}
                                            ></img>
                                        }
                                        {
                                            imgOrMapPP==="img"
                                            &&
                                            !selectedCRepPP.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedCRepPP.image_url}
                                            ></img>
                                        }

                                        {
                                            imgOrMapPP==="map"
                                            &&
                                            <MapViewMultiC
                                            selectedCruise={selectedCRepPP}
                                            // imgOrMapC={imgOrMapPP}
                                            />
                                        }


                                        <div className="segmentconNW">
                                            <div className={imgOrMapPP === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("img")}
                                            >IMAGE</div>
                                            <div className={imgOrMapPP === "map" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapPP("map")}
                                            >MAP</div>
                                        </div>
                
                                        <div className="bckicncont"
                                                onClick={handleClBackWindCRepPP}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>

                



            </div>
            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                                

                                
                                    <div className="windowtitleboxNW">

                                    {/* {
                                    props.selectedDragExpo?.daterealstart <= props.currentDateAndTime
                                    &&
                                    <div
                                    className="livewindowbox">
                                        <div className="blinkingtextlivebg">LIVE</div>
                                    </div>
                                    } */}
                                    {/* not currently doing live for drag tours/expos */}

                                    <div className="windowtitleshortNW">
                                        {selectedCRepPP.name}
                                    </div>
                                    
                                   
                                
                                    {/* {
                                    props.selectedDragExpo?.price === 0 && props.selectedDragExpo?.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightfreeNW">
                                            <div>FREE</div>
                                        </div>
                                    </div>
                                    } */}

                                    {
                                    selectedCRepPP.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightNW">
                                            <div className="titlerightfromNW">from</div>
                                            <div className="titlerightmargNW">{selectedCRepPP.currency_symbol}{selectedCRepPP.starting_price}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    !selectedCRepPP.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightsoldoutNW">
                                            <div className="">SOLD</div>
                                            <div className="">OUT</div>
                                        </div>
                                    </div>
                                    }
                                    </div>
                                

                                {/* {
                                props.selectedDragExpo?.price === 999
                                &&
                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedDragExpo?.name}
                                    </div>
                                </div>
                                } */}


                                {/* {
                                !selectedCRepPP.tickets_available
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmaintextboxstd redfont">
                                        Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                    </div>
                                </div>
                                } */}

                        {/* <div className="windowmainsetwidthNW"> */}
                            <div className="itinplusmapheadercont">
                                <div className="windowmainheadingstd addflexhd">
                                    {/* v9 has specific style but ive used generic for the moment */}
                                        Itinerary
                                </div>
                                {/* <div className="viewmapbtorng"
                                onClick={() => setIsWindowMapOpenCruises(true)}
                                >View map</div> */}
                            </div>
                            <div className="itinerarybox">
                                <div className="itineraryflexbox">
                                    <div className="itincirccont">
                                        <div className="itincirc circinv">1</div>
                                    </div>
                                </div> 
                                <div className="itineraryflexbox">
                                    <div className="itinlinecont">
                                        <div className="itinlineleft"></div>
                                        <div className="itinlineright"></div>
                                    </div>
                                    <div className="itinerarytextbox">
                                        <div className="itinerarymiddletext midtextextra">{selectedCRepPP.other_destinations[0][0]}{", "}{selectedCRepPP.other_destinations[0][1]}</div>
                                        
                                        <div className="itinerarybottomtext">Depart&nbsp;<span className="bottextkeyword">{selectedCRepPP.other_destinations[0][6]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{selectedCRepPP.other_destinations[0][8]}</span></div>
                                    </div>
                                </div> 

                                {
                                selectedCRepPP.other_destinations.slice(1,-1).map((destination: any, index: number) => {
                                    return <div key={index}>
                                                <div className="itineraryflexbox">
                                                    <div className="itincirccont">
                                                        <div className="itincirc circ">{index + 2}</div>
                                                    </div>
                                                    {/* <div className="itinerarytextbox"></div> */}
                                                </div> 
                                                <div className="itineraryflexbox">
                                                    <div className="itinlinecont">
                                                        <div className="itinlineleft"></div>
                                                        <div className="itinlineright"></div>
                                                    </div>
                                                    <div className="itinerarytextbox">
                                                        <div className="itinerarymiddletext"><span className="itinmidtextinner">{destination[0]}{", "}{destination[1]}</span></div>

                                                        <div className="itinerarybottomtext">Arrive&nbsp;<span className="bottextkeyword">{destination[5]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{destination[7]}</span></div>
                                                        <div className="itinerarybottomtext">Depart&nbsp;<span className="bottextkeyword">{destination[6]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{destination[8]}</span></div>
                                                    </div>
                                                </div> 
                                        </div>
                                })
                                }
                                    

                                <div className="itineraryflexbox">
                                    <div className="itincirccont">
                                        <div className="itincirc circinv">{selectedCRepPP.other_destinations.length}</div>
                                    </div>
                                </div> 
                                <div className="itineraryflexbox">
                                    <div className="itinlinecont">
                                    </div>
                                    <div className="itinerarytextbox">
                                        <div className="itinerarymiddletext midtextextra">{selectedCRepPP.other_destinations[selectedCRepPP.other_destinations.length-1][0]}{", "}{selectedCRepPP.other_destinations[selectedCRepPP.other_destinations.length-1][1]}</div>

                                        <div className="itinerarybottomtext">Arrive&nbsp;<span className="bottextkeyword">{selectedCRepPP.other_destinations[selectedCRepPP.other_destinations.length-1][5]}</span>&nbsp;at&nbsp;<span className="bottextkeyword">{selectedCRepPP.other_destinations[selectedCRepPP.other_destinations.length-1][7]}</span></div>

                                    </div>
                                </div> 

                            </div>

                            <div className="itinminuspad"></div>

                            {
                            (selectedCRepPP.description !== "" || selectedCRepPP.extra_info !== "")
                            &&
                            <div className="windowmainboxstd">
                                <div className="windowmainheadingstd">
                                    About
                                </div>   
                                <div className="windowmaintextboxstd">
                                    {selectedCRepPP.description}
                                </div>
                                {
                                selectedCRepPP.extra_info !== ""
                                &&
                                <div className="windowmaintextboxext">
                                    <div className="exclambox">
                                        <IonIcon icon={alertCircle} className="exclam">
                                        </IonIcon>
                                    </div>
                                    <div className="exclamtext">{selectedCRepPP.extra_info}</div>
                                </div>
                                }
                            </div>
                            }
                            {
                            selectedCRepPP.entertainers !== ""
                            &&
                            props.peopleOth.filter((person: any) => selectedCRepPP.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                            &&
                            <div className="windowmainheadingstd">
                                Featured entertainers
                                {/* make above conditional on length in terms of 's' */}
                            </div>
                        }
                        </div>
                        {
                            selectedCRepPP.entertainers !== ""
                            &&
                            props.peopleOth.filter((person: any) => selectedCRepPP.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                            &&
                                            <div className="windowgrid"
                                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                            >
                                                {
                                                    // put logic in useeffect
                                                    props.peopleOth.filter((person: any) => selectedCRepPP.entertainers?.some((entertainer: any) => entertainer === person.name))
                                                    .map((person: any, index: any) => {
                                                        return <div key={index} className="gridviewcardstd"
                                                        onClick={() => handleChangePersonPart2(person)}
                                                        >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}

                                                                    />

                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt=""
                                                                    />

                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                {
                                                                person.type[0] === "Drag queen"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedrag ">Drag</div>
                                                                }      
                                                                {
                                                                person.type[0] === "DJ"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedj">DJ</div>
                                                                }         
                                                                {
                                                                person.type[0] === "Singer"
                                                                &&
                                                                <div className="gridviewtypebubbletour typesing">Singer</div>
                                                                }          
                                                                {
                                                                person.type[0] === "Other"
                                                                &&
                                                                <div className="gridviewtypebubbletour typeoth">Other</div>
                                                                }
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainer">               
                                                            
                                                                            <div className="gridviewtitlecontainerfull">
                                                                                <div className="gridviewtitlefull">
                                                                                    {person.name}
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>                       
                                                    })
                                                    }
                                                </div>
                            }
                                    
                            <div className="windowmainsetwidthNW">
                            <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                selectedCRepPP.organiser !== ""
                                &&
                                <div
                                style={selectedCRepPP.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {selectedCRepPP.organiser}
                                    </div>
                                </div>
                                }
                                {
                                selectedCRepPP.ticket_provider !== ""
                                &&
                                <div
                                style={selectedCRepPP.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedCRepPP.ticket_provider}
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="floatingbuttoncont">
                                                {
                                                (selectedCRepPP.tickets_available)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                (!selectedCRepPP.tickets_available)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                
                            </div>

                            

                            

                        {/* </div> */}

                            
                            
                            

                        </div>

                        <div className="spaceforbtn"></div>
                    
                    </IonContent>
                    </div>
                

                    
                </div>


                <div className="windbotcont" onClick={props.handleCloseWindPP}>

                            {
                                            (selectedCRepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPC}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            (!selectedCRepPP.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPPC}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            
                        </div>
                    
                        

                    </div>

                }
                </>
                }
                    




                    {
                        isRepBCOpenPP
                        &&
                        <div className="testwindowabs" onClick={props.handleCloseWindPP}>
                        <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                        {/* <div className="arrowtest"></div> */}
                        <div className="newwinimgcont">


                                    
                                            {
                                                imgOrMapPP==="img"
                                                &&
                                                selectedBCRepPP.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRepPP.image_src}
                                                onError={(e: any) => e.target.src = selectedBCRepPP.image_url}
                                                ></img>
                                            }
                                            {
                                                imgOrMapPP==="img"
                                                &&
                                                !selectedBCRepPP.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRepPP.image_url}
                                                ></img>
                                            }

                                            {
                                                imgOrMapPP==="map"
                                                &&
                                                // <div className="mapcontainer">
                                                    <div ref={mapContainerPP} className="mapcontainer" />
                                                    //can i reuse same map container? do i need to do something in useeffect?
                                                // </div>
                                            }


                                            <div className="segmentconNW">
                                                <div className={imgOrMapPP === "img" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapPP("img")}
                                                >IMAGE</div>
                                                <div className={imgOrMapPP === "map" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapPP("map")}
                                                >MAP</div>
                                            </div>

                                            
                                                <div className="bckicncont"
                                                onClick={handleClBackWindBCRepPP}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>
                                            
                                            
                                        

                        </div>
                    
                    
                    <div className="newwininfocont">
                    <IonContent className="contentpad">

                    
                    

                    
                    
                            <div className="windowmainsetwidthNW">


                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {selectedBCRepPP.name}
                                    </div>
                                </div>
                                


                                
                                
                                {
                                (selectedBCRepPP.description !== "" || selectedBCRepPP.extra_info !== "")
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRepPP.description}
                                    </div>
                                        {
                                    selectedBCRepPP.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedBCRepPP.extra_info}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {
                                selectedBCRepPP.price_info !== ""
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Cover
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRepPP.price_info}
                                    </div>
                                </div>
                                }
                                
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Address
                                    </div>
                                    <div className="windowmainvenuecardbox">
                                        <div className="windowvenueleft">
                                        {selectedBCRepPP.suite_number === "" ? "" : <div>{selectedBCRepPP.suite_number}</div>}
                                            <div className="addressfirstrowflexbox">
                                                
                                                {selectedBCRepPP.street_number === "" ? <div>{selectedBCRepPP.street_name}</div> : <div className="limitaddresstooneline">{selectedBCRepPP.street_number}{" "}{selectedBCRepPP.street_name}</div>}
                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                            </div>
                                            <div>{selectedBCRepPP.suburb} {selectedBCRepPP.postcode}</div>
                                        </div>
                                        {/* <div className="windowvenueright">
                                            <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenBC(true)}>
                                                View map
                                            </div>
                                        </div> */}
                                    </div>
                                </div>



                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                    Opening hours
                                    </div>
                                    <div className="openinghoursbox">
                                    <div className="timetablebox">
                                        <div className="">
                                            <div className="weekday">
                                                Monday
                                            </div>
                                            <div className="weekday">
                                                Tuesday
                                            </div>
                                            <div className="weekday">
                                                Wednesday
                                            </div>
                                            <div className="weekday">
                                                Thursday
                                            </div>
                                            <div className="weekday">
                                                Friday
                                            </div>
                                            <div className="weekday">
                                                Saturday
                                            </div>
                                            <div className="weekday">
                                                Sunday
                                            </div>
                                        </div>
                                        <div className="BCtimes">
                                    
                                            {
                                            selectedBCRepPP.monday_open !== ""
                                            &&
                                            !(selectedBCRepPP.monday_open === "12am" && selectedBCRepPP.monday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.monday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.monday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.monday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.monday_open === "12am" && selectedBCRepPP.monday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.tuesday_open !== ""
                                            &&
                                            !(selectedBCRepPP.tuesday_open === "12am" && selectedBCRepPP.tuesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.tuesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.tuesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.tuesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.tuesday_open === "12am" && selectedBCRepPP.tuesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.wednesday_open !== ""
                                            &&
                                            !(selectedBCRepPP.wednesday_open === "12am" && selectedBCRepPP.wednesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.wednesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.wednesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.wednesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.wednesday_open === "12am" && selectedBCRepPP.wednesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.thursday_open !== ""
                                            &&
                                            !(selectedBCRepPP.thursday_open === "12am" && selectedBCRepPP.thursday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.thursday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.thursday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.thursday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.thursday_open === "12am" && selectedBCRepPP.thursday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.friday_open !== ""
                                            &&
                                            !(selectedBCRepPP.friday_open === "12am" && selectedBCRepPP.friday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.friday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.friday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.friday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.friday_open === "12am" && selectedBCRepPP.friday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.saturday_open !== ""
                                            &&
                                            !(selectedBCRepPP.saturday_open === "12am" && selectedBCRepPP.saturday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.saturday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.saturday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.saturday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.saturday_open === "12am" && selectedBCRepPP.saturday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepPP.sunday_open !== ""
                                            &&
                                            !(selectedBCRepPP.sunday_open === "12am" && selectedBCRepPP.sunday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.sunday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepPP.sunday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepPP.sunday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepPP.sunday_open === "12am" && selectedBCRepPP.sunday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="floatingbuttoncont">
                        {/* {
                            selectedBarClub.facebook
                            &&
                            <div className="socialmediabtn fbbtn"
                            onClick={()=>window.open(selectedBarClub?.facebook, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoFacebook} className="sharebtniconlg" />
                            </div>
                        </div>
                        } */}
                        
                            {/* <div className="sharebuttonsm">
                                <div className="floatbtnskew">
                                    <IonIcon icon={shareOutline} color="" className="sharebtniconlg" />
                                </div>
                            </div> */}
                        

                        {
                        selectedBCRepPP.website !== ""
                        &&
                        <div className="socialmediabtn"
                        onClick={()=>window.open(selectedBCRepPP.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRepPP.website === ""
                        &&
                        <div className="socialmediabtn"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={()=>window.open(`https://www.instagram.com/${selectedBCRepPP.instagram}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                        

                        

                    </div>

                                

                                
                       

                            
                                
                    <div className="spaceforbtn"></div>
                               
                                
                    </div>
                        
                    </IonContent>
                    </div>

                    
                </div>

                <div className="windbotcont" onClick={props.handleCloseWindPP}>
                    <div className="floatingbuttoncontweb">
                {
                        selectedBCRepPP.website !== ""
                        &&
                        <div className="socialmediabtnwebBC"
                        onClick={handleClickLinkPPBC}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRepPP.website === ""
                        &&
                        <div className="socialmediabtnwebBC"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={handleClickLinkPPInstBC}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                                            
                                           
                    </div>
                                    
                </div>
            </div>
            }
                
        </>
            
    )
}

export default NewWindPP;