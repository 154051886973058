import mapboxgl from 'mapbox-gl';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonIcon,
    IonModal,
    IonBackdrop
} from '@ionic/react';

import BarAndClubWindow from './BarAndClubWindow';

import { arrowForwardOutline } from 'ionicons/icons';

import 'mapbox-gl/dist/mapbox-gl.css';

import '../theme/maprelated.css';
import '../theme/spotcards.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const Marker: React.FC<{
    onClick: any;
    // zoomLevel: any;
    // children: any;
    feature: any;
    hoverNo: any;
}> = ({
    onClick,
    // zoomLevel,
    // children,
    feature,
    hoverNo
}) => {
    // const _onClick = () => {
    //   onClick(feature.properties.description);
    // };

    // const [stateTog, setStateTog] = useState<any>(false);
    // useEffect(() => {
    //     setStateTog(!stateTog);
    // }, [hoverNo])
    //above is alternative way to force just the markers to rerender. didnt work.

    return (
      <button
    //   onClick={onClick}
      className={hoverNo === feature.properties.description ? "markerH" : "marker"}>
        {/* {children} */}
            <div>{feature.properties.description}</div>
      </button>
    );
  };

//   i think tomorrow go back to default (blue) markers and have the flyto funcction in the pop up menu. also when you click open a popup.


const MapViewMulti: React.FC<{
    selectedBC: any;
    BCView: any;
    filteredBarsAndClubs: any;
    filteredGeoJSONBC: any;
    cityCoordinates: any;
    cityZoom: any;
    citySelected: any;
    // rootsContainingMarkers: any;
    // setRootsContainingMarkers: any;
    setHoverNo: any;
    handleOnMouseOut: any;
    hoverNo: any;
    zoomInLv: any;
    fromCirc: any;
    view: any;

    handleOpenWindBC: any;

    barsAndClubs: any[];
    parties: any;
    gridNo: any;
    currentDateAndTime: any;
    currentDateAndTimePlusTwoWeeks: any;
    peopleOth: any;
    handleIncrClick: any;
    // fromMp: any;
    count: any;
}> = ( {
    selectedBC,
    BCView,
    filteredBarsAndClubs,
    filteredGeoJSONBC,
    cityCoordinates,
    cityZoom,
    citySelected,
    // rootsContainingMarkers,
    // setRootsContainingMarkers,
    setHoverNo,
    handleOnMouseOut,
    hoverNo,
    zoomInLv,
    fromCirc,
    view,
    handleOpenWindBC,

    barsAndClubs,
    parties,
    gridNo,
    currentDateAndTime,
    currentDateAndTimePlusTwoWeeks,
    peopleOth,
    handleIncrClick,
    // fromMp,
    count
} ) => {


    const mapContainerBCMult = useRef<any>(null);

    const [myMap, setMyMap] = useState<any>(null);

    const [rootsContainingMarkers, setRootsContainingMarkers] = useState<any>(null);

    const [myMapboxMarkers, setMyMapboxMarkers] = useState<any>(null);

    const [isWindowMapLoadedSau, setIsWindowMapLoadedSau] = useState<any>(false);
    const [isWindOpenMp, setIsWindOpenMp] = useState(false);



    // const [imgOrMapBC, setImgOrMapBC] = useState<any>("map");

    // const handleImgOrMapBC = (viewClicked: string) => {
    //     if (viewClicked === imgOrMapBC) return;
    //     if (viewClicked === "img") setImgOrMapBC("img");
    //     if (viewClicked === "map") setImgOrMapBC("map");
    //   };

    const [selectedItem, setSelectedItem] = useState<any>(null);



    const handleOpenWindMp = (e:any, item:any) => {
        setIsWindOpenMp(true);
        e.stopPropagation();
        // setSelectedItem(item);
        handleOpenWindBC(item);
        // setIsWindOpenMp(true);
        
    }

      const handleCloseWindMp = () => {
        setIsWindOpenMp(false);
        setSelectedItem(null);
        // setHoverNo(0);
      }

      

      

    useEffect(() => {
        
        // if (imgOrMapBC === "img") {
        //     console.log("img");
        //     return
        // }


        if (!isWindowMapLoadedSau) {
            console.log('window isnt loaded');
            return;
        }

        

        console.log("newmapbeingcreated");
        console.log(cityCoordinates, 'city coords')

        const mapBCMult = new mapboxgl.Map({
            container: mapContainerBCMult.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: cityCoordinates,
            zoom: cityZoom,
            attributionControl: false
        });

        // var zoomLevel;

        // mapBCMult.on('zoom', () => {
        //     zoomLevel = mapBCMult.getZoom();
        //     console.log(zoomLevel);
        // });

        setMyMap(mapBCMult);
        
        return () => {
            console.log('map removed big');
            mapBCMult.remove();
        }

    },[cityCoordinates,
        isWindowMapLoadedSau,
        // imgOrMapBC
    ]);

    // the logic im using here is different from eg newWindP. im not unmounting map when i click image. im just putting image over it using absolute positioning. this is because its more complicated to relaunch image when you are zoomed in (it was naturally zooming out again).

    useEffect (() => {

        if (myMap === null) {
            console.log(filteredGeoJSONBC, "my map is null");
            return;
        }

        if (myMap === undefined) {
            console.log(filteredGeoJSONBC, "my map is undefined");
            return;
        }

        if (!filteredGeoJSONBC) {
            console.log(filteredGeoJSONBC, "no filtered geojson");
            return
        }

        // why do i need if statements

        if (myMapboxMarkers) {
            myMapboxMarkers.forEach((marker: any) => marker.remove());
            setMyMapboxMarkers(null);
        }

        if (rootsContainingMarkers) {
            rootsContainingMarkers.forEach((rootparam: any) => rootparam.unmount());
            setRootsContainingMarkers(null);
        }

        // is the above the only way to remove these things... like theres no point having state if its going to be wiped on each reload..

        const arrayOfMapBoxMarkers: any = [];
        const arrayOfRoots: any = [];
        
        if (myMap) {
            // Render custom marker components
            filteredGeoJSONBC.features.forEach((feature: any) => {
                // Create a React ref
                // const ref = useRef<any>(null);
                // Create a new DOM node and save it to the React ref
                // const ref = React.createRef<any>();

                console.log(zoomInLv, 'zoom');

                const el = document.createElement("div");
                el.className = 'marker';
                // Render a Marker Component on our new DOM node
                const root = createRoot(el);
                arrayOfRoots.push(root);
                root.render(
                <Marker
                onClick={()=>myMap.flyTo({center: feature.geometry.coordinates, zoom: zoomInLv})}
                feature={feature} hoverNo={hoverNo}/>);

                // Create a Mapbox Marker at our new DOM node
                const mapboxMarker = new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .addTo(myMap);

                arrayOfMapBoxMarkers.push(mapboxMarker);

        });
        }

        setMyMapboxMarkers(arrayOfMapBoxMarkers);
        setRootsContainingMarkers(arrayOfRoots);
        console.log(arrayOfMapBoxMarkers, 'what do mapbox markers look like');
        console.log(arrayOfRoots, 'what do roots look like');

        return () => {
            console.log('map removed big markers');
            // mapBCMult.remove();
            if (myMapboxMarkers) {
                myMapboxMarkers.forEach((marker: any) => marker.remove());
                setMyMapboxMarkers(null);
            }
    
            if (rootsContainingMarkers) {
                rootsContainingMarkers.forEach((rootparam: any) => rootparam.unmount());
                setRootsContainingMarkers(null);
            }
        }

    }, [myMap, filteredGeoJSONBC,
        hoverNo
        // there must be a better way to change the format without reloading ALL the markers again
    ])




// so there was an issue switching between cities while the map was open because this would try to load filteredgeojson of previous city. so ive
// set a conditional in bars and clubs which says unless the first filered bar or club has a city name of sydney then dont render the map
// it works but obviously not the best way to do it... 
// so why didnt i need that this time...


    useEffect(() => {
        if (!myMap) {
            return;
        }
        if (selectedBC
            // && !fromMp
            ) {
            myMap.flyTo({center: [selectedBC.lng, selectedBC.lat], zoom: zoomInLv})
        }
        if (!selectedBC
            // && !fromMp
            ) {
            myMap.flyTo({center: cityCoordinates, zoom: cityZoom})
        }
    }, [selectedBC]);
    // is there a possibility that a bar card could be clicked before map has loaded? not accounting for that
    // okay ive put first conditional..not 100% sure about it


    useEffect(() => {
        console.log('my map is this changing so')
        setIsWindowMapLoadedSau(true);
    }, []);


    useEffect(() => {
        if (!myMap || !mapContainerBCMult.current) {
            return;
        }
        myMap.resize();
    }, [view, myMap,
        // filteredBarsAndClubs,
        count
    ])

    return (
        
            <div className="mapboxstyleBC">
                    {/* <div className="mapboxstyle"> */}
                        <div ref={mapContainerBCMult} className="mapcontainerBC" />
                    {/* </div> */}
                    
                    {
                        selectedBC
                        &&
                        selectedBC.suburb
                        &&
                        <div className="testcont2">
                                <div className="venuebox">
                                            {selectedBC?.suite_number === "" ? "" : <div>{selectedBC?.suite_number}</div>}
                                                <div className="addressfirstrowflexbox">
                                                    {selectedBC?.street_number === "" ? <div>{selectedBC?.street_name}</div> : <div className="limitaddresstooneline">{selectedBC?.street_number}{" "}{selectedBC?.street_name}</div>}
                                                </div>
                                                <div>{selectedBC?.suburb} {selectedBC?.postcode}</div>
                                            </div>

                    </div>
                    }
                    {
                        selectedBC
                        &&
                        !selectedBC.suburb
                        &&
                        <div className="testcont2">
                                {selectedBC.city}

                    </div>
                    }
                    
                








                {/* {
                imgOrMapBC==="img"
                &&
                selectedBC?.image_src
                &&
                <img className="windowimgboxNBC" src={selectedBC?.image_src}
                onError={(e: any) => e.target.src = selectedBC?.image_url}
                ></img>
            }
            {
                imgOrMapBC==="img"
                &&
                !selectedBC?.image_src
                &&
                <img className="windowimgboxNBC" src={selectedBC?.image_url}
                ></img>
            }
                {
                    selectedBC
                    &&
                <div className="segmentconBC">
                    <div
                    className={imgOrMapBC === "img" ? "segon" : "segoff"}
                    onClick={()=>handleImgOrMapBC("img")}
                    >IMAGE</div>
                    <div
                    className={imgOrMapBC === "map" ? "segon" : "segoff"}
                    onClick={()=>handleImgOrMapBC("map")}
                    >MAP</div>
                </div>
                } */}

            <div className="mapviewcontainerBC">
                <IonContent className="mapioncontent">
                            {
                                filteredBarsAndClubs?.map((barclub: any, index: any) => {
                                    return <div
                                            className="mapspotcard"
                                            key={index}

                                            onClick={()=>myMap.flyTo({center: [barclub.lng, barclub.lat], zoom: zoomInLv})}

                                            onMouseOver={()=>setHoverNo(barclub.order)}
                                            // onFocus={()=>console.log("focus hello")}
                                                // onMouseOut={()=>setHoverNo(0)}
                                                onMouseOut={handleOnMouseOut}
                                            >   
                                        <div className="mapspotfirstsect">
                                            {/* <div className="BCcardflexboxleftmapview"> */}
                                                <div className="mapspotcirc">{barclub.order}</div>
                                            {/* </div> */}
                                        </div>
                                        <div className="mapspotsecondsect">
                                            {
                                                barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_src} alt=""
                                                onError={(e: any) => e.target.src = barclub.image_url}
                                                ></img>
                                            }
                                            {
                                                !barclub.image_src
                                                &&
                                                <img className="mapspotsecondsectimg" src={barclub.image_url} alt=""
                                                ></img>
                                            }
                                        </div>
                                        <div className="mapspotmainsect">
                                            <div className="mapspotmainsectinner">
                                                <div className="mapspotmainsectinnert">{barclub.name}</div>
                                                {
                                                    !fromCirc
                                                    &&
                                                <div className="mapspotmainsectinnerb">{barclub.suburb}</div>
                                                }
                                                {
                                                    fromCirc
                                                    &&
                                                <div className="mapspotmainsectinnerb">{barclub.city}{", "}{barclub.country}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="mapspotlastsection">
                                            {/* <Link to={`/barsandclubs/${barclub.id}`} style={{ display: 'contents' }}> */}
                                                    <div className="mapspotarrowcont" onClick={(e) => handleOpenWindMp(e, barclub)}>
                                                        <IonIcon icon={arrowForwardOutline} className="mapspotarrow" />
                                                    </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                })
                            }
                </IonContent>
            </div>

            {/* {
                        isWindOpenMp
                        &&
                        !fromCirc
                        &&
                            <div className="tranabs2"
                            onClick={handleCloseWindMp}
                            >
                            <BarAndClubWindow
                            handleCloseWindBC={handleCloseWindMp}
                            selectedBarClub={selectedItem}
                        barsAndClubs={barsAndClubs}
                        parties={parties}
                        currentDateAndTime={currentDateAndTime}
                        currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                    
                        gridNo={gridNo}
                        peopleOth={peopleOth}
                        handleIncrClick={handleIncrClick}
                        />

                   
                        

                        </div>
                        
                    } */}


                
            </div>
            
    );
}

export default MapViewMulti;

// search app version for that change that needs to be made in the app
// do the use effect or whatever will make this smoother