import mapboxgl from 'mapbox-gl';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonIcon,
    IonModal,
    IonBackdrop
} from '@ionic/react';

import { arrowForwardOutline } from 'ionicons/icons';

import 'mapbox-gl/dist/mapbox-gl.css';

import '../theme/maprelated.css';
import '../theme/spotcards.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const MarkerCruise: React.FC<{
    onClick: any;
    feature: any;
}> = ({
    onClick,
    feature,
}) => {
   

    return (
      <button
      onClick={onClick}
      className="marker">
            <div>{feature.properties.description}</div>
      </button>
    );
  };



const MapViewMultiC: React.FC<{
    selectedCruise: any;
    // imgOrMapC: any;
}> = ( {
    selectedCruise,
    // imgOrMapC
} ) => {

    const [rootsContainingMarkersC, setRootsContainingMarkersC] = useState<any>(null);

    const [myMapboxMarkersC, setMyMapboxMarkersC] = useState<any>(null);

    const rootsRef = useRef<any>(null);
    const markersRef = useRef<any>(null);

    const mapContainerC = useRef<any>(null);

useEffect(() => {

        

        // if (imgOrMapC !== "map") {
        //     return
        // }

        const zoomLevel:number = selectedCruise.timeframe;

        const mapC = new mapboxgl.Map({
            container: mapContainerC.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [selectedCruise.start_destination, selectedCruise.finish_destination],
            zoom: zoomLevel,
            attributionControl: false
        });

        
        const arrayOfMapBoxMarkers: any = [];
        const arrayOfRoots: any = [];


        selectedCruise.geojson_data.features.forEach((feature: any) => {
            console.log("marker get created when component unmounts?")
            const el = document.createElement("div");
            el.className = 'marker';
            const root = createRoot(el);

            arrayOfRoots.push(root);

            root.render(
            <MarkerCruise onClick={()=>mapC.flyTo({center: feature.geometry.coordinates, zoom: 6})} feature={feature} />);

            // Create a Mapbox Marker at our new DOM node
            const mapboxMarker = new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .addTo(mapC);
            
            arrayOfMapBoxMarkers.push(mapboxMarker);
        });
        console.log('marker does this run on unmount?');
        setMyMapboxMarkersC(arrayOfMapBoxMarkers);
        setRootsContainingMarkersC(arrayOfRoots);
        console.log(arrayOfMapBoxMarkers, 'what do mapbox markers look like');
        console.log(arrayOfRoots, 'what do roots look like');

        // return () => mapC.remove();

        return () => {
            console.log("map cruises demounting");
            console.log(myMapboxMarkersC, markersRef.current, 'marker marker marker')
            if (markersRef.current) {
                console.log('got here marker');
                markersRef.current.forEach((marker: any) => marker.remove());
                setMyMapboxMarkersC(null);
            }
            console.log(myMapboxMarkersC, rootsRef.current, 'marker marker marker marker')
            if (rootsRef.current) {
                rootsRef.current.forEach((rootparam: any) => rootparam.unmount());
                setRootsContainingMarkersC(null);
            }
            mapC.remove();
        }

        // return function cleanup() {
        //     console.log("map cruises demounting");
        //     console.log(myMapboxMarkersC, 'marker marker marker')
        //     if (myMapboxMarkersC) {
        //         console.log('got here marker');
        //         myMapboxMarkersC.forEach((marker: any) => marker.remove());
        //         setMyMapboxMarkersC(null);
        //     }
    
        //     if (rootsContainingMarkersC) {
        //         rootsContainingMarkersC.forEach((rootparam: any) => rootparam.unmount());
        //         setRootsContainingMarkersC(null);
        //     }
        //     mapC.remove();
        //   };

        //above in theory would work if map was demounting... so maybe betst thing to do is create mapboxmulticruises component. remove these extra logic things because i want to see what happens to markers/roots without removing them when comonent gets demounted.


        // return function cants access latest state for some reason so i had to put latest state in refs. that has created the warning about 'attempted to synchronously unmount a root while React was already rendering' which is the same as the one ive got when i hover over markers in Bars and Clubs...
        // something connected to the createRoot function.. google how to unmount root properly..




        },[]);

        useEffect(() => {
            console.log(myMapboxMarkersC, rootsContainingMarkersC, 'what is going on marker');
            markersRef.current = myMapboxMarkersC;
            rootsRef.current = rootsContainingMarkersC;
        }, [myMapboxMarkersC, rootsContainingMarkersC]);

        return (
        
            
                
                    <div ref={mapContainerC} className="mapcontainer" />
                
                
            
            
    );
}

export default MapViewMultiC;