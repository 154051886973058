import { useState, useEffect } from 'react';

import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { Directory, Filesystem } from '@capacitor/filesystem';

import { Capacitor } from '@capacitor/core';

import { isPlatform } from '@ionic/core';

// onesignal
// import OneSignal from 'onesignal-cordova-plugin';

import { Image } from '../types/Image';

const ALLIMAGES_KEY = 'all-images';

const PARTIESLIKED_KEY = 'parties-liked';
const DRAGEXPOSLIKED_KEY = 'dragexpos-liked';
const BARSANDCLUBSLIKED_KEY = 'barsandclubs-liked';
const PEOPLELIKED_KEY = 'people-liked';
const CRUISESLIKED_KEY = 'cruises-liked';

const NOTIFICATIONSVIEWED_KEY = 'notifications-viewed';
const DEFCITY_KEY = 'def-city';
const CITIESNOTIFS_KEY = 'cities-notifs';

const PROMOSVIEWED_KEY = 'promos-viewed';



export function useStorage() {

    const [store, setStore] = useState<Storage>();

    const [storedImages, setStoredImages] = useState<Image[]>([]);
    const [likedParties, setLikedParties] = useState<any[]>([]);
    const [likedDragExpos, setLikedDragExpos] = useState<any[]>([]);
    const [likedBarsAndClubs, setLikedBarsAndClubs] = useState<any[]>([]);
    const [likedPeople, setLikedPeople] = useState<any[]>([]);
    const [likedCruises, setLikedCruises] = useState<any[]>([]);
    const [viewedNotifications, setViewedNotifications] = useState<any[]>([]);
    const [defCity, setDefCity] = useState<any>("");
    const [citiesNotifs, setCitiesNotifs] = useState<any[]>([]);
    const [viewedPromos, setViewedPromos] = useState<any[]>([]);


    const [storeIsLoaded, setStoreIsLoaded] = useState(false);

    const [imagesAreLoaded, setImagesAreLoaded] = useState(false);
    // const [likedPartiesAreLoaded, setLikedPartiesAreLoaded] = useState(false);
    // const [likedDragExposAreLoaded, setLikedDragExposAreLoaded] = useState(false);
    // const [likedBarsAndClubsAreLoaded, setLikedBarsAndClubsAreLoaded] = useState(false);
    // const [likedPeopleAreLoaded, setLikedPeopleAreLoaded] = useState(false);
    // const [likedCruisesAreLoaded, setLikedCruisesAreLoaded] = useState(false);
    const [viewedNotificationsAreLoaded, setViewedNotificationsAreLoaded] = useState(false);
    const [defCityIsLoaded, setDefCityIsLoaded] = useState(false);
    const [cityNotifsAreLoaded, setCityNotifsAreLoaded] = useState(false);

    const [viewedPromosAreLoaded, setViewedPromosAreLoaded] = useState(false);

    const [firstLoad, setFirstLoad] = useState(false);
    
    useEffect(() => {
      const initStorage = async () => {

        // Create a connection to the Storage and save the reference
        const newStore = new Storage({
          name: 'ionicstorage',
          driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        });
        await newStore.defineDriver(CordovaSQLiteDriver);
        const store = await newStore.create();
        setStore(store);
        console.log(store, 'New connection to store.');
  
        // Load all stored items. Note use of 'saved' in variable names to avoid clash with 'stored' in some state names.
        const savedImages = await store!.get(ALLIMAGES_KEY) || [];
        if (savedImages.length === 0) {
            setFirstLoad(true);
        }


        // if (isPlatform('hybrid')) {
        //     for (let i = 0; i < savedImages.length; i++) {
        //         console.log('this should not run in web');
        //         const file = await Filesystem.getUri({
        //             path: savedImages[i].fileName,
        //             directory: Directory.Data
        //         });
        //         savedImages[i].useableFilePath = Capacitor.convertFileSrc(file.uri);
        //     }
        // }


        setStoredImages(savedImages);
        //ive blanked out above for the moment. why does blanking out cause half the images to be re downloaded each time. and why no problem not having set state for cities notifs.
        // setImagesAreLoaded(true);
        // the above is done elsewhere now. okay update. i commmented it out and then the app seemed to delete and redownload half the images when it reset.

        const savedLikedParties = await store!.get(PARTIESLIKED_KEY) || [];
        setLikedParties(savedLikedParties);
        // setLikedPartiesAreLoaded(true);

        const savedLikedDragExpos = await store!.get(DRAGEXPOSLIKED_KEY) || [];
        setLikedDragExpos(savedLikedDragExpos);
        // setLikedDragExposAreLoaded(true);

        const savedLikedBarsAndClubs = await store!.get(BARSANDCLUBSLIKED_KEY) || [];
        setLikedBarsAndClubs(savedLikedBarsAndClubs);
        // setLikedBarsAndClubsAreLoaded(true);

        const savedLikedPeople = await store!.get(PEOPLELIKED_KEY) || [];
        setLikedPeople(savedLikedPeople);
        // setLikedPeopleAreLoaded(true);

        const savedLikedCruises = await store!.get(CRUISESLIKED_KEY) || [];
        setLikedCruises(savedLikedCruises);
        // setLikedCruisesAreLoaded(true);

        const savedViewedNotifications = await store!.get(NOTIFICATIONSVIEWED_KEY) || [];
        setViewedNotifications(savedViewedNotifications);
        setViewedNotificationsAreLoaded(true);

        const savedDefCity = await store!.get(DEFCITY_KEY) || "";
        setDefCity(savedDefCity);
        setDefCityIsLoaded(true);

        // const savedCitiesNotifs = await store!.get(CITIESNOTIFS_KEY) || ["Sydney", "Melbourne", "London", "San Francisco"];
        // setCitiesNotifs(savedCitiesNotifs);

        const savedViewedPromos = await store!.get(PROMOSVIEWED_KEY) || [];
        setViewedPromos(savedViewedPromos);
        setViewedPromosAreLoaded(true);

        setStoreIsLoaded(true);

      }

      initStorage();

    }, []);


    async function base64FromPath(path: string): Promise<string> {
        const response = await fetch(path);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject('method did not return a string');
                }
            };
    
            reader.readAsDataURL(blob);
        });
    }
    //note that the above is a function expression (is that the right term?) while the below are arrow functions... does that matter?

    
    const saveImageToFileSystem = async (imageUrl: any, fileName: string, id: number, name: string): Promise<Image> => {
        const base64data = await base64FromPath(imageUrl);
        // console.log(base64data, 'base64data');
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            directory: Directory.Data,
            data: base64data
        })
        // console.log(savedFile, 'file being saved');
        return {
            fileName: fileName,
            filePath: savedFile.uri,
            useableFilePath: Capacitor.convertFileSrc(savedFile.uri),
            // given that I am doing the above when I pull images from storage, I shouldn't really save images with this. but does it matter? also, at the moment I have to do it here for new images that I haven't been saved yet that otherwise won't have it because they won't be getting pulled from storage.
            // it seems redundant to then do it when I pull from storage but if I remember correctly I HAVE to do it when I pull from storage when there is a new release of the app... something to do with sandboxing connection to filesystem between versions...
            id: id,
            imageUrl: imageUrl,
            name: name
            // category: category
        }
    }
    

    const addArrayOfImagesToFileSystemThenAddToState = async (arrayOfImagesToStore: any) => {
        console.log('this function also ran why not');
        const arrayOfDetailsOfNewImagesSavedToFileSystem = await Promise.all(arrayOfImagesToStore.map(async (image: any) => {
            return saveImageToFileSystem(image.url, image.name + '.jpeg', image.id, image.name);
            // is the above return keyword redundant?
        }));
        
        if (!isPlatform('hybrid')) {
            const arrayofImageDetailsWithModification = await Promise.all(arrayOfDetailsOfNewImagesSavedToFileSystem.map(async (image: any) => {
                const file = await Filesystem.readFile({
                    path: image.fileName,
                    directory: Directory.Data
                });
                image.useableFilePath = `data:image/jpeg;base64,${file.data}`;
                return image;
            }))
            setStoredImages((prev:any) => [...prev, ...arrayofImageDetailsWithModification]);
        } else {
            setStoredImages((prev:any) => [...prev, ...arrayOfDetailsOfNewImagesSavedToFileSystem]);
        }
        // so the flaw in doing the above here rather is that im storing the image inside Ionic Storage i think... it would be better to do it when you pull the image
        // from storage. but the issue is with needing the right useablefilepath the first time the image is created..
        //i mean i could set storage and then set state but thats not how ive seen it done so im wondering if thats not a good order
        // in any case it doesnt really matter since this is the web and i have no plans to have my app running on the web right now. well, see below and above.

        // in a similar way im now saving the convertfilesrc to the ionic storage but then replacing it when the app loads (on a phone not web). but i guess i still need it
        // for the first time an app is saved because otherwise wont have that. also i dont think saving (a redundant) convertfilesrc thing in storage is that bad
        // becasue its just a different way to represent the file path. not like with the web issue where i think i am actually storing base64 data in ionic storage.
    }

    const deleteFile = async (imgToDelete: any) => {
        Filesystem.deleteFile({
            path: imgToDelete.fileName,
            directory: Directory.Data
            });
    }


    const checkIonicStorageForExistingImagesDetermineAction = async (realtimedatabaseimagesdata: any) => {
        console.log('this runs');
        const imagesDataInIonicStorage = await store?.get(ALLIMAGES_KEY) || [];
        console.log(realtimedatabaseimagesdata, 'why not');
        // could get fuck up by JUST relyingo n lenght no? shouldnt the thing check for name match? like what if accidentally has wrong ones. or has one twice. would it self correct?
        if (imagesDataInIonicStorage.length > realtimedatabaseimagesdata.length) {
            //code below can handle multiple image deletions. previously, I could only do one at a time. not sure where I would have multiple so is this overdoing it?
            const arrayOfImagesToDelete: any = [];
            for (let i = 0; i < imagesDataInIonicStorage.length; i++) {
                if (!realtimedatabaseimagesdata.some((imgindb: any) => imagesDataInIonicStorage[i].name === imgindb.name)) {
                    arrayOfImagesToDelete.push(imagesDataInIonicStorage[i]);
                }
            }
            await Promise.all(arrayOfImagesToDelete.map(async (image: any) => {
                deleteFile(image);
            }));
            setStoredImages(imagesDataInIonicStorage.filter((image: any) => !arrayOfImagesToDelete.some((imgdeleted: any) => image.name === imgdeleted.name)));

        } else if (realtimedatabaseimagesdata.length > imagesDataInIonicStorage.length) {
            const arrayOfNewImagesNeedingDownload: any = [];
            for (let i = 0; i < realtimedatabaseimagesdata.length; i++) {
                if (
                // is below the right placement of exclamation mark. i think so because if saying no cases for particular image in ionic storage (pointer) that match at least one in realtime db. if exclamation sign was where the equal sign was it would be asking whether for each image there was at least one case of it not matching an id in realtime db which will true be true for all of them.
                !(imagesDataInIonicStorage.some((imginstorage:any) => realtimedatabaseimagesdata[i].name === imginstorage.name))
                ) {
                // console.log('image needing download');
                    arrayOfNewImagesNeedingDownload.push(realtimedatabaseimagesdata[i])
                }
            }
            addArrayOfImagesToFileSystemThenAddToState(arrayOfNewImagesNeedingDownload);
        }
    }

    const checkIonicStorageForExistingImagesDetermineActionAlt = async (realtimedatabaseimagesdata: any) => {
        console.log('this runs');
        const imagesDataInIonicStorage = await store?.get(ALLIMAGES_KEY) || [];
        console.log(realtimedatabaseimagesdata, 'why not');
        console.log(imagesDataInIonicStorage, 'why not 2');
        // could get fuck up by JUST relyingo n lenght no? shouldnt the thing check for name match? like what if accidentally has wrong ones. or has one twice. would it self correct?
       //months later update. what if hypothetically one image got deleted and one got added at the same time.
            //code below can handle multiple image deletions. previously, I could only do one at a time. not sure where I would have multiple so is this overdoing it?
            
            //so does it help prevent extra
            
            const arrayOfImagesToDelete: any = [];
            for (let i = 0; i < imagesDataInIonicStorage.length; i++) {
                if (!realtimedatabaseimagesdata.some((imgindb: any) => imagesDataInIonicStorage[i].name === imgindb.name)) {
                    arrayOfImagesToDelete.push(imagesDataInIonicStorage[i]);
                }
            }
            console.log(arrayOfImagesToDelete, 'why not 3');
            await Promise.all(arrayOfImagesToDelete.map(async (image: any) => {
                deleteFile(image);
            }));
            setStoredImages(imagesDataInIonicStorage.filter((image: any) => !arrayOfImagesToDelete.some((imgdeleted: any) => image.name === imgdeleted.name)));
            //not sure about above. would be better to set state once...
        
            const arrayOfNewImagesNeedingDownload: any = [];
            for (let i = 0; i < realtimedatabaseimagesdata.length; i++) {
                if (
                // is below the right placement of exclamation mark. i think so because if saying no cases for particular image in ionic storage (pointer) that match at least one in realtime db. if exclamation sign was where the equal sign was it would be asking whether for each image there was at least one case of it not matching an id in realtime db which will true be true for all of them.
                !(imagesDataInIonicStorage.some((imginstorage:any) => realtimedatabaseimagesdata[i].name === imginstorage.name))
                ) {
                // console.log('image needing download');
                    arrayOfNewImagesNeedingDownload.push(realtimedatabaseimagesdata[i])
                }
            }
            await addArrayOfImagesToFileSystemThenAddToState(arrayOfNewImagesNeedingDownload);


            //months later update. why is this below here. surely you just have it once at the top. well, see what i wrote at the top.
            setImagesAreLoaded(true);
        
    }

    const checkIonicStorageForExistingImagesDetermineActionAltAlt = async (realtimedatabaseimagesdata: any) => {
        console.log('this runs');
        const imagesDataInIonicStorage = await store?.get(ALLIMAGES_KEY) || null;
        console.log(realtimedatabaseimagesdata, 'why not');
        if (imagesDataInIonicStorage === null) {
            console.log('first img');
            //code below can handle multiple image deletions. previously, I could only do one at a time. not sure where I would have multiple so is this overdoing it?
            const arrayOfImagesToDelete: any = [];
            for (let i = 0; i < imagesDataInIonicStorage.length; i++) {
                if (!realtimedatabaseimagesdata.some((imgindb: any) => imagesDataInIonicStorage[i].name === imgindb.name)) {
                    arrayOfImagesToDelete.push(imagesDataInIonicStorage[i]);
                }
            }
            await Promise.all(arrayOfImagesToDelete.map(async (image: any) => {
                deleteFile(image);
            }));
            setStoredImages(imagesDataInIonicStorage.filter((image: any) => !arrayOfImagesToDelete.some((imgdeleted: any) => image.name === imgdeleted.name)));
            //not sure about above. would be better to set state once...
        
            const arrayOfNewImagesNeedingDownload: any = [];
            for (let i = 0; i < realtimedatabaseimagesdata.length; i++) {
                if (
                // is below the right placement of exclamation mark. i think so because if saying no cases for particular image in ionic storage (pointer) that match at least one in realtime db. if exclamation sign was where the equal sign was it would be asking whether for each image there was at least one case of it not matching an id in realtime db which will true be true for all of them.
                !(imagesDataInIonicStorage.some((imginstorage:any) => realtimedatabaseimagesdata[i].name === imginstorage.name))
                ) {
                // console.log('image needing download');
                    arrayOfNewImagesNeedingDownload.push(realtimedatabaseimagesdata[i])
                }
            }
            await addArrayOfImagesToFileSystemThenAddToState(arrayOfNewImagesNeedingDownload);
            setImagesAreLoaded(true);
        } else {
            console.log('second img');
            const arrayOfNewImagesNeedingDownload: any = [];
            for (let i = 0; i < realtimedatabaseimagesdata.length; i++) {
                
                    arrayOfNewImagesNeedingDownload.push(realtimedatabaseimagesdata[i])
                
            }
            await addArrayOfImagesToFileSystemThenAddToState(arrayOfNewImagesNeedingDownload);
            setImagesAreLoaded(true);
        }
        
    }

    const checkExistingNotifsAndDetermineAction = async (listcities: any) => {
        
        console.log('this runs');
        const citiesDataInIonicStorage = await store?.get(CITIESNOTIFS_KEY) || null;
        // console.log(listcities, 'why not');

        if (!citiesDataInIonicStorage) {
            // for (let i = 0; i < listcities.length; i++) {
                
            //     OneSignal.User.addTag(listcities[i], "true");
            //old above i think. this whole loop
            //     setCitiesNotifs(listcities[i]);
            //   }

            await Promise.all(listcities.map(async (city: any) => {
                // OneSignal.User.addTag(city, "true");
                setCitiesNotifs((prev: any)=>[...prev, city]);
                // why had i greyed second as well as first
            }));
            // does this need to be awaited
            

            setCityNotifsAreLoaded(true);
            //this refers to the else if below. im not accounting for deleting city logic. the below just accounts for new city. seems to get deleted anyway. but wouldnt be doing anything at one signal end.
            //ive commented out because it was adding not just the new city but also cities that the user had turned off
        }
        // else if (listcities.length > citiesDataInIonicStorage.length) {
            
        //     const citiesNotInStorage = [];
        //     for (let i = 0; i < listcities.length; i++) {
        //         if (
        //         !(citiesDataInIonicStorage.some((cityinstorage:any) => listcities[i] === cityinstorage))
        //         ) {
        //         citiesNotInStorage.push(listcities[i])
        //         }
        //     }
        //     await Promise.all(citiesNotInStorage.map(async (city: any) => {
        //         OneSignal.User.addTag(city, "true");
        //         setCitiesNotifs((prev: any)=>[...prev, city]);
        //     }));
        // }
        else {
            setCitiesNotifs(citiesDataInIonicStorage);
            setCityNotifsAreLoaded(true);
        }
        //send citynotifsareloaded through to main app
        
    }

    const checkDefCity = async () => {
        console.log('def city check runs');
        const savedDefCity = await store!.get(DEFCITY_KEY) || null;
        if (!savedDefCity) {
            setDefCity("none");
        } else {
            setDefCity(savedDefCity);
        }

        // setDefCity(savedDefCity);
        // setDefCityIsLoaded(true);
    }


    //useEffects

    useEffect(() => {
        console.log('useStorage hook ran.');
        // console.log(store, 'This is what store looks like.');
    });

    useEffect(() => {
        if (!storeIsLoaded) return;
        console.log('why not this is the useEffect that runs when storedImages change.')
        const setStoredAsync = async () => {
            await store!.set(ALLIMAGES_KEY, storedImages);
            //change the question mark to exclamation mark... because store has to be loaded for the function to run....
            // setImagesAreLoaded(true);
        }
        if (storeIsLoaded) {
            setStoredAsync();
        }
    }, [storedImages, storeIsLoaded]);
    // What was my reasoning here around storedIsLoaded. also why did I take off the stored images must be greater than 0 conditional?
    //months later update. i am just going to add a conditional return if !storeIsLoaded...makes sense now.
    // but why does it need to be await..since nothing is awaiting it.....(and therefore why even need async function)

    useEffect(() => {
        if (storeIsLoaded) {
            store?.set(PARTIESLIKED_KEY, likedParties);
        }
    }, [likedParties]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(DRAGEXPOSLIKED_KEY, likedDragExpos);
        }
    }, [likedDragExpos]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(BARSANDCLUBSLIKED_KEY, likedBarsAndClubs);
        }
    }, [likedBarsAndClubs]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(PEOPLELIKED_KEY, likedPeople);
        }
    }, [likedPeople]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(CRUISESLIKED_KEY, likedCruises);
        }
    }, [likedCruises]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(NOTIFICATIONSVIEWED_KEY, viewedNotifications);
        }
    }, [viewedNotifications]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(DEFCITY_KEY, defCity);
        }
    }, [defCity]);

    useEffect(() => {
        if (storeIsLoaded && cityNotifsAreLoaded) {
        store?.set(CITIESNOTIFS_KEY, citiesNotifs);
        }
    }, [citiesNotifs]);

    useEffect(() => {
        if (storeIsLoaded) {
        store?.set(PROMOSVIEWED_KEY, viewedPromos);
        }
    }, [viewedPromos]);

  
    return {
        storeIsLoaded,
        firstLoad,
        imagesAreLoaded,
        checkIonicStorageForExistingImagesDetermineAction,
        checkIonicStorageForExistingImagesDetermineActionAlt,
        checkIonicStorageForExistingImagesDetermineActionAltAlt,
        storedImages,
        checkExistingNotifsAndDetermineAction,
        viewedNotifications,
        setViewedNotifications,
        viewedNotificationsAreLoaded,
        defCity,
        setDefCity,
        defCityIsLoaded,
        checkDefCity,
        citiesNotifs,
        setCitiesNotifs,
        viewedPromos,
        setViewedPromos,
        viewedPromosAreLoaded,
        likedParties,
        setLikedParties,
        likedDragExpos,
        setLikedDragExpos,
        likedBarsAndClubs,
        setLikedBarsAndClubs,
        likedPeople,
        setLikedPeople,
        likedCruises,
        setLikedCruises,
    }
}