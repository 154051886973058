import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';
// to work with typescript had to do npm install -D @types/mapbox-gl

// import { Share } from '@capacitor/share';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonModal,
    IonMenuButton,
    IonSegment,
    IonSegmentButton,
    IonLabel
} from '@ionic/react';

import { calendarOutline, timeOutline, mapOutline, shareOutline, heartSharp, heartOutline, locationSharp, pricetagOutline, pricetag, cashOutline, arrowForwardCircleOutline, arrowForwardOutline, alertCircle, playCircleOutline, arrowBack, callOutline, logoInstagram, globeOutline, chevronBack, chevronBackOutline, chevronForwardOutline, close } from 'ionicons/icons';

// import NewWindPP from './NewWindPP';
import NewWindPPSimp from './NewWindPPSimp';

import '../theme/windowstyle.css';
import '../theme/newWindow.css';

import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';



// import mapimage from '../images/buttonroundmap.png';

import testbtn from '../images/testbutn.png';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const NewWindP: React.FC<{
    selectedParty: any;
    setSelectedParty: any;
    selectedPartyInd: any;
    filteredPartiesLn:any;
    // parties: any[];
    handleNextP: any;
    handlePrevP: any;
    // gridNo: any;
    barsAndClubs: any[];
    // likedParties: any;
    // setLikedParties: any;
    // people: any;
    peopleOth: any;
    currentDateAndTime: any;
    // handleIncrement: any;
    // handleDecrement: any;
    handleIncrClick: any;
    // handleDecrClick: any;
    fromCal: any;
    handleCloseWind: any;

    

//below is whats missing for newWindPP
    // people: any;
    // parties: any;
    // partiesCirc: any;
    // dragExpos: any;
    // dragTours: any;
    // cruises: any;
    // citySelected: any;
    
    
}> = (props) => {

    const [imgOrMap, setImgOrMap] = useState<any>("img");
    //hmm in this component the above is being used for both the original party and the 'rep' BC when that opens

    const [isWindowMapOpenP, setIsWindowMapOpenP] = useState<any>(false);
    const [isWindowMapLoadedP, setIsWindowMapLoadedP] = useState<any>(false);
    // const [myMapP, setMyMapP] = useState<any>(null);
    //why dont i use myMapP? ive got rid of setting it for the moment.

    const [isRepBCOpen, setIsRepBCOpen] = useState(false);
    const [selectedBCRep, setSelectedBCRep] = useState<any>(null);
    

    const [isRepPPOpen, setIsRepPPOpen] = useState(false);
    const [selectedPPRep, setSelectedPPRep] = useState<any>(null);

    // const modalMapP= useRef<HTMLIonModalElement>(null);
    const mapContainerP = useRef<any>(null);

    const handleImgOrMap = (viewClicked: string) => {
        if (viewClicked === imgOrMap) return;
        if (viewClicked === "img") setImgOrMap("img");
        if (viewClicked === "map") setImgOrMap("map");
      };


      const handleOpenWindBCRep = (barclub:any) => {
        setSelectedBCRep(barclub);
        setImgOrMap("img");
        //i think the above only needs to be set since shared between party wind and rep BC wind. so if you're in map in party wind and you go to BC wind then it will show image first.
        setIsRepBCOpen(true);
      }

      const handleOpenWindPPRep = (person:any) => {
        setSelectedPPRep(person);
        
        setIsRepPPOpen(true);
      }

      //in CalViewParties i just made conditional on whether there WAS a selected____ thing to cover both of the above. if i did that here then id have to do same logic as with CalViewParties which is slightly more complex (extra button conditional plus moving isBCWIndowRep up one level to be able to set to null when modal closes. TMAAAAA CalViewParties is same level as Parties essentialy ie one before NewWindP)
      // no but key diff is that where you start in CalViewParties, this is second page (comparable to going to it through People window). if you go through Parties directly then its first page.
      //fromCalView can be thought of 'is this the second page?'. note i used it for instance in newWindPP component.
      // but i did do rep BC differently event though it is 3rd page. dont use that as the model.
      //other KEY difference with rep BC here is that im not using a newWindBC so to speak... i dont actually have one because that window was done differently. probably a good thing so i dont have to do upcoming bars and can have endpoint (no clicking on from there).
      //but make sure to make that point about 'see bars and clubs for upcoming events'



      const handleKeyDown = (e:any) => {
        if (isRepBCOpen || isRepPPOpen) {
            return;
        }
        console.log(e.key, 'key code');
        if (e.key === "ArrowLeft") {
            setImgOrMap("img");
            props.handlePrevP();
        }
        if (e.key === "ArrowRight") {
            console.log("arrow right key")
            setImgOrMap("img");
            props.handleNextP();
            
        }
        
      }

      const handleClickBack = (e:any) => {
        e.stopPropagation();
        setImgOrMap("img");
        props.handlePrevP();
      }

      const handleClickFwd = (e:any) => {
        e.stopPropagation();
        setImgOrMap("img");
        props.handleNextP();
      }




      const handleClBackWindBCRep = () => {
        setIsRepBCOpen(false);
        setSelectedBCRep(null);
        setImgOrMap("img");
        

      }

      const handleClBackWindPPRep = () => {
        setIsRepPPOpen(false);
        // setSelectedPPRep(null);
        //above not necessary since comp will be demounted
        setImgOrMap("img");
      }
      
    

    
    const handleClickLink = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedParty.website, '_system', 'location=yes');
        props.handleIncrClick("parties", props.selectedParty.hash);
    }

    const handleClickLinkPBC = (e:any) => {
        e.stopPropagation();
        window.open(selectedBCRep.website, '_system', 'location=yes');
        // props.handleIncrClick("party", selectedPartyRepPP.hash);
    }

    const handleClickLinkPInstBC = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${selectedBCRep.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }


    // const shareParty = async(titleparam:any, textparam:any, urlparam:any) => {
    //     await Share.share({
    //       title: titleparam,
    //       text: "Description",
    //       url: urlparam
    //       dialogTitle: 'Share with buddies',
    //     });
    //   }


    useEffect(() => {

        

        if (imgOrMap !== "map") {
            return
        }

        const mapObj = isRepBCOpen ? selectedBCRep : props.selectedParty;

        const mapP = new mapboxgl.Map({
            container: mapContainerP.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [mapObj.lng, mapObj.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([mapObj.lng, mapObj.lat]).addTo(mapP);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapP(mapP);

        return () => mapP.remove();

    },[imgOrMap]);

    const handleDismissWindowMapModalP = () => {
        // myMapDE.remove();
        // because the above doesnt work i dont actually have a need for state HOWEVER im leaving it because... (update: i commented it out)
        // ...i dont think the map is being demounted when i close modal and its creating new instance each time...
        // so its layering instances of maps, no? or maybe not since use of const variable can surely only hold one map..?
        setIsWindowMapLoadedP(false);
        setIsWindowMapOpenP(false);
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
            console.log('key down listener removed!!!');
        };
    }, [props.selectedPartyInd, props.filteredPartiesLn, isRepBCOpen, isRepPPOpen]);
//review dependencies


    return(
        <>
            
            
                {
                    !isRepBCOpen && !isRepPPOpen
                    &&
                    <div className="testwindowabs" >
                    

                        <div className="windowcontainerNWwitharr">

                                    <div className="clsabvarr"
                                            onClick={props.handleCloseWind}
                                            >
                                        {
                                            !props.fromCal
                                            &&
                                    <div className="abvarrcls"
                                    onClick={props.handleCloseWind}
                                    
                                    >
                                            <IonIcon icon={close} className="clsicon"></IonIcon>
                                    </div>
                                    }
                                    </div>
                            
                                <div className="arrowcontl"
                                onClick={props.handleCloseWind}
                                >


                                    {
                                        !props.fromCal
                                        &&
                                    <div className={props.selectedPartyInd === 0 ? "arrowcirc csrnot": "arrowcirc"}
                                    onClick={handleClickBack}
                                    // tabIndex={0}
                                    // onKeyDown={leftArrow}
                                    >
                                            <IonIcon icon={chevronBackOutline} className={props.selectedPartyInd === 0 ? "arricn opac3" : "arricn"}></IonIcon>
                                    </div>
                                    }

                                                {/* <div className="clsabvarrlg"
                                                        onClick={props.handleCloseWind}
                                                        >
                                                {
                                                    !props.fromCal
                                                    &&
                                                <div className="abvarrcls"
                                                onClick={props.handleCloseWind}
                                                
                                                >
                                                        <IonIcon icon={close} className="clsicon"></IonIcon>
                                                </div>
                                                }
                                                </div> */}
                                                {/* commented out above which was creating the circle with x to close party window. weirdly other components have the abvarrcls class and dont have the X so look into that. */}
                                </div>
                                <div className="contmiddle">
                                <div className="newwinimgcont">


                                            
                                                    {
                                                        imgOrMap==="img"
                                                        &&
                                                        props.selectedParty?.image_src
                                                        &&
                                                        <img className="windowimgboxNW" src={props.selectedParty?.image_src}
                                                        onError={(e: any) => e.target.src = props.selectedParty?.image_url}
                                                        ></img>
                                                    }
                                                    {
                                                        imgOrMap==="img"
                                                        &&
                                                        !props.selectedParty?.image_src
                                                        &&
                                                        <img className="windowimgboxNW" src={props.selectedParty?.image_url}
                                                        ></img>
                                                    }

                                                    {
                                                        imgOrMap==="map"
                                                        &&
                                                        // <div className="mapcontainer">
                                                            <div ref={mapContainerP} className="mapcontainer" />
                                                        // </div>
                                                    }


                                                    <div className="segmentconNW">
                                                        <div className={imgOrMap === "img" ? "segon" : "segoff"}
                                                        onClick={()=>handleImgOrMap("img")}
                                                        >IMAGE</div>
                                                        <div className={imgOrMap === "map" ? "segon" : "segoff"}
                                                        onClick={()=>handleImgOrMap("map")}
                                                        >MAP</div>
                                                    </div>

                                                    {
                                                    props.selectedParty?.daterealstart <= props.currentDateAndTime
                                                    &&
                                                    <div
                                                    className="livewindowboxweb">
                                                        <div className="blinkingtextlive">LIVE</div>
                                                    </div>
                                                    }

                                                    {
                                                        props.fromCal
                                                        &&
                                                        <div className="bckicncont"
                                                        onClick={()=> props.setSelectedParty(null)}
                                                        >
                                                            <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                        </div>
                                                    }
                                                    
                                                

                                </div>
                                
                                <div className="newwininfocont">
                                <IonContent className="contentpad">

                                
                                

                                
                                
                                        <div className="windowmainsetwidthNW">

                                        {
                                                props.selectedParty?.price !== 0 && props.selectedParty?.tickets_available
                                                &&
                                                <div className="dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightdd">
                                                                <div className="titlerightfromdd">from</div>
                                                                <div className="titlerightmargNW">{props.selectedParty?.currency_symbol}{props.selectedParty?.price}</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                </div>

                                        }
                                        
                                        {
                                                props.selectedParty?.price === 0 && props.selectedParty?.tickets_available
                                                &&
                                        <div className="dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightfreedd">
                                                                <div>FREE</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                            </div>
                                            }

                                                    {
                                                !props.selectedParty?.tickets_available
                                                &&
                                                <div className="dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightsoldoutdd">
                                                                <div className="">SOLD OUT</div>
                                                                {/* <div className="">OUT</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                </div>
                                                    }

                                                

                                            

                                            {
                                                (props.selectedParty?.price !== 999)
                                                &&
                                                <div className="windowtitleboxNW">

                                                

                                                <div className="windowtitleshortNW">
                                                    {props.selectedParty?.name}
                                                </div>
                                                


                                                {
                                                props.selectedParty?.price !== 0 && props.selectedParty?.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightNW">
                                                        <div className="titlerightfromNW">from</div>
                                                        <div className="titlerightmargNW">{props.selectedParty?.currency_symbol}{props.selectedParty?.price}</div>
                                                    </div>
                                                </div>
                                                }
                                            
                                                {
                                                props.selectedParty?.price === 0 && props.selectedParty?.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightfreeNW">
                                                        <div>FREE</div>
                                                    </div>
                                                </div>
                                                }

                                                
                                                {
                                                !props.selectedParty?.tickets_available
                                                &&
                                                <div className="titleboxright">
                                                    <div className="titlerightsoldoutNW">
                                                        <div className="">SOLD</div>
                                                        <div className="">OUT</div>
                                                    </div>
                                                </div>
                                                }
                                                </div>
                                            }   

                                            

                                            {
                                            (props.selectedParty?.price === 999)
                                            &&
                                            <div className="windowtitleboxNW">
                                                <div className="windowtitlefullNW">
                                                    {props.selectedParty?.name}
                                                </div>
                                            </div>
                                            }

                                            {/* {
                                                props.selectedParty?.daterealstart <= props.currentDateAndTime
                                                &&
                                                <div
                                                className="livewindowboxweb">
                                                    <div className="blinkingtextlivebg">LIVE</div>
                                                </div>
                                                } */}

                                            


                                            {
                                            !props.selectedParty?.tickets_available
                                            &&
                                            <div className="windowmainboxstd">
                                                <div className="windowmaintextboxstd soldoutbox">
                                                    Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                                </div>
                                            </div>
                                            }


                                            
                                            <div className="windowdatetimecont">
                                                <div className="datetimebox">
                                                    <IonIcon icon={calendarOutline} className="datetimeicon" />
                                                    <div className="datebox">
                                                        <div className="datetimetext">
                                                            {props.selectedParty?.start_day.slice(0,3)}{", "}{props.selectedParty?.start_date}{" "}{props.selectedParty?.start_month.slice(0,3)}
                                                            {/* <br/>
                                                            2-11pm */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="datetimebox">
                                                    <IonIcon icon={timeOutline} className="datetimeicon" />
                                                    <div className="timebox">
                                                        <div className="datetimetext">
                                                            {props.selectedParty?.start_time}{" "}to{" "}{props.selectedParty?.finish_time}
                                                        </div>
                                                    </div>
                                                </div>                  
                                            </div>


                                            {/* <img className="testbtn" src={testbtn} alt="" /> */}
                                            
                                            
                                            
                                            {
                                            (props.selectedParty?.description !== "" || props.selectedParty?.extra_info !== "")
                                            &&
                                            <div className="windowmainboxstd">
                                                <div className="windowmainheadingstd">
                                                    About
                                                </div>   
                                                <div className="windowmaintextboxstd">
                                                    {props.selectedParty?.description}
                                                </div>
                                                    {
                                                props.selectedParty?.extra_info !== ""
                                                &&
                                                <div className="windowmaintextboxext">
                                                    <div className="exclambox">
                                                        <IonIcon icon={alertCircle} className="exclam">
                                                        </IonIcon>
                                                    </div>
                                                    <div className="exclamtext">{props.selectedParty?.extra_info}</div>
                                                </div>
                                                }
                                            </div>
                                            }

                                            

                                            {/* <div className="partywindowdescriptionbox">
                                                <div className="partywindowheading">
                                                    Prices
                                                </div>   
                                                <div className="partywindowdescriptionpricescontainer">
                                                    <div className="partywindowpricebox">
                                                        <div className="ticketinfotextalt">{selectedParty.price_type}</div>
                                                        <div className="ticketinfopricealt">{selectedParty.currency_symbol}{selectedParty.price}</div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            

                                            {/* <div className="windowmainboxstd"> */}
                                                
                                                {
                                                props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue).length > 0
                                                &&
                                                !props.selectedParty.country
                                                &&
                                                <div className="windowmainheadingstd">
                                                    Venue
                                                </div>
                                                }
                                            {/* </div> */}
                                        </div>
                                            {/* end of set width main box */}
                                                {
                                                props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue).length > 0
                                                &&
                                                <div className="windowgrid"
                                                // style={{gridTemplateColumns: `repeat(${Math.max(Math.min(props.gridNo-1, 3),2)}, calc(100% / ${Math.max(Math.min(props.gridNo-1, 3),2)}))`}}
                                                >
                                                    <div className="gridviewcardstd"
                                                    onClick={() => handleOpenWindBCRep(props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0])}
                                                    >
                                                        {/* <Link to={`/barsandclubs/${props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].id}`} style={{ display: 'contents' }}> */}
                                                            {/* maybe i should do abovoe calc in body in formula. and wrap in use memo. same as below. ie calc would identify that matched venue */}
                                                            {
                                                            (props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].image_src)
                                                            &&
                                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].image_src} alt="" 
                                                            onError={(e: any) => e.target.src = props.selectedParty?.image_url}
                                                            />
                                                            }
                                                            {
                                                            !(props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].image_src)
                                                            &&
                                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].image_url} alt="" 
                                                            />
                                                            }
                                                            <div className="gridviewcardimagecover">
                                                                {/* <div className="gridviewsuburbbubble">
                                                                    {props.barsAndClubs.filter(ele => ele.name === selectedParty?.venue)[0].suburb}
                                                                </div> */}
                                                                <div className="gridviewbottomcontainermod">               
                                                                    <div className="gridviewtitlecontainerfull">
                                                                    <div className="gridviewtitlefull">{props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue)[0].name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        {/* </Link>      */}
                                                    </div>
                                                </div>
                                                }
                                                <div className="windowmainsetwidthNW">
                                                
                                                {
                                                props.barsAndClubs.filter(ele => ele.name === props.selectedParty?.venue).length === 0
                                                &&
                                                !props.selectedParty.country
                                                // im using the country thing to distinguish circuit festivals which have that param
                                                &&
                                                <div className="windowmainboxstd">
                                                    <div className="windowmainheadingstd">
                                                        Address
                                                    </div>
                                                    
                                                
                                                    <div className="windowmainvenuecardbox">
                                                        <div className="windowvenueleft">      
                                                            {
                                                            props.selectedParty?.venue !== ""
                                                            &&
                                                            <div>
                                                                <span className="addresshasvenuename">{props.selectedParty?.venue}</span>
                                                            </div>
                                                            }
                                                            {props.selectedParty?.suite_number === "" ? "" : <div>{props.selectedParty?.suite_number}</div>}
                                                            <div className="addressfirstrowflexbox">
                                                                {props.selectedParty?.street_number === "" ? <div>{props.selectedParty?.street_name}</div> : <div className="limitaddresstooneline">{props.selectedParty?.street_number}{" "}{props.selectedParty?.street_name}</div>}
                                                            </div>
                                                            <div>{props.selectedParty?.suburb} {props.selectedParty?.postcode}</div>       
                                                        </div>
                                                        {/* <div className="windowvenueright">  
                                                            <img
                                                            src={mapimage}
                                                            alt=""
                                                            className="windowvenueimg" 
                                                            onClick={() => setIsWindowMapOpenP(true)}
                                                            />
                                                        </div> */}
                                                    </div>
                                                </div>
                                                }    
                                               
                                            {
                                            props.selectedParty?.entertainers !== ""
                                            &&
                                            props.peopleOth.filter((person: any) => props.selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                            &&
                                            <div className="windowmainheadingstd">
                                                Featured entertainers
                                            </div>
                                            }

                                            {/* end of set width main box */}
                                            </div>

                                        {/* can i move the below calcs up or some of them and memoize? */}

                                        {
                                        props.selectedParty?.entertainers !== ""
                                        &&
                                        props.peopleOth.filter((person: any) => props.selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                        &&
                                        <div className="windowgrid"
                                        // style={{gridTemplateColumns: `repeat(${Math.max(Math.min(props.gridNo-1, 3),2)}, calc(100% / ${Math.max(Math.min(props.gridNo-1, 3),2)}))`}}
                                        >
                                            {props.peopleOth.filter((person: any) => props.selectedParty?.entertainers.some((entertainer: any) => entertainer === person.name))
                                            .map((person: any, index: any) => {
                                                return <div key={index} className="gridviewcardstd"
                                                onClick={() => handleOpenWindPPRep(person)}
                                                >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}
                                                                    />
                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt="" />
                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                    {/* {
                                                                    person.type[0] === "Drag queen"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedrag ">Drag</div>
                                                                    }      
                                                                    {
                                                                    person.type[0] === "DJ"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedj">DJ</div>
                                                                    }         
                                                                    {
                                                                    person.type[0] === "Singer"
                                                                    &&
                                                                    <div className="gridviewtypebubble typesing">Singer</div>
                                                                    }          
                                                                    {
                                                                    person.type[0] === "Other"
                                                                    &&
                                                                    <div className="gridviewtypebubble typeoth">Other</div>
                                                                    } */}
                                                                        <div className="gridviewtopcont">
                                                                            {
                                                                                person.type.map((typeparam:any, index:number) => {
                                                                                    return <div key={index} className="gridviewpricebubbletype"> 

                                                                                    {
                                                                                    typeparam === "Drag queen"
                                                                                    &&
                                                                                    <span className="tyre">DRAG</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "DJ"
                                                                                    &&
                                                                                    <span className="tybl">DJ</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Other"
                                                                                    &&
                                                                                    <span className="tyor">OTHER</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Singer"
                                                                                    &&
                                                                                    <span className="tygr">SINGER</span>
                                                                                    }

                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {/* {
                                                                            person.type[0] === "Drag queen"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyre"> 
                                                                                    DRAG
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "DJ"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tybl"> 
                                                                                    DJ
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Singer"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tygr"> 
                                                                                    SINGER
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Other"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyor"> 
                                                                                    OTHER
                                                                            </div>
                                                                            } */}
                                                                            </div>
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainer">               
                                                            
                                                                            <div className="gridviewtitlecontainer">
                                                                            {
                                                                                person.name_short === ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name}
                                                                                </div>
                                                                                }
                                                                                {
                                                                                person.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name_short}
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            })}
                                        </div>
                                        }

                                        {/* {
                                        selectedParty.entertainers !== ""
                                        &&
                                        props.people.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                        &&
                                        <div className="swipercontainerstd gvgaddmarginbot">
                                            <Swiper
                                            slidesPerView={3.1}
                                            loop={false}
                                            modules={[IonicSlides]}
                                            slidesOffsetBefore={30}
                                            slidesOffsetAfter={30}
                                            >
                                            {props.people.filter((person: any) => selectedParty.entertainers.some((entertainer: any) => entertainer === person.name))
                                            .map((person: any, index: any) => {
                                                return <SwiperSlide
                                                key={index}
                                                >
                                                    <div className="swipercard">
                                                        <Link to={`/people/${person.id}`} style={{ display: 'contents' }}>

                                                            <img className="gridviewcardimage" src={person.image_src} alt="" />                                          
                                                            <div className="gridviewcardimagecover">
                                                                <div className="gridviewbottomcontainer">
                                                                    <div className="gridviewtitlecontainerfull">
                                                                        <div className="gridviewtitlefull">{person.name}</div>
                                                                    </div>
                                                                    {
                                                                    person.type[0] === "Drag queen"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedrag ">Drag</div>
                                                                    }      
                                                                    {
                                                                    person.type[0] === "DJ"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedj">DJ</div>
                                                                    }         
                                                                    {
                                                                    person.type[0] === "Singer"
                                                                    &&
                                                                    <div className="gridviewtypebubble typesing">Singer</div>
                                                                    }          
                                                                    {
                                                                    person.type[0] === "Other"
                                                                    &&
                                                                    <div className="gridviewtypebubble typeoth">Other</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Link>            
                                                    </div>
                                                </SwiperSlide>
                                                })}
                                            </Swiper>
                                        </div>
                                        } */}

                                        <div className="windowmainsetwidthNW">
                                            <div className="windowdatetimecont">
                                                {/* using the above because same properties and values */}
                                                {
                                                props.selectedParty?.organiser !== ""
                                                &&
                                                <div
                                                style={props.selectedParty?.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                                >
                                                    <div className="windowmainheadingstd">
                                                        Organiser
                                                    </div>
                                                    <div className="windowmaintextboxstd extrapadorg">
                                                        {props.selectedParty?.organiser}
                                                    </div>
                                                </div>
                                                }
                                                {
                                                props.selectedParty?.ticket_provider !== ""
                                                &&
                                                <div
                                                style={props.selectedParty?.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                                >
                                                    <div className="windowmainheadingstd">
                                                        Ticket vendor
                                                    </div>
                                                    <div className="windowmaintextboxstd">
                                                        {props.selectedParty?.ticket_provider}
                                                    </div>
                                                </div>
                                                }
                                            </div>


                                            <div className="floatingbuttoncont">
                                                {
                                                (props.selectedParty?.price !== 0 && props.selectedParty?.tickets_available && props.selectedParty?.price !== 999 && props.selectedParty.price_type !== "more" && !props.selectedParty?.global)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                ((props.selectedParty?.price === 0 || !props.selectedParty?.tickets_available || props.selectedParty?.price === 999 || props.selectedParty?.price_type === "more") && !props.selectedParty?.global)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                {
                                                props.selectedParty?.global
                                                &&
                                                    <div className="mainwindowbtn"
                                                    onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskewsm">
                                                            MAKE A RESERVATION
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="spaceforbtn"></div>
                                        
                                    </IonContent>
                                    </div>
                                </div>
                                    <div className="arrowcontr"
                                    onClick={props.handleCloseWind}
                                    >
                                    {
                                        !props.fromCal
                                        &&
                                    <div
                                    className={props.selectedPartyInd === (props.filteredPartiesLn - 1) ? "arrowcirc csrnot": "arrowcirc"}
                                    onClick={handleClickFwd}
                                    >
                                            <IonIcon icon={chevronForwardOutline} className={props.selectedPartyInd === (props.filteredPartiesLn - 1) ? "arricn opac3" : "arricn"}></IonIcon>
                                    </div>
                                        }
                                </div>

                        </div>

                        <div className="windbotcont" onClick={props.handleCloseWind}>

                            {
                                            (props.selectedParty?.price !== 0 && props.selectedParty?.tickets_available && props.selectedParty?.price !== 999 && props.selectedParty?.price_type !== "more" && !props.selectedParty?.global)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLink}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            ((props.selectedParty?.price === 0 || !props.selectedParty?.tickets_available || props.selectedParty?.price === 999 || props.selectedParty?.price_type === "more") && !props.selectedParty?.global)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLink}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            {
                                            props.selectedParty?.global
                                            &&
                                            <div className="floatingbuttoncontweb">

                                            

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLink}
                                                >
                                                    <div className="floatbtnskewsm">
                                                        MAKE A RESERVATION
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }
                        </div>                      


                    </div>
                    }

                    
                    {
                        isRepPPOpen
                        &&
                        <NewWindPPSimp 
                        selectedPerson={selectedPPRep}
                        // people={props.people}
                        // gridNo={props.gridNo}
                        handleClBackWindPPRep={handleClBackWindPPRep}
                        handleCloseWind={props.handleCloseWind}
                        
                        />
                    }



                    {
                        isRepBCOpen
                        &&
                        <div className="testwindowabs"
                        onClick={props.handleCloseWind}
                        >
                        <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                        {/* <div className="arrowtest"></div> */}
                        <div className="newwinimgcont">


                                    
                                            {
                                                imgOrMap==="img"
                                                &&
                                                selectedBCRep.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRep.image_src}
                                                onError={(e: any) => e.target.src = selectedBCRep.image_url}
                                                ></img>
                                            }
                                            {
                                                imgOrMap==="img"
                                                &&
                                                !selectedBCRep.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRep.image_url}
                                                ></img>
                                            }

                                            {
                                                imgOrMap==="map"
                                                &&
                                                // <div className="mapcontainer">
                                                    <div ref={mapContainerP} className="mapcontainer" />
                                                    //can i reuse same map container? do i need to do something in useeffect?
                                                // </div>
                                            }


                                            <div className="segmentconNW">
                                                <div className={imgOrMap === "img" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMap("img")}
                                                >IMAGE</div>
                                                <div className={imgOrMap === "map" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMap("map")}
                                                >MAP</div>
                                            </div>

                                            
                                                <div className="bckicncont"
                                                onClick={handleClBackWindBCRep}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>
                                            
                                            
                                        

                        </div>
                    
                    <div className="newwininfocont">
                    <IonContent className="contentpad">

                    
                    

                    
                    
                            <div className="windowmainsetwidthNW">


                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {selectedBCRep.name}
                                    </div>
                                </div>

                                <div className="windowmainboxstd">
                                <div className="windowmaintextboxstd seeothersecbox">
                                    For a list of {selectedBCRep.name}{"'"}s upcoming events, please refer to the {"'"}Bars & Clubs{"'"} section of the website.
                                </div>
                            </div>
                                


                                
                                
                                {
                                (selectedBCRep.description !== "" || selectedBCRep.extra_info !== "")
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRep.description}
                                    </div>
                                        {
                                    selectedBCRep.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedBCRep.extra_info}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {
                                selectedBCRep.price_info !== ""
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Cover
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRep.price_info}
                                    </div>
                                </div>
                                }
                                
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Address
                                    </div>
                                    <div className="windowmainvenuecardbox">
                                        <div className="windowvenueleft">
                                        {selectedBCRep.suite_number === "" ? "" : <div>{selectedBCRep.suite_number}</div>}
                                            <div className="addressfirstrowflexbox">
                                                
                                                {selectedBCRep.street_number === "" ? <div>{selectedBCRep.street_name}</div> : <div className="limitaddresstooneline">{selectedBCRep.street_number}{" "}{selectedBCRep.street_name}</div>}
                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                            </div>
                                            <div>{selectedBCRep.suburb} {selectedBCRep.postcode}</div>
                                        </div>
                                        {/* <div className="windowvenueright">
                                            <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenBC(true)}>
                                                View map
                                            </div>
                                        </div> */}
                                    </div>
                                </div>



                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                    Opening hours
                                    </div>
                                    <div className="openinghoursbox">
                                    <div className="timetablebox">
                                        <div className="">
                                            <div className="weekday">
                                                Monday
                                            </div>
                                            <div className="weekday">
                                                Tuesday
                                            </div>
                                            <div className="weekday">
                                                Wednesday
                                            </div>
                                            <div className="weekday">
                                                Thursday
                                            </div>
                                            <div className="weekday">
                                                Friday
                                            </div>
                                            <div className="weekday">
                                                Saturday
                                            </div>
                                            <div className="weekday">
                                                Sunday
                                            </div>
                                        </div>
                                        <div className="BCtimes">
                                            {
                                            selectedBCRep.monday_open !== ""
                                            &&
                                            !(selectedBCRep.monday_open === "12am" && selectedBCRep.monday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.monday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.monday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.monday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.monday_open === "12am" && selectedBCRep.monday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.tuesday_open !== ""
                                            &&
                                            !(selectedBCRep.tuesday_open === "12am" && selectedBCRep.tuesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.tuesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.tuesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.tuesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.tuesday_open === "12am" && selectedBCRep.tuesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.wednesday_open !== ""
                                            &&
                                            !(selectedBCRep.wednesday_open === "12am" && selectedBCRep.wednesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.wednesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.wednesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.wednesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.wednesday_open === "12am" && selectedBCRep.wednesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.thursday_open !== ""
                                            &&
                                            !(selectedBCRep.thursday_open === "12am" && selectedBCRep.thursday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.thursday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.thursday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.thursday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.thursday_open === "12am" && selectedBCRep.thursday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.friday_open !== ""
                                            &&
                                            !(selectedBCRep.friday_open === "12am" && selectedBCRep.friday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.friday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.friday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.friday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.friday_open === "12am" && selectedBCRep.friday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.saturday_open !== ""
                                            &&
                                            !(selectedBCRep.saturday_open === "12am" && selectedBCRep.saturday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.saturday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.saturday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.saturday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.saturday_open === "12am" && selectedBCRep.saturday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRep.sunday_open !== ""
                                            &&
                                            !(selectedBCRep.sunday_open === "12am" && selectedBCRep.sunday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.sunday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRep.sunday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRep.sunday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRep.sunday_open === "12am" && selectedBCRep.sunday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>




                              

                                

                                
                       

                            
                                

                               
                                
                            


                            <div className="floatingbuttoncont">
                        {/* {
                            selectedBarClub.facebook
                            &&
                            <div className="socialmediabtn fbbtn"
                            onClick={()=>window.open(selectedBarClub?.facebook, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoFacebook} className="sharebtniconlg" />
                            </div>
                        </div>
                        } */}
                        
                            {/* <div className="sharebuttonsm">
                                <div className="floatbtnskew">
                                    <IonIcon icon={shareOutline} color="" className="sharebtniconlg" />
                                </div>
                            </div> */}
                        

                        {
                        selectedBCRep.website !== ""
                        &&
                        <div className="socialmediabtn"
                        onClick={handleClickLinkPBC}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRep.website === ""
                        &&
                        <div className="socialmediabtn csrnot"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={handleClickLinkPInstBC}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                        

                        

                    </div>

                    </div>

                    <div className="spaceforbtn"></div>
                    
                    </IonContent>
                    </div>

                    
                </div>

                <div className="windbotcont" onClick={props.handleCloseWind}>
                    <div className="floatingbuttoncontweb">
                {
                        selectedBCRep.website !== ""
                        &&
                        <div className="socialmediabtnwebBC"
                        onClick={handleClickLinkPBC}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRep.website === ""
                        &&
                        <div className="socialmediabtnwebBC csrnot"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        onClick={(e) => e.stopPropagation()}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={handleClickLinkPInstBC}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}
                            
                                            
                                           
                    </div>
                                    
                </div>
            </div>
            }
                
        </>

    )
}

export default NewWindP;