// later for each of parties, drag expos, and cruises and tours.. you are going to need to have logic where it's one or two...

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';


import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonMenuButton
} from '@ionic/react';

import {
    globeOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    tvOutline,
    logoYoutube,
    logoSoundcloud,
    alertCircle,
    logoTiktok,
    arrowBack,
    calendarOutline,
    timeOutline,
    arrowForwardOutline,
    callOutline
} from 'ionicons/icons';

import NewWindP from './NewWindP';


import '../theme/windowstyle.css';
import '../theme/newWindow.css';

import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';



const NewWindPPSimp: React.FC<{
    selectedPerson: any;
    // people: any[];
    // gridNo: any;
    handleClBackWindPPRep: any;
    handleCloseWind:any;
}> = (props) => {
//handleCloseWind is to close party window.. which should unmount this comp

      const handleClickLinkPP = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedPerson.website, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

   

    const handleClickLinkPPSC = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.soundcloud.com/${props.selectedPerson?.soundcloud}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

    const handleClickLinkPPInst = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${props.selectedPerson?.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }


    const handleClickLinkPPTT = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.tiktok.com/@${props.selectedPerson?.spotify}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }




    return(
        
        <div className="testwindowabs" onClick={props.handleCloseWind}>
            <div className="windowcontainerNW" onClick={e => e.stopPropagation()}>
                <div className="newwinimgcont">


                                                
                {
                    
                    props.selectedPerson?.image_src
                    &&
                    <img className="windowimgboxNW" src={props.selectedPerson?.image_src}
                    onError={(e: any) => e.target.src = props.selectedPerson?.image_url}
                    ></img>
                }
                {
                    
                    !props.selectedPerson?.image_src
                    &&
                    <img className="windowimgboxNW" src={props.selectedPerson?.image_url}
                    ></img>
                }


                                {/* {
                                    isRepPartyOpenPP
                                    &&
                                    <div className="segmentconrepP"
                                            onClick={handleClBackWindPartyRepPP}
                                            >
                                                <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                </div>
                                } */}
                                        {
                                            props.handleClBackWindPPRep
                                            &&
                                                <div className="bckicncont"
                                                onClick={()=>props.handleClBackWindPPRep()}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>
                                        }
                                            

                


                



            </div>
            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedPerson?.name}
                                    </div>
                                </div>

                               
                            <div className="windowmainboxstd">
                                <div className="windowmaintextboxstd seeothersecbox">
                                    For a full list of {props.selectedPerson.name}{"'"}s upcoming performances, please refer to the {"'"}LGBT+ Entertainers{"'"} section of the website.
                                </div>
                            </div>
                                            

                            {
                            (props.selectedPerson.description !== "" || props.selectedPerson.extra_info !== "")
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {props.selectedPerson?.description}
                                    </div>
                                    {
                                    props.selectedPerson.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{props.selectedPerson.extra_info}</div>
                                    </div>
                                    }
                            </div>
                            }

                            {/* could put tv shows at bottom of about section */}


                        {
                            props.selectedPerson?.tv_shows.length > 0
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Credits
                                    </div>  
                                    {
                                        props.selectedPerson?.tv_shows.map((ele: any, index: number) => {
                                            return <div key={index} className="tvshowlistcont">
                                                        <div className="tvshowlistitem">
                                                            <IonIcon icon={tvOutline} size="small" color="primary" />
                                                        </div>
                                                        <div className="tvshowlistitem">{ele}</div>
                                                </div>
                                        })
                                    }
                            </div>
                            }

                            <div className="floatingbuttoncont">
                        

                        

                            {
                                props.selectedPerson?.soundcloud !== ""
                                &&
                                <div className="socialmediabtn"
                                onClick={handleClickLinkPPSC}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                props.selectedPerson?.soundcloud === ""
                                &&
                                <div className="socialmediabtn"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                >
                                <div className="floatbtnskewsocialmed csrnot">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx" />
                                </div>
                            </div>
                            }

                        

                        {
                            props.selectedPerson?.website !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={handleClickLinkPP}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson?.website === ""
                            &&
                            <div className="socialmediabtn"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninsta"
                        onClick={handleClickLinkPPInst}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        
                        {
                            props.selectedPerson?.spotify !== ""
                            &&
                            <div className="socialmediabtn"
                            onClick={handleClickLinkPPTT}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson?.spotify === ""
                            &&
                            <div className="socialmediabtn"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                            
                            
                        
         
                        </div>

                        <div className="spaceforbtn"></div>

                        

                            
                    
                           
                            </div> 

                        

                    </IonContent>
                    </div>

                

                    
                </div>

                <div className="windbotcont" onClick={props.handleCloseWind}>
                    <div className="floatingbuttoncontweb">

                {
                                props.selectedPerson.soundcloud !== ""
                                &&
                                <div className="socialmediabtnwebBC"
                                onClick={handleClickLinkPPSC}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                props.selectedPerson.soundcloud === ""
                                &&
                                <div className="socialmediabtnwebBC csrnot"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                onClick={(e) => e.stopPropagation()}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx" />
                                </div>
                            </div>
                            }

                        

                        {
                            props.selectedPerson.website !== ""
                            &&
                            <div className="socialmediabtnwebBC"
                            onClick={handleClickLinkPP}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson.website === ""
                            &&
                            <div className="socialmediabtnwebBC csrnot"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninstawebBC"
                        onClick={handleClickLinkPPInst}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        {/* spotify now equals tiktok */}
                        {
                            props.selectedPerson.spotify !== ""
                            &&
                            <div className="socialmediabtnwebBC"
                            onClick={handleClickLinkPPTT}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            props.selectedPerson.spotify === ""
                            &&
                            <div className="socialmediabtnwebBC csrnot"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx" />
                            </div>
                        </div>
                        }
                        </div>
                    </div>
            </div>
            
            
    )
}

export default NewWindPPSimp;