// later for each of parties, drag expos, and cruises and tours.. you are going to need to have logic where it's one or two...

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonMenuButton
} from '@ionic/react';

import {
    globeOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    tvOutline,
    logoYoutube,
    logoSoundcloud,
    alertCircle,
    logoTiktok,
    calendarOutline,
    timeOutline,
    arrowForwardOutline,
    arrowBack,
    close
} from 'ionicons/icons';

import NewWindPPSimp from './NewWindPPSimp';

import '../theme/windowstyle.css';
import '../theme/newWindow.css';

import '../theme/gridview.css';
import '../theme/maprelated.css';
// import '../theme/swipers.css';

import 'mapbox-gl/dist/mapbox-gl.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const NewWindDT: React.FC<{
    selectedDragExpo: any;
    barsAndClubs: any[];
    tvShows: any;
    people: any[];
    peopleOth: any;
    currentDateAndTime: any;
    gridNo: any;
    handleIncrClick: any;
    handleCloseWindDT: any;
}> = (props) => {


    const [imgOrMapDT, setImgOrMapDT] = useState<any>("img");

    const [isRepBCOpenDT, setIsRepBCOpenDT] = useState(false);
    const [selectedBCRepDT, setSelectedBCRepDT] = useState<any>(null);

    const [isRepPPOpenDT, setIsRepPPOpenDT] = useState(false);
    const [selectedPPRepDT, setSelectedPPRepDT] = useState<any>(null);

    const modalMapDT= useRef<HTMLIonModalElement>(null);
    const mapContainerDT = useRef<any>(null);

    const handleImgOrMapDT = (viewClicked: string) => {
        if (viewClicked === imgOrMapDT) return;
        if (viewClicked === "img") setImgOrMapDT("img");
        if (viewClicked === "map") setImgOrMapDT("map");
      };

      const handleOpenWindPPRepDT = (person:any) => {
        setSelectedPPRepDT(person);
        
        setIsRepPPOpenDT(true);
      }
    
      const handleOpenWindBCRepDT = (barclub:any) => {
        setSelectedBCRepDT(barclub);
        setImgOrMapDT("img");
        //i think the above only needs to be set since shared between party wind and rep BC wind. so if you're in map in party wind and you go to BC wind then it will show image first.
        setIsRepBCOpenDT(true);
      }
      

      const handleClBackWindBCRepDT = () => {
        setIsRepBCOpenDT(false);
        setSelectedBCRepDT(null);
        setImgOrMapDT("img");
      }

      const handleClBackWindPPRepDT = () => {
        setIsRepPPOpenDT(false);
        // setSelectedPPRepDT(null);
        //above not necessary since comp will be demounted
        setImgOrMapDT("img");
      }
      

    const handleClickLinkDT = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedDragExpo.website, '_system', 'location=yes');
        props.handleIncrClick("dragtours", props.selectedDragExpo.hash);
    }

    const handleClickLinkDTBC = (e:any) => {
        e.stopPropagation();
        window.open(selectedBCRepDT.website, '_system', 'location=yes');
        // props.handleIncrClick("party", selectedPartyRepPP.hash);
    }

    const handleClickLinkDTInstBC = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${selectedBCRepDT.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }

    

    useEffect(() => {

        // if (!mapContainerDE.current) {
        //     return
        // }

        if (imgOrMapDT !== "map") {
            return
        }

        const mapObj = isRepBCOpenDT ? selectedBCRepDT : props.selectedDragExpo;

        const mapDE = new mapboxgl.Map({
            container: mapContainerDT.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [mapObj.lng, mapObj.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([mapObj.lng, mapObj.lat]).addTo(mapDE);

        // map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // setMyMapDE(mapDE);

        return () => mapDE.remove();

    },[imgOrMapDT]);

    return(

        <>
        {
            !isRepBCOpenDT && !isRepPPOpenDT
            &&
            <div className="testwindowabs" onClick={props.handleCloseWindDT}>
        
                <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                                                                    <div className="clsabvarr">
                                                                        <div className="abvarrcls"
                                                                                onClick={props.handleCloseWindDT}
                                                                                
                                                                                >
                                                                                        <IonIcon icon={close} className="clsicon"></IonIcon>
                                                                                </div>
                                                                </div>




                    <div className="newwinimgcont">


                                                
                
                                        {
                                            imgOrMapDT==="img"
                                            &&
                                            props.selectedDragExpo?.image_src
                                            &&
                                            <img className="windowimgboxNW" src={props.selectedDragExpo?.image_src}
                                            onError={(e: any) => e.target.src = props.selectedDragExpo?.image_url}
                                            ></img>
                                        }
                                        {
                                            imgOrMapDT==="img"
                                            &&
                                            !props.selectedDragExpo?.image_src
                                            &&
                                            <img className="windowimgboxNW" src={props.selectedDragExpo?.image_url}
                                            ></img>
                                        }

                                        {
                                            imgOrMapDT==="map"
                                            &&
                                            // <div className="mapcontainer">
                                                <div ref={mapContainerDT} className="mapcontainer" />
                                            // </div>
                                        }


                                        <div className="segmentconNW">
                                            <div className={imgOrMapDT === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapDT("img")}
                                            >IMAGE</div>
                                            <div className={imgOrMapDT === "map" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrMapDT("map")}
                                            >MAP</div>
                                        </div>
                


                



            </div>

            <div className="newwininfocont">
            <IonContent className="contentpad">

                
                        
                        <div className="windowmainsetwidthNW">

                        {
                                                props.selectedDragExpo?.price !== 0 && props.selectedDragExpo?.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightdd">
                                                                <div className="titlerightfromdd">from</div>
                                                                <div className="titlerightmargNW">{props.selectedDragExpo?.currency_symbol}{props.selectedDragExpo?.starting_price}</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                </div>

                                        }
                                        
                                        {
                                                props.selectedDragExpo?.price === 0 && props.selectedDragExpo?.tickets_available
                                                &&
                                        <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightfreedd">
                                                                <div>FREE</div>
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelinebdd"></div>
                                                        </div>
                                            </div>
                                            }

                                                    {
                                                !props.selectedDragExpo?.tickets_available
                                                &&
                                                <div className="windowmainheadingstd dropdownbox">
                                                        <div className="livelinesdd">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                        <div className="titleboxrightdd">
                                                            <div className="titlerightsoldoutdd">
                                                                <div className="">SOLD OUT</div>
                                                                {/* <div className="">OUT</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="livelines">
                                                            <div className="livelinet"></div>
                                                            <div className="livelineb"></div>
                                                        </div>
                                                </div>
                                                    }

                        

                                
                                    <div className="windowtitleboxNW">

                                    {/* {
                                    props.selectedDragExpo?.daterealstart <= props.currentDateAndTime
                                    &&
                                    <div
                                    className="livewindowbox">
                                        <div className="blinkingtextlivebg">LIVE</div>
                                    </div>
                                    } */}
                                    {/* not currently doing live for drag tours/expos */}

                                    <div className="windowtitleshortNW">
                                        {props.selectedDragExpo?.name}
                                    </div>
                                    
                                   
                                
                                    {/* {
                                    props.selectedDragExpo?.price === 0 && props.selectedDragExpo?.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightfreeNW">
                                            <div>FREE</div>
                                        </div>
                                    </div>
                                    } */}

                                    {
                                    props.selectedDragExpo?.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightNW">
                                            <div className="titlerightfromNW">from</div>
                                            <div className="titlerightmargNW">{props.selectedDragExpo?.currency_symbol}{props.selectedDragExpo?.starting_price}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    !props.selectedDragExpo?.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightsoldoutNW">
                                            <div className="">SOLD</div>
                                            <div className="">OUT</div>
                                        </div>
                                    </div>
                                    }
                                    </div>
                                

                                {/* {
                                props.selectedDragExpo?.price === 999
                                &&
                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {props.selectedDragExpo?.name}
                                    </div>
                                </div>
                                } */}

{
                                !props.selectedDragExpo?.tickets_available
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmaintextboxstd soldoutbox">
                                        Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                                    </div>
                                </div>
                                }


                             
                                <div className="windowdatetimecont">
                                    <div className="datetimebox">
                                        <IonIcon icon={calendarOutline} className="datetimeicon" />
                                        <div className="datebox">
                                            <div className="datetimetext">
                                                {props.selectedDragExpo?.start_day.slice(0,3)}{", "}{props.selectedDragExpo?.start_date}{" "}{props.selectedDragExpo?.start_month.slice(0,3)}{" '"}{props.selectedDragExpo.start_year.slice(-2)}
                                                {/* <br/>
                                                2-11pm */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="datetimebox">
                                        <IonIcon icon={timeOutline} className="datetimeicon" />
                                        <div className="timebox">
                                            <div className="datetimetext">
                                                {props.selectedDragExpo?.start_time}{" "}to{" "}{props.selectedDragExpo?.finish_time}
                                            </div>
                                        </div>
                                    </div>                  
                                </div>

                                {/* <div className="windowmainboxstd"> */}
                                            <div className="windowmainheadingstd">
                                                Performers
                                                {/* make above conditional on length in terms of 's' */}
                                            </div>

                                </div>
                                            <div className="windowgrid"
                                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 4}, calc(100% / ${props.gridNo - 4}))`}}
                                            >
                                                {
                                                    // put logic in useeffect
                                                    props.peopleOth.filter((person: any) => props.selectedDragExpo.entertainers?.some((entertainer: any) => entertainer === person.name))
                                                    .map((person: any, index: any) => {
                                                        return <div key={index} className="gridviewcardstd"
                                                        onClick={()=>handleOpenWindPPRepDT(person)}
                                                        >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}

                                                                    />

                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt=""
                                                                    />

                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                {/* {
                                                                person.type[0] === "Drag queen"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedrag ">Drag</div>
                                                                }      
                                                                {
                                                                person.type[0] === "DJ"
                                                                &&
                                                                <div className="gridviewtypebubbletour typedj">DJ</div>
                                                                }         
                                                                {
                                                                person.type[0] === "Singer"
                                                                &&
                                                                <div className="gridviewtypebubbletour typesing">Singer</div>
                                                                }          
                                                                {
                                                                person.type[0] === "Other"
                                                                &&
                                                                <div className="gridviewtypebubbletour typeoth">Other</div>
                                                                } */}

                                                                            <div className="gridviewtopcont">
                                                                            {
                                                                                person.type.map((typeparam:any, index:number) => {
                                                                                    return <div key={index} className="gridviewpricebubbletype"> 

                                                                                    {
                                                                                    typeparam === "Drag queen"
                                                                                    &&
                                                                                    <span className="tyre">DRAG</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "DJ"
                                                                                    &&
                                                                                    <span className="tybl">DJ</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Other"
                                                                                    &&
                                                                                    <span className="tyor">OTHER</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Singer"
                                                                                    &&
                                                                                    <span className="tygr">SINGER</span>
                                                                                    }

                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {/* {
                                                                            person.type[0] === "Drag queen"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyre"> 
                                                                                    DRAG
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "DJ"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tybl"> 
                                                                                    DJ
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Singer"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tygr"> 
                                                                                    SINGER
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Other"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyor"> 
                                                                                    OTHER
                                                                            </div>
                                                                            } */}
                                                                            </div>
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainer">               
                                                            
                                                                            <div className="gridviewtitlecontainer">
                                                                                {
                                                                                person.name_short === ""
                                                                                &&
                                                                                    <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name}
                                                                                </div>
                                                                                }
                                                                                {
                                                                                person.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name_short}
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>                       
                                                    })
                                                    }
                                                </div>
                                    {/* </div> */}
                          

                                <div className="windowmainsetwidthNW">
                                {
                                (props.selectedDragExpo.description !== "" || props.selectedDragExpo.extra_info !== "")
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {props.selectedDragExpo?.description}
                                    </div>
                                        {
                                    props.selectedDragExpo.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{props.selectedDragExpo.extra_info}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {/* <div className="windowmainboxstd"> */}
                                
                                {
                                props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue).length > 0
                                &&
                               
                                    <div className="windowmainheadingstd">
                                    Venue
                                </div>
                                }
                                </div>
                                {
                                props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue).length > 0
                                &&
                                <div className="windowgrid">
                                    <div className="gridviewcardstd"
                                    onClick={()=>handleOpenWindBCRepDT(props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0])}
                                    >
                                        {/* <Link to={`/barsandclubs/${props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].id}`} style={{ display: 'contents' }}> */}
                                            {/* maybe i should do abovoe calc in body in formula. and wrap in use memo. same as below. ie calc would identify that matched venue */}
                                            {
                                            (props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].image_src} alt=""
                                            onError={(e: any) => e.target.src = props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].image_url}

                                            />
                                            }
                                            {
                                            !(props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].image_src)
                                            &&
                                            <img className="gridviewcardimage" src={props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].image_url} alt=""
                                            />
                                            }
                                            <div className="gridviewcardimagecover">
                                                {/* <div className="gridviewsuburbbubble">
                                                    {props.barsAndClubs.filter(ele => ele.name === selectedDragExpo?.venue)[0].suburb}
                                                </div> */}
                                                <div className="gridviewbottomcontainer">               
                                                    <div className="gridviewtitlecontainerfull">
                                                    <div className="gridviewtitlefull">{props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue)[0].name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </Link>        */}
                                    </div>
                                </div>
                             
                                }
                                <div className="windowmainsetwidthNW">
                                
                                {
                                    props.barsAndClubs.filter(ele => ele.name === props.selectedDragExpo?.venue).length === 0
                                    &&
                                    <div className="windowmainboxstd">
                                        <div className="windowmainheadingstd">
                                            Address
                                        </div>
                                        
                                        <div className="windowmainvenuecardbox">
                                            <div className="windowvenueleft">      
                                                {
                                                props.selectedDragExpo?.venue !== ""
                                                &&
                                                <div>
                                                    <span className="addresshasvenuename">{props.selectedDragExpo?.venue}</span>
                                                </div>
                                                }
                                                {props.selectedDragExpo?.suite_number === "" ? "" : <div>{props.selectedDragExpo?.suite_number}</div>}
                                                <div className="addressfirstrowflexbox">
                                                    
                                                    {props.selectedDragExpo?.street_number === "" ? <div>{props.selectedDragExpo?.street_name}</div> : <div className="limitaddresstooneline">{props.selectedDragExpo?.street_number}{" "}{props.selectedDragExpo?.street_name}</div>}
                                                    {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                                </div>
                                                <div>{props.selectedDragExpo?.suburb} {props.selectedDragExpo?.postcode}</div>       
                                            </div>
                                            {/* <div className="windowvenueright">  
                                                <div className="viewmapbtorng" onClick={() => setIsWindowMapOpenDE(true)}>
                                                    View map
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                }   

                                {/* </div> */}

                                

                                <div className="windowdatetimecont">
                                {/* using the above because same properties and values */}
                                {
                                props.selectedDragExpo?.organiser !== ""
                                &&
                                <div
                                style={props.selectedDragExpo?.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Organiser
                                    </div>
                                    <div className="windowmaintextboxstd extrapadorg">
                                        {props.selectedDragExpo?.organiser}
                                    </div>
                                </div>
                                }
                                {
                                props.selectedDragExpo?.ticket_provider !== ""
                                &&
                                <div
                                style={props.selectedDragExpo?.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                >
                                    <div className="windowmainheadingstd">
                                        Ticket vendor
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {props.selectedDragExpo?.ticket_provider}
                                    </div>
                                </div>
                                }
                            </div>
                            

                            <div className="floatingbuttoncont">
                                                {
                                                (props.selectedDragExpo?.tickets_available)
                                                &&
                                                <div className="mainwindowbtn"
                                                    // onClick={()=>window.open(selectedParty?.website, '_system', 'location=yes')}
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            GET A TICKET
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>

                                            
                                                }
                                                
                                                {
                                                (!props.selectedDragExpo?.tickets_available)
                                                &&
           
                                                    <div className="mainwindowbtn"
                                                    // onClick={handleClickLink}
                                                    >
                                                        <div className="floatbtnskew">
                                                            MORE INFO
                                                            <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                        </div>
                                                    </div>
                                                
                                            
                                                }

                                                
                            </div>



                        

                            
                            

                            
                            
                            

                        </div>

                        <div className="spaceforbtn"></div>
                    
                    </IonContent>
                    </div>

                

                    
                </div>


                <div className="windbotcont" onClick={props.handleCloseWindDT}>

                            {
                                            (props.selectedDragExpo?.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkDT}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            (!props.selectedDragExpo?.tickets_available)
                                            &&
                                            <div className="floatingbuttoncontweb">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkDT}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                           
                        </div>







                </div>
            }


            {
                isRepPPOpenDT
                &&
                <NewWindPPSimp 
                selectedPerson={selectedPPRepDT}
                // people={props.people}
                // gridNo={props.gridNo}
                handleClBackWindPPRep={handleClBackWindPPRepDT}
                handleCloseWind={props.handleCloseWindDT}
                
                />
            }


            {
                        isRepBCOpenDT
                        &&
                        <div className="testwindowabs" onClick={props.handleCloseWindDT}>
                        <div className="windowcontainerNW" onClick={(e) => e.stopPropagation()}>
                        {/* <div className="arrowtest"></div> */}
                        <div className="newwinimgcont">


                                    
                                            {
                                                imgOrMapDT==="img"
                                                &&
                                                selectedBCRepDT.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRepDT.image_src}
                                                onError={(e: any) => e.target.src = selectedBCRepDT.image_url}
                                                ></img>
                                            }
                                            {
                                                imgOrMapDT==="img"
                                                &&
                                                !selectedBCRepDT.image_src
                                                &&
                                                <img className="windowimgboxNW" src={selectedBCRepDT.image_url}
                                                ></img>
                                            }

                                            {
                                                imgOrMapDT==="map"
                                                &&
                                                // <div className="mapcontainer">
                                                    <div ref={mapContainerDT} className="mapcontainer" />
                                                    //can i reuse same map container? do i need to do something in useeffect?
                                                // </div>
                                            }


                                            <div className="segmentconNW">
                                                <div className={imgOrMapDT === "img" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapDT("img")}
                                                >IMAGE</div>
                                                <div className={imgOrMapDT === "map" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapDT("map")}
                                                >MAP</div>
                                            </div>

                                            
                                                <div className="bckicncont"
                                                onClick={handleClBackWindBCRepDT}
                                                >
                                                    <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                                </div>
                                            
                                            
                                        

                        </div>
                    
                        <div className="newwininfocont">
                    <IonContent className="contentpad">

                    
                    

                    
                    
                            <div className="windowmainsetwidthNW">


                                <div className="windowtitleboxNW">
                                    <div className="windowtitlefullNW">
                                        {selectedBCRepDT.name}
                                    </div>
                                </div>
                                


                                
                                
                                {
                                (selectedBCRepDT.description !== "" || selectedBCRepDT.extra_info !== "")
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        About
                                    </div>   
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRepDT.description}
                                    </div>
                                        {
                                    selectedBCRepDT.extra_info !== ""
                                    &&
                                    <div className="windowmaintextboxext">
                                        <div className="exclambox">
                                            <IonIcon icon={alertCircle} className="exclam">
                                            </IonIcon>
                                        </div>
                                        <div className="exclamtext">{selectedBCRepDT.extra_info}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {
                                selectedBCRepDT.price_info !== ""
                                &&
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Cover
                                    </div>
                                    <div className="windowmaintextboxstd">
                                        {selectedBCRepDT.price_info}
                                    </div>
                                </div>
                                }
                                
                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Address
                                    </div>
                                    <div className="windowmainvenuecardbox">
                                        <div className="windowvenueleft">
                                        {selectedBCRepDT.suite_number === "" ? "" : <div>{selectedBCRepDT.suite_number}</div>}
                                            <div className="addressfirstrowflexbox">
                                                
                                                {selectedBCRepDT.street_number === "" ? <div>{selectedBCRepDT.street_name}</div> : <div className="limitaddresstooneline">{selectedBCRepDT.street_number}{" "}{selectedBCRepDT.street_name}</div>}
                                                {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                            </div>
                                            <div>{selectedBCRepDT.suburb} {selectedBCRepDT.postcode}</div>
                                        </div>
                                        {/* <div className="windowvenueright">
                                            <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenBC(true)}>
                                                View map
                                            </div>
                                        </div> */}
                                    </div>
                                </div>



                                <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                    Opening hours
                                    </div>
                                    <div className="openinghoursbox">
                                    <div className="timetablebox">
                                        <div className="">
                                            <div className="weekday">
                                                Monday
                                            </div>
                                            <div className="weekday">
                                                Tuesday
                                            </div>
                                            <div className="weekday">
                                                Wednesday
                                            </div>
                                            <div className="weekday">
                                                Thursday
                                            </div>
                                            <div className="weekday">
                                                Friday
                                            </div>
                                            <div className="weekday">
                                                Saturday
                                            </div>
                                            <div className="weekday">
                                                Sunday
                                            </div>
                                        </div>
                                        <div className="BCtimes">
                                    
                                            {
                                            selectedBCRepDT.monday_open !== ""
                                            &&
                                            !(selectedBCRepDT.monday_open === "12am" && selectedBCRepDT.monday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.monday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.monday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.monday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.monday_open === "12am" && selectedBCRepDT.monday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.tuesday_open !== ""
                                            &&
                                            !(selectedBCRepDT.tuesday_open === "12am" && selectedBCRepDT.tuesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.tuesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.tuesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.tuesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.tuesday_open === "12am" && selectedBCRepDT.tuesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.wednesday_open !== ""
                                            &&
                                            !(selectedBCRepDT.wednesday_open === "12am" && selectedBCRepDT.wednesday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.wednesday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.wednesday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.wednesday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.wednesday_open === "12am" && selectedBCRepDT.wednesday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.thursday_open !== ""
                                            &&
                                            !(selectedBCRepDT.thursday_open === "12am" && selectedBCRepDT.thursday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.thursday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.thursday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.thursday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.thursday_open === "12am" && selectedBCRepDT.thursday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.friday_open !== ""
                                            &&
                                            !(selectedBCRepDT.friday_open === "12am" && selectedBCRepDT.friday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.friday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.friday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.friday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.friday_open === "12am" && selectedBCRepDT.friday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.saturday_open !== ""
                                            &&
                                            !(selectedBCRepDT.saturday_open === "12am" && selectedBCRepDT.saturday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.saturday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.saturday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.saturday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.saturday_open === "12am" && selectedBCRepDT.saturday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }

                                            {
                                            selectedBCRepDT.sunday_open !== ""
                                            &&
                                            !(selectedBCRepDT.sunday_open === "12am" && selectedBCRepDT.sunday_close === "11:59pm")
                                            &&
                                            <div className="standardsinglerowopeninghours">
                                                <div className="firstcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.sunday_open}</div>
                                                </div>
                                                <div className="secondcolumnopeninghours">
                                                    to
                                                </div>
                                                <div className="thirdcolumnopeninghours">
                                                    <div className="bubbledaycontainer">{selectedBCRepDT.sunday_close}</div>
                                                </div>
                                            </div>
                                            }
                                            {
                                            selectedBCRepDT.sunday_open === ""
                                            &&
                                            <div className="closedsinglerowopeninghours">
                                                Closed
                                            </div>
                                            }
                                            {
                                            (selectedBCRepDT.sunday_open === "12am" && selectedBCRepDT.sunday_close === "11:59pm")
                                            &&
                                            <div className="twentyfourhourssinglerowopeninghours">
                                                <div className="opentwentyfourhours">Open 24 hours</div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="floatingbuttoncont">
                        {/* {
                            selectedBarClub.facebook
                            &&
                            <div className="socialmediabtn fbbtn"
                            onClick={()=>window.open(selectedBarClub?.facebook, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoFacebook} className="sharebtniconlg" />
                            </div>
                        </div>
                        } */}
                        
                            {/* <div className="sharebuttonsm">
                                <div className="floatbtnskew">
                                    <IonIcon icon={shareOutline} color="" className="sharebtniconlg" />
                                </div>
                            </div> */}
                        

                        {
                        selectedBCRepDT.website !== ""
                        &&
                        <div className="socialmediabtn"
                        onClick={()=>window.open(selectedBCRepDT.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRepDT.website === ""
                        &&
                        <div className="socialmediabtn btnopac"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={()=>window.open(`https://www.instagram.com/${selectedBCRepDT.instagram}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                        

                        

                    </div>

                              

                                

                                
                       

                            
                                <div className="spaceforbtn"></div>

                               
                                
                            </div>
                       
                    </IonContent>
                    </div>
                    
                </div>

                <div className="windbotcont" onClick={props.handleCloseWindDT}>
                    <div className="floatingbuttoncontweb">
                {
                        selectedBCRepDT.website !== ""
                        &&
                        <div className="socialmediabtnwebBC"
                        onClick={handleClickLinkDTBC}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        selectedBCRepDT.website === ""
                        &&
                        <div className="socialmediabtnwebBC"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed csrnot">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlgx" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstaBC"
                            onClick={handleClickLinkDTInstBC}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                        

                        {/* {
                        (selectedBCRep.phone !== 0 && selectedBCRep.phone !== "")
                        &&
                        <div className="socialmediabtnwebBC">
                            <a className="makefull" href={`tel:+${selectedBCRep?.phone}`}>
                                <div className="floatbtnskewsocialmed">
                                    <IonIcon icon={callOutline} color="" className="sharebtniconlg" />
                                </div>
                            </a>
                        </div>
                        }
                        {
                        (selectedBCRep?.phone === 0 || selectedBCRep.phone === "")
                        &&
                        <div className="socialmediabtnwebBC btnopac">
                            <a className="makefull"
                            // href={`tel:+${selectedBarClub?.phone}`}
                            >
                                <div className="floatbtnskewsocialmed">
                                    <IonIcon icon={callOutline} color="" className="sharebtniconlg" />
                                </div>
                            </a>
                        </div>
                        } */}
                            
                                            
                                           
                    </div>
                                    
                </div>
            </div>
            }



        </>
                                 
            
    )
}

export default NewWindDT;