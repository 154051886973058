import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonHeader,
    IonModal,
    IonMenuButton
} from '@ionic/react';

import { 
    globeOutline,
    callOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    alertCircle,
    shareOutline,
    close,
    arrowBack,
    calendarOutline,
    timeOutline,
    arrowForwardOutline,
    tvOutline,
    logoTiktok,
    logoSoundcloud
} from 'ionicons/icons';

import NewWindPPSimp from './NewWindPPSimp';


// import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';
import '../theme/mod-BC.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const BarAndClubWindow: React.FC<{
    handleCloseWindBC:any;
    selectedBarClub: any;
    barsAndClubs: any[];
    parties: any[];
    currentDateAndTime: any;
    currentDateAndTimePlusTwoWeeks: any
    // likedBarsAndClubs: any;
    // setLikedBarsAndClubs: any;
    // handleIncrement: any;
    // handleDecrement: any;
    gridNo: any;
    peopleOth: any;
    handleIncrClick: any;
}> = (props) => {

    

    // theres no reload in bars and clubs so current date plus two weeks would depend on poeple relaoding .. but i assume given its two weeks it wont ever get that off..like would app reload are a certain period


    const [imgOrTextBC, setImgOrTextBC] = useState<any>("text");
    const [imgOrMapBC, setImgOrMapBC] = useState<any>("img");


    const [isRepPartyOpen, setIsRepPartyOpen] = useState<any>(false);
    const [selectedPartyRep, setSelectedPartyRep] = useState<any>(null);

    const [isRepPPOpenBC, setIsRepPPOpenBC] = useState(false);
    const [selectedPPRepBC, setSelectedPPRepBC] = useState<any>(null);

    
    const mapContainerBC = useRef<any>(null);

    const mapContainerBC2 = useRef<any>(null);

    const handleImgOrTextBCW = (viewClicked: string) => {
        if (viewClicked === imgOrTextBC) return;
        if (viewClicked === "img") setImgOrTextBC("img");
        if (viewClicked === "text") setImgOrTextBC("text");
      };

      const handleImgOrMapBCW = (viewClicked: string) => {
        if (viewClicked === imgOrTextBC) return;
        if (viewClicked === "img") setImgOrMapBC("img");
        if (viewClicked === "map") setImgOrMapBC("map");
      };

      const handleOpenWindPartyRep = (party:any) => {
        // setSelectedPPRepBC(null);
        setSelectedPartyRep(party);
        setImgOrTextBC("text");
        setImgOrMapBC("img");
        setIsRepPartyOpen(true);
      }

      const handleClBackWindPartyRep = () => {
        setSelectedPartyRep(null);
        setImgOrTextBC("text");
        setImgOrMapBC("img");
        setIsRepPartyOpen(false);
      }

      const handleOpenWindPPRepBC = (person:any) => {
        // setSelectedPartyRep(null);
        setSelectedPPRepBC(person);
        setImgOrTextBC("text");
        setImgOrMapBC("img");
        setIsRepPPOpenBC(true);
      }

      const handleClBackWindPPRepBC = () => {
        // setIsRepPPOpenBC(false);
        setSelectedPPRepBC(null);
        // setSelectedPPRep(null);
        //above not necessary since comp will be demounted
        setImgOrTextBC("text");
        setImgOrMapBC("img");
        setIsRepPPOpenBC(false);
      }

      const handleClickLinkBC = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedBarClub.website, '_system', 'location=yes');
        // props.handleIncrClick("parties", props.selectedParty.hash);
    }

    const handleClickLinkBCInsta = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${props.selectedBarClub.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }


    const handleClickLinkPRep2 = (e:any) => {
        e.stopPropagation();
        window.open(selectedPartyRep.website, '_system', 'location=yes');
        props.handleIncrClick("parties", selectedPartyRep.hash);
    }


    useEffect(() => {

        // if (!mapContainerBC.current) {
        //     return
        // }
       

        const mapBC = new mapboxgl.Map({
            container: mapContainerBC.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [props.selectedBarClub.lng, props.selectedBarClub.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([props.selectedBarClub.lng, props.selectedBarClub.lat]).addTo(mapBC);

        

        return () => mapBC.remove();

    },[]);

    useEffect(() => {

        // if (!mapContainerBC.current) {
        //     return
        // }
        if (imgOrMapBC !== "map") {
            return
        }

        const mapBC = new mapboxgl.Map({
            container: mapContainerBC2.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [props.selectedBarClub.lng, props.selectedBarClub.lat],
            zoom: 13,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([props.selectedBarClub.lng, props.selectedBarClub.lat]).addTo(mapBC);

        

        return () => mapBC.remove();

    },[imgOrMapBC]);

    useEffect(() => {
        console.log(props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks), 'xxx');
    })

 

    

    return(
        <>
        <div className="bigcardcont">
            <div className="BCtopcont">
                                    <div className="ionclosecontnorm">
                                            <IonIcon icon={close} className="closeic" onClick={props.handleCloseWindBC}></IonIcon>
                                    </div>

            </div>
            <div className="bigcardBC" onClick={(e) => e.stopPropagation()}>
                                    
                
                                    
                                <div className={(selectedPartyRep || selectedPPRepBC) ? "segmentconBCWP" : "segmentconBCW"}>
                                            <div className={imgOrTextBC === "text" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrTextBCW("text")}
                                            >INFO</div>
                                            <div className={imgOrTextBC === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrTextBCW("img")}
                                            >IMAGE</div>
                                </div>
                                
                                
                                {
                                    (isRepPartyOpen && !isRepPPOpenBC)
                                    &&
                                    <div className="segmentconrepBC"
                                            onClick={handleClBackWindPartyRep}
                                            >
                                                <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                </div>
                                }
                                {
                                    (isRepPPOpenBC)
                                    &&
                                    <div className="segmentconrepBC"
                                            onClick={handleClBackWindPPRepBC}
                                            >
                                                <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                </div>
                                }
                                

                    {
                        imgOrTextBC === "img"
                        &&
                        (!selectedPartyRep && !selectedPPRepBC)
                        &&
                        
                        props.selectedBarClub.image_src
                                            &&
                                            <div className="BCWimgcont">
                                            <img className="windowimgboxNW" src={props.selectedBarClub.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        imgOrTextBC === "img"
                        &&
                        (!selectedPartyRep && !selectedPPRepBC)
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                        <div className="BCWimgcont">
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        </div>
                    }

                <div className="BCWimgcontsm">
                                            {
                                                !isRepPPOpenBC
                                                &&
                                                <div className="segconNWBCoth">
                                                <div className={imgOrMapBC === "img" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapBCW("img")}
                                                >IMAGE</div>
                                                <div className={imgOrMapBC === "map" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapBCW("map")}
                                                >MAP</div>
                                            </div>
                                            }
                                            

                    {
                        imgOrMapBC === "map"
                        &&
                        <div ref={mapContainerBC2} className="mapcontainer" />
                    }

                    {
                        imgOrMapBC === "img"
                        &&
                        (isRepPartyOpen && !isRepPPOpenBC)
                        &&
                        
                        selectedPartyRep.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedPartyRep.image_src}
                                            onError={(e: any) => e.target.src = selectedPartyRep.image_url}
                                            ></img>
                                            
                    }
                    {
                        imgOrMapBC === "img"
                        &&
                        (isRepPartyOpen && !isRepPPOpenBC)
                        &&
                        !selectedPartyRep.image_src
                        &&
                            <img className="windowimgboxNW" src={selectedPartyRep.image_url}
                            ></img>
                    }
                    {
                        imgOrMapBC === "img"
                        &&
                        (isRepPPOpenBC)
                        &&
                        
                        selectedPPRepBC.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedPPRepBC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepBC.image_url}
                                            ></img>
                    }
                    {
                        imgOrMapBC === "img"
                        &&
                        (isRepPPOpenBC)
                        &&
                        !selectedPPRepBC.image_src
                        &&
                            <img className="windowimgboxNW" src={selectedPPRepBC.image_url}
                            ></img>
                    }
                    {
                        imgOrMapBC === "img"
                        &&
                        (!selectedPartyRep && !selectedPPRepBC)
                        &&
                        
                        props.selectedBarClub.image_src
                                            &&
                                           
                                            <img className="windowimgboxNW" src={props.selectedBarClub.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub.image_url}
                                            ></img>
                                           
                    }
                    {
                        imgOrMapBC === "img"
                        &&
                        (!selectedPartyRep && !selectedPPRepBC)
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                       
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        
                    }

                    {/* {
                       
                        (!selectedPartyRep && !selectedPPRepBC)
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                        
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        
                    } */}

                </div>



                    {
                        imgOrTextBC === "img"
                        &&
                        isRepPartyOpen && !isRepPPOpenBC
                        &&
                        
                        selectedPartyRep.image_src
                                            &&
                                            <div className="BCWimgcont">
                                            <img className="windowimgboxNW" src={selectedPartyRep.image_src}
                                            onError={(e: any) => e.target.src = selectedPartyRep.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        imgOrTextBC === "img"
                        &&
                        isRepPartyOpen && !isRepPPOpenBC
                        &&
                        !selectedPartyRep.image_src
                        &&
                        <div className="BCWimgcont">
                            <img className="windowimgboxNW" src={selectedPartyRep.image_url}
                            ></img>
                        </div>


                    }

                    {
                        imgOrTextBC === "img"
                        &&
                        isRepPPOpenBC
                        &&
                        
                        selectedPPRepBC.image_src
                                            &&
                                            <div className="BCWimgcont">
                                            <img className="windowimgboxNW" src={selectedPPRepBC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepBC.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        imgOrTextBC === "img"
                        &&
                        isRepPPOpenBC
                        &&
                        !selectedPPRepBC.image_src
                        &&
                        <div className="BCWimgcont">
                            <img className="windowimgboxNW" src={selectedPPRepBC.image_url}
                            ></img>
                        </div>


                    }

                    
            {
                            // imgOrTextBC === "text"
                            // &&
                            !isRepPartyOpen && !isRepPPOpenBC
                        &&
            <IonContent className={imgOrTextBC === "text" ? "contentBBCW" : "nocontentBBCW"}>
                
                {/* <IonModal
                ref={modalMapBC}
                className="windowmapmodal"
                isOpen={isWindowMapOpenBC}
                onDidDismiss={handleDismissWindowMapModalBC}
                onDidPresent={() => setIsWindowMapLoadedBC(true)}
                >
                    <div className="mapcontainer">
                        <div ref={mapContainerBC} className="mapcontainerinner" />
                    </div>
                </IonModal> */}

                                    
                    
                        
                <div className="windowcontainerwithgrad">
                        
                            
                    
                    

                    {/* <div className="windowtopcont">
                        


                        {
                            props.selectedBarClub.image_src
                            &&
                            <img className="windowimgbox" src={props.selectedBarClub.image_src} alt=""
                            onError={(e: any) => e.target.src = props.selectedBarClub.image_url}

                            ></img>

                        }
                        {
                            !props.selectedBarClub.image_src
                            &&
                            <img className="windowimgbox" src={props.selectedBarClub.image_url} alt=""
                            ></img>

                        }
                        <div className="windowimggrad">
                            <div className="windowtitlebox">
                                <div className="windowtitlefull">
                                    {props.selectedBarClub?.name}
                                </div>
                            </div>

                        </div>
                    </div> */}
                    

                    <div className="windowmainsetwidthNW">
                        

                                

                        <div className="windowtitleboxBCW">
                            
                                    <div className="windowtitlefullNW">
                                        {props.selectedBarClub.name}
                                    </div>
                        </div>
                        {
                            props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                            &&
                        <div className="windowmainheadingstd headingliveBC">
                                    <div className="livenow">LIVE NOW</div>
                                    <div className="livelines">
                                        <div className="livelinet"></div>
                                        <div className="livelineb"></div>
                                    </div>
                        </div>
                        }
                    </div>
                    
                        {/* make live event have eg a different background. and maybe allow for two live events... rather than just taking first in array.. */}
                        {/* i think it would make sense to have parties in progress, parties today actually separated in app? but think about whether it would actually improve party funcationality given you need to filter */}
                        {
                        // is it better to have multiple filters or multiple conditions in one filter?
                        props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => ((party.daterealfinish > props.currentDateAndTime) && (party.daterealstart <= props.currentDateAndTime))).length > 0
                        &&
                        // <div className="windowmainboxstd">
                            
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${Math.max(Math.min(props.gridNo-2, 3),2)}, calc(100% / ${Math.max(Math.min(props.gridNo-2, 3),2)}))`}}
                            >
                            {/* used the box from party window where you just have one venue (here you would only have one live event). just created 50% space */}
                                {/* I seem to have mix of link then card div and card div then link... */}
                                {/* <Link to={`/parties/${props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealfinish > props.currentDateAndTime && party.daterealstart <= props.currentDateAndTime)[0].id}`} style={{ display: 'contents'}}> */}
                                    {/* maybe i should do abovoe calc in body in formula. and wrap in use memo. same as below. ie calc would identify that matched venue */}
                                    
                                    {
                                        props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealfinish > props.currentDateAndTime && party.daterealstart <= props.currentDateAndTime)
                                        .map((party:any, index: number) => {
                                            return  <div key={index} className="gridviewcardstd"
                                                onClick={() => handleOpenWindPartyRep(party)}
                                                >
                                        {
                                            (party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_src} alt=""
                                            onError={(e: any) => e.target.src = party.image_url}

                                            />   

                                        }
                                        {
                                            !(party.image_src)
                                            &&
                                            <img className="gridviewcardimage" src={party.image_url} alt=""

                                            />   

                                        }
                                        <div className="gridviewcardimagecover">
                                        {
                                            (party.tickets_available && party.price > 0 && party.price !== 999)
                                            &&
                                           
                                            <div className="gridviewpricebubblealt"> 
                                                <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price}
                                            </div>
                                            }
                                            {
                                            party.tickets_available && party.price === 0
                                            &&
                                            <div className="gridviewpricebubblefree"> 
                                                Free
                                            </div>
                                            }
                                            {
                                            !party.tickets_available
                                            &&
                                            <div className="gridviewpricebubblesoldout"> 
                                                Sold out
                                            </div>
                                            }
    
                                            <div className="gridviewbottomcontainer">               
                                                <div className="gridviewtitlecontaineroth">
                                                {
                                                    party.name_short === ""
                                                    &&
                                                    <div className="gridviewtitlemod">{party.name}</div>
                                                }
                                                {
                                                    party.name_short !== ""
                                                    &&
                                                    <div className="gridviewtitlemod">{party.name_short}</div>
                                                }
                                                </div>
                                                <div className="gridviewtimecontaineroth">
                                                    <div className="gridviewtimebubblelive">
                                                        <div className="livefrom">until</div>
                                                        <div className="colorpi">{party.finish_time}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        })
                                    }
                                    
                                {/* </Link> */}
                            </div>
            
                        }


                    <div className="windowmainsetwidthNW">
                        {
                        (props.selectedBarClub?.description !== "" || props.selectedBarClub.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>
                            <div className="windowmaintextboxstd">
                                {props.selectedBarClub.description}
                            </div>
                            {
                            props.selectedBarClub.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{props.selectedBarClub.extra_info}</div>
                            </div>
                            }
                        </div>
                        }

                        {
                        props.selectedBarClub?.price_info !== ""
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Entry
                            </div>
                            <div className="windowmaintextboxstd">
                                {props.selectedBarClub.price_info}
                            </div>
                        </div>
                        }

                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                Address
                            </div>
                            <div className="windowmainvenuecardbox">
                                <div className="windowvenueleft">
                                {props.selectedBarClub.suite_number === "" ? "" : <div>{props.selectedBarClub.suite_number}</div>}
                                    <div className="addressfirstrowflexbox">
                                        
                                        {props.selectedBarClub.street_number === "" ? <div>{props.selectedBarClub.street_name}</div> : <div className="limitaddresstooneline">{props.selectedBarClub.street_number}{" "}{props.selectedBarClub.street_name}</div>}
                                        {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                    </div>
                                    <div>{props.selectedBarClub.suburb} {props.selectedBarClub.postcode}</div>
                                </div>
                                {/* <div className="windowvenueright">
                                    <div className="viewmapbtnpurp" onClick={() => setIsWindowMapOpenBC(true)}>
                                        View map
                                    </div>
                                </div> */}
                            </div>
                        </div>


                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                            Opening hours
                            </div>
                            <div className="openinghoursbox">
                            <div className="timetablebox">
                                <div className="">
                                    <div className="weekday">
                                        Monday
                                    </div>
                                    <div className="weekday">
                                        Tuesday
                                    </div>
                                    <div className="weekday">
                                        Wednesday
                                    </div>
                                    <div className="weekday">
                                        Thursday
                                    </div>
                                    <div className="weekday">
                                        Friday
                                    </div>
                                    <div className="weekday">
                                        Saturday
                                    </div>
                                    <div className="weekday">
                                        Sunday
                                    </div>
                                </div>
                                <div className="BCtimes">
                                    
                                    {
                                    props.selectedBarClub?.monday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.monday_open === "12am" && props.selectedBarClub?.monday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.monday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.monday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.monday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.monday_open === "12am" && props.selectedBarClub?.monday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.tuesday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.tuesday_open === "12am" && props.selectedBarClub?.tuesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.tuesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.tuesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.tuesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.tuesday_open === "12am" && props.selectedBarClub?.tuesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.wednesday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.wednesday_open === "12am" && props.selectedBarClub?.wednesday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.wednesday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.wednesday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.wednesday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.wednesday_open === "12am" && props.selectedBarClub?.wednesday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.thursday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.thursday_open === "12am" && props.selectedBarClub?.thursday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.thursday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.thursday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.thursday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.thursday_open === "12am" && props.selectedBarClub?.thursday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.friday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.friday_open === "12am" && props.selectedBarClub?.friday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.friday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.friday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.friday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.friday_open === "12am" && props.selectedBarClub?.friday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.saturday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.saturday_open === "12am" && props.selectedBarClub?.saturday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.saturday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.saturday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.saturday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.saturday_open === "12am" && props.selectedBarClub?.saturday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }

                                    {
                                    props.selectedBarClub?.sunday_open !== ""
                                    &&
                                    !(props.selectedBarClub?.sunday_open === "12am" && props.selectedBarClub?.sunday_close === "11:59pm")
                                    &&
                                    <div className="standardsinglerowopeninghours">
                                        <div className="firstcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.sunday_open}</div>
                                        </div>
                                        <div className="secondcolumnopeninghours">
                                            to
                                        </div>
                                        <div className="thirdcolumnopeninghours">
                                            <div className="bubbledaycontainer">{props.selectedBarClub?.sunday_close}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    props.selectedBarClub?.sunday_open === ""
                                    &&
                                    <div className="closedsinglerowopeninghours">
                                        Closed
                                    </div>
                                    }
                                    {
                                    (props.selectedBarClub?.sunday_open === "12am" && props.selectedBarClub?.sunday_close === "11:59pm")
                                    &&
                                    <div className="twentyfourhourssinglerowopeninghours">
                                        <div className="opentwentyfourhours">Open 24 hours</div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
                           
                        {
                        props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > 0
                        &&
                        <div className="windowmainheadingstd">
                            Upcoming events
                        </div>
                        }
                    
                    </div> 
                    {/* end of first set width main section */}
                    
                    {
                    ((props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > 0))
                    &&
                    <div className="windowgrid"
                    // style={{gridTemplateColumns: `repeat(${Math.max(Math.min(props.gridNo-2, 3),2)}, calc(100% / ${Math.max(Math.min(props.gridNo-2, 3),2)}))`}}
                    >
                        {
                        props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks)
                            .map((party, index) => {
                            return <div key={index} className="gridviewcardBC"
                            onClick={() => handleOpenWindPartyRep(party)}
                            >
                                            {
                                                party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_src} alt=""
                                                onError={(e: any) => e.target.src = party.image_url}

                                                />
                                            }
                                            {
                                                !party.image_src
                                                &&
                                                <img className="gridviewcardimage" src={party.image_url} alt="" />
                                            }
                                            <div className="gridviewcardimagecover">
                                            {
                                            (party.tickets_available && party.price > 0 && party.price !== 999)
                                            &&
                                           
                                            <div className="gridviewpricebubblealt"> 
                                                <span className="livefrom">from</span>&nbsp;&nbsp;{party.currency_symbol}{party.price}
                                            </div>
                                            }
                                            {
                                            party.tickets_available && party.price === 0
                                            &&
                                            <div className="gridviewpricebubblefree"> 
                                                Free
                                            </div>
                                            }
                                            {
                                            !party.tickets_available
                                            &&
                                            <div className="gridviewpricebubblesoldout"> 
                                                Sold out
                                            </div>
                                            }
                                               



                                                <div className="gridviewbottomcontainer">
                                                    <div className="gridviewtitlecontainer">
                                                                {
                                                                party.name_short === ""
                                                                &&
                                                                <div className="gridviewtitle">{party.name}</div>
                                                            }
                                                            {
                                                                party.name_short !== ""
                                                                &&
                                                                <div className="gridviewtitle">{party.name_short}</div>
                                                            }
                                                    </div>
                                                    <div className="gridviewtimecontainer">
                                                        <div className="gridviewtimebubble">
                                                            
                                                            <div className="gridviewtimebubbletop">{party.start_date}{" "}</div>
                                                            <div className="gridviewtimebubblemon">{party.start_month.slice(0,3)}</div>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                            })
                        }
                    </div>
                    }




                    {/* {
                    props.parties.filter(party => party.venue === props.selectedBarClub.name).filter(party => party.daterealstart >= props.currentDateAndTime && party.daterealstart <= props.currentDateAndTimePlusTwoWeeks).length > 2
                    &&
                    <div className="swipercontainerstd">
                        
                    </div>
                    } */}

                    {/* REMOVE ABOVE FROM APP VERSION */}
                        

                    

                    {/* <div className="floatingbuttoncontalt">
                       
                        

                        {
                        props.selectedBarClub?.website !== ""
                        &&
                        <div className="socialmediabtn"
                        onClick={()=>window.open(props.selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        props.selectedBarClub?.website === ""
                        &&
                        <div className="socialmediabtn btnopac"
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }

                       
                            <div className="socialmediabtninsta instabtngr"
                            onClick={()=>window.open(`https://www.instagram.com/${props.selectedBarClub?.instagram}`, '_system', 'location=yes')}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                  

                        

                        

                    </div>

                    <div className="spaceforbtn"></div> */}

                    
                    
                </div>   
                                              
            </IonContent>
            }

                    {
                        isRepPPOpenBC
                        &&
                        // imgOrTextBC === "text"
                        //     &&
                        <IonContent className={imgOrTextBC === "text" ? "contentBBCW" : "nocontentBBCW"}>
                
                

                                    
                    
                        
                <div className="windowcontainerwithgrad">

               
                        

                    <div className="windowmainsetwidthNW">

                        <div className="windowtitleboxBCW">
                                
                                <div className="windowtitlefullNW">
                                    {selectedPPRepBC.name}
                                </div>
                        </div>

                        <div className="windowmainboxstd">
                                <div className="windowmaintextboxstd seeothersecbox">
                                    For a full list of {selectedPPRepBC.name}{"'"}s upcoming performances, please refer to the {"'"}LGBT+ Entertainers{"'"} section of the website.
                                </div>
                            </div>


                        
                   


                        

                        {
                        (selectedPPRepBC.description !== "" || selectedPPRepBC.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedPPRepBC.description}
                            </div>
                            {
                            selectedPPRepBC.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{selectedPPRepBC.extra_info}</div>
                            </div>
                            }
                        </div>
                        }

{
                            selectedPPRepBC.tv_shows.length > 0
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Credits
                                    </div>  
                                    {
                                       selectedPPRepBC.tv_shows.map((ele: any, index: number) => {
                                            return <div key={index} className="tvshowlistcont">
                                                        <div className="tvshowlistitem">
                                                            <IonIcon icon={tvOutline} size="small" color="primary" />
                                                        </div>
                                                        <div className="tvshowlistitem">{ele}</div>
                                                </div>
                                        })
                                    }
                            </div>
                            }

                        

                           
                               
                    
                    </div> 
                  
                   

                        

                        

                    

                    
                    
                </div>   
                                              
            </IonContent>
                    }



            {
                            // imgOrTextBC === "text"
                            // &&
                            isRepPartyOpen && !isRepPPOpenBC
                        &&
            <IonContent className={imgOrTextBC === "text" ? "contentBBCW" : "nocontentBBCW"}>
                
                

                                    
                    
                        
                <div className="windowcontainerwithgrad">

                {/* <div className="newwinimgcontrepP">


                                   
                    {
                        
                        selectedPartyRep.image_src
                        &&
                        <img className="windowimgboxNW" src={selectedPartyRep.image_src}
                        onError={(e: any) => e.target.src = selectedPartyRep.image_url}
                        ></img>
                    }
                    {
                        
                        !selectedPartyRep.image_src
                        &&
                        <img className="windowimgboxNW" src={selectedPartyRep.image_url}
                        ></img>
                    }

                


                    

                    



                    </div> */}

                    {/* <div className="windowtopcont">
                        {
                            selectedPartyRep.image_src
                            &&
                            <img className="windowimgbox" src={selectedPartyRep.image_src}
                            onError={(e: any) => e.target.src = selectedPartyRep.image_url}
                            ></img>
                        }
                        {
                            !selectedPartyRep.image_src
                            &&
                            <img className="windowimgbox" src={selectedPartyRep.image_url}
                            ></img>
                        }
                        <div className="windowimggrad">
                            {
                                selectedPartyRep.price !== 999
                                &&
                                <div className="windowtitlebox">

                                {
                                selectedPartyRep.daterealstart <= props.currentDateAndTime
                                &&
                                <div
                                className="livewindowbox">
                                    <div className="blinkingtextlivebg">LIVE</div>
                                </div>
                                }

                                

                                
                                    <div className="windowtitleshort">
                                        {selectedPartyRep.name}
                                    </div>
                                    
                        
                                
                                    {
                                    selectedPartyRep.price === 0 && selectedPartyRep.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightfree">
                                            <div>FREE</div>
                                        </div>
                                    </div>
                                    }

                                    {
                                    selectedPartyRep.price !== 0 && selectedPartyRep.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titleright">
                                            <div className="titlerightfrom">from</div>
                                            <div className="titlerightmarg">{selectedPartyRep.currency_symbol}{selectedPartyRep.price}</div>
                                        </div>
                                    </div>
                                    }
                                    {
                                    !selectedPartyRep.tickets_available
                                    &&
                                    <div className="titleboxright">
                                        <div className="titlerightsoldout">
                                            <div className="">SOLD</div>
                                            <div className="">OUT</div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                            {
                            selectedPartyRep.price === 999
                            &&
                            <div className="windowtitlebox">
                                <div className="windowtitlefull">
                                    {selectedPartyRep.name}
                                </div>
                            </div>
                            }
                            
                        </div>
                    </div> */}
                        
                            
                    
        
                    

                    <div className="windowmainsetwidthNW">

                        <div className="windowtitleboxBCW">
                                
                                <div className="windowtitlefullNW">
                                    {selectedPartyRep.name}
                                </div>
                        </div>


                        
                    {
                        !selectedPartyRep.tickets_available
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmaintextboxstd soldoutbox">
                                Ticket allocation for this event has been exhausted however tickets may be available through resale facilities.
                            </div>
                        </div>
                        }


                        {/* {
                        selectedParty.daterealstart > props.currentDateAndTime
                        && */}
                        <div className="windowdatetimecont">
                            <div className="datetimebox">
                                <IonIcon icon={calendarOutline} className="datetimeicon" />
                                <div className="datebox">
                                    <div className="datetimetext">
                                        {selectedPartyRep.start_day.slice(0,3)}{", "}{selectedPartyRep.start_date}{" "}{selectedPartyRep.start_month.slice(0,3)}
                                        {/* <br/>
                                        2-11pm */}
                                    </div>
                                </div>
                            </div>
                            <div className="datetimebox">
                                <IonIcon icon={timeOutline} className="datetimeicon" />
                                <div className="timebox">
                                    <div className="datetimetext">
                                        {selectedPartyRep.start_time}{" "}to{" "}{selectedPartyRep.finish_time}
                                    </div>
                                </div>
                            </div>                  
                        </div>
                        {/* } */}

                        {
                        (selectedPartyRep.description !== "" || selectedPartyRep.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedPartyRep.description}
                            </div>
                            {
                            selectedPartyRep.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{selectedPartyRep.extra_info}</div>
                            </div>
                            }
                        </div>
                        }

                            <div className="windowmainboxstd">
                                                
                                                <div className="windowmainheadingstd">
                                                    Venue
                                                </div>
                                                
                                               
                                                <div className="windowmainvenuecardbox">
                                                    <div className="windowvenueleft">      
                                                        {
                                                        selectedPartyRep.venue !== ""
                                                        &&
                                                        <div>
                                                            <span className="addresshasvenuename">{selectedPartyRep.venue}</span>
                                                        </div>
                                                        }
                                                        {selectedPartyRep.suite_number === "" ? "" : <div>{selectedPartyRep.suite_number}</div>}
                                                        <div className="addressfirstrowflexbox">
                                                            
                                                            {selectedPartyRep.street_number === "" ? <div>{selectedPartyRep.street_name}</div> : <div className="limitaddresstooneline">{selectedPartyRep.street_number}{" "}{selectedPartyRep.street_name}</div>}
                                                            {/* cant really have suite number but no street number at the moment. ALSO If box is too short it looks weird. not sure if okay now.. anyway can always eg change street to st */}
                                                        </div>
                                                        <div>{selectedPartyRep.suburb} {selectedPartyRep.postcode}</div>       
                                                    </div>
                                                    {/* <div className="windowvenueright">  
                                                        <img
                                                        src={mapimage}
                                                        alt=""
                                                        className="windowvenueimg" 
                                                        onClick={() => setIsWindowMapOpenP(true)}
                                                        />
                                                    </div> */}
                                                </div>
                                            
                            </div>

                        

                           
                                {
                                    (
                                selectedPartyRep.entertainers !== ""
                                &&
                                props.peopleOth.filter((person: any) => selectedPartyRep.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                    )
                                &&
                                <div className="windowmainheadingstd">
                                    Featured entertainers
                                </div>
                                }
                    
                    </div> 
                  
                    {
                            selectedPartyRep.entertainers !== ""
                            &&
                            props.peopleOth.filter((person: any) => selectedPartyRep.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                            &&
                            <div className="windowgrid"
                            // style={{gridTemplateColumns: `repeat(${props.gridNo - 2}, calc(100% / ${props.gridNo - 2}))`}}
                            >
                                {props.peopleOth.filter((person: any) => selectedPartyRep.entertainers.some((entertainer: any) => entertainer === person.name))
                                .map((person: any, index: any) => {
                                    return <div key={index} className="gridviewcardstd"
                                    onClick={() => handleOpenWindPPRepBC(person)}
                                    >
                                                    {
                                                        person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_src} alt=""
                                                        onError={(e: any) => e.target.src = person.image_url}
                                                        />
                                                    }
                                                    {
                                                        !person.image_src
                                                        &&
                                                        <img className="gridviewcardimage" src={person.image_url} alt="" />
                                                    }
                                                    
                                                    <div className="gridviewcardimagecover">
                                                       
                                                    <div className="gridviewtopcont">
                                                                            {
                                                                                person.type.map((typeparam:any, index:number) => {
                                                                                    return <div key={index} className="gridviewpricebubbletype"> 

                                                                                    {
                                                                                    typeparam === "Drag queen"
                                                                                    &&
                                                                                    <span className="tyre">DRAG</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "DJ"
                                                                                    &&
                                                                                    <span className="tybl">DJ</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Other"
                                                                                    &&
                                                                                    <span className="tyor">OTHER</span>
                                                                                    }

                                                                                    {
                                                                                    typeparam === "Singer"
                                                                                    &&
                                                                                    <span className="tygr">SINGER</span>
                                                                                    }

                                                                                    </div>
                                                                                })
                                                                            }
                                                                            {/* {
                                                                            person.type[0] === "Drag queen"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyre"> 
                                                                                    DRAG
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "DJ"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tybl"> 
                                                                                    DJ
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Singer"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tygr"> 
                                                                                    SINGER
                                                                            </div>
                                                                            }

                                                                            {
                                                                            person.type[0] === "Other"
                                                                                &&
                                                                            <div className="gridviewpricebubbletype tyor"> 
                                                                                    OTHER
                                                                            </div>
                                                                            } */}
                                                                            </div>
                                                        
                                                        
                                                        <div className="gridviewbottomcontainer">               
                                                
                                                                <div className="gridviewtitlecontainer">
                                                                                {
                                                                                person.name_short === ""
                                                                                &&
                                                                                    <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name}
                                                                                </div>
                                                                                }
                                                                                {
                                                                                person.name_short !== ""
                                                                                &&
                                                                                <div className="gridviewtitlefull2">
                                                                                    {/* <div className="gridviewtitlePP"> */}
                                                                                    {person.name_short}
                                                                                </div>
                                                                                }
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                            
                                })}
                            </div>
                            }

                        <div className="windowmainsetwidthNW">
                                <div className="windowdatetimecont">
                                    {/* using the above because same properties and values */}
                                    {
                                    selectedPartyRep.organiser !== ""
                                    &&
                                    <div
                                    style={selectedPartyRep.ticket_provider !== "" ? {width: '50%'} : {width: '100%'}}
                                    >
                                        <div className="windowmainheadingstd">
                                            Organiser
                                        </div>
                                        <div className="windowmaintextboxstd extrapadorg">
                                            {selectedPartyRep.organiser}
                                        </div>
                                    </div>
                                    }
                                    {
                                    selectedPartyRep.ticket_provider !== ""
                                    &&
                                    <div
                                    style={selectedPartyRep.organiser !== "" ? {width: '50%'} : {width: '100%'}}
                                    >
                                        <div className="windowmainheadingstd">
                                            Ticket vendor
                                        </div>
                                        <div className="windowmaintextboxstd">
                                            {selectedPartyRep.ticket_provider}
                                        </div>
                                    </div>
                                    }
                                </div>

                               
                                
                            </div>

                        

                    

                    
                    
                </div>   
                                              
            </IonContent>
            }



            </div>


            {
                            // imgOrTextBC === "text"
                            // &&
                            // not sure why i need the above conditional above
                            !selectedPartyRep && !selectedPPRepBC
                        &&
            <div className="BCbotcont">
            <div className="flotbtncontBC">
                {
                        props.selectedBarClub.website !== ""
                        &&
                        <div className="socialmediabtnwebBC"
                        // onClick={()=>window.open(props.selectedBarClub?.website, '_system', 'location=yes')}
                        onClick={handleClickLinkBC}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                        props.selectedBarClub.website === ""
                        &&
                        <div className="socialmediabtnwebBC btnopac"
                        // onClick={()=>window.open(selectedBarClub?.website, '_system', 'location=yes')}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} color="" className="sharebtniconlg" />
                            </div>
                        </div>
                        }

                        {/* {
                            selectedBarClub.instagram !== ""
                            && */}
                            <div className="socialmediabtninstawebBC instabtngr"
                            // onClick={()=>window.open(`https://www.instagram.com/${props.selectedBarClub?.instagram}`, '_system', 'location=yes')}
                            onClick={handleClickLinkBCInsta}
                            >
                            <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                            </div>
                        {/* } */}

                        

                        {/* {
                        (props.selectedBarClub.phone !== 0 && props.selectedBarClub.phone !== "")
                        &&
                        <div className="socialmediabtnwebBC">
                            <a className="makefull" href={`tel:+${props.selectedBarClub?.phone}`}>
                                <div className="floatbtnskewsocialmed">
                                    <IonIcon icon={callOutline} color="" className="sharebtniconlg" />
                                </div>
                            </a>
                        </div>
                        }
                        {
                        (props.selectedBarClub?.phone === 0 || props.selectedBarClub.phone === "")
                        &&
                        <div className="socialmediabtnwebBC btnopac">
                            <a className="makefull"
                            >
                                <div className="floatbtnskewsocialmed">
                                    <IonIcon icon={callOutline} color="" className="sharebtniconlg" />
                                </div>
                            </a>
                        </div>
                        } */}
                            
                                            
                                           
                    </div>
            </div>
            }
            {
                            // imgOrTextBC === "text"
                            // &&
                            isRepPartyOpen && !isRepPPOpenBC
                        &&
            <div className="BCbotcont">
            <div className="flotbtncontBC">
            {
                                            (selectedPartyRep.price !== 0 && selectedPartyRep.tickets_available && selectedPartyRep.price !== 999 && selectedPartyRep.price_type !== "more" && !selectedPartyRep.global)
                                            &&
                                            <div className="flotbtncontBC">

                                                
                                            
                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPRep2}
                                                >
                                                    <div className="floatbtnskew">
                                                        GET A TICKET
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            
                                            {
                                            ((selectedPartyRep.price === 0 || !selectedPartyRep.tickets_available || selectedPartyRep.price === 999 || selectedPartyRep.price_type === "more") && !selectedPartyRep.global)
                                            &&
                                            <div className="flotbtncontBC">

                                                

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPRep2}
                                                >
                                                    <div className="floatbtnskew">
                                                        MORE INFO
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }

                                            {
                                            selectedPartyRep.global
                                            &&
                                            <div className="flotbtncontBC">

                                            

                                                <div className="mainwindowbtnweb"
                                                onClick={handleClickLinkPRep2}
                                                >
                                                    <div className="floatbtnskew reserv">
                                                        MAKE A RESERVATION
                                                        <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            }
                        

                        

                        

                        
                            
                                            
                                           
                    </div>
            </div>
            }
            {
                            // imgOrTextBC === "text"
                            // &&
                            isRepPPOpenBC
                        &&
            <div className="BCbotcont">
            <div className="flotbtncontBC">
          
                                          
            {
                               selectedPPRepBC.soundcloud !== ""
                                &&
                                <div className="socialmediabtnwebBC btnmarg"
                                // onClick={handleClickLinkPPSC}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                selectedPPRepBC.soundcloud === ""
                                &&
                                <div className="socialmediabtnwebBC btnmarg cursorno"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                onClick={(e) => e.stopPropagation()}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx btnopac" />
                                </div>
                            </div>
                            }

                        

                        {
                            selectedPPRepBC.website !== ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg"
                            // onClick={handleClickLinkPP}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPPRepBC.website === ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg cursorno"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx btnopac" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninstawebBC instabtn btnmarg"
                        // onClick={handleClickLinkPPInst}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        {/* spotify now equals tiktok */}
                        {
                            selectedPPRepBC.spotify !== ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg"
                            // onClick={handleClickLinkPPTT}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPPRepBC.spotify === ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg cursorno"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx btnopac" />
                            </div>
                        </div>
                        }

                        

                        
                            
                                            
                                           
                    </div>
            </div>
            }
            </div>
            <div className="mapimgcont">
                        {
                        !isRepPartyOpen && !isRepPPOpenBC
                        &&
                        props.selectedBarClub?.image_src
                                            &&
                                            <div className="BCWimgconthalf">
                                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub?.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        !isRepPartyOpen && !isRepPPOpenBC
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                        <div className="BCWimgconthalf">
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        </div>
                    }
                    {
                        isRepPartyOpen && !isRepPPOpenBC
                        &&
                        selectedPartyRep.image_src
                                            &&
                                            <div className="BCWimgconthalf">
                                            <img className="windowimgboxNW" src={selectedPartyRep.image_src}
                                            onError={(e: any) => e.target.src = selectedPartyRep.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        isRepPartyOpen && !isRepPPOpenBC
                        &&
                        !selectedPartyRep.image_src
                        &&
                        <div className="BCWimgconthalf">
                            <img className="windowimgboxNW" src={selectedPartyRep.image_url}
                            ></img>
                        </div>
                    }
                    {
                        isRepPPOpenBC
                        &&
                        selectedPPRepBC.image_src
                                            &&
                                            <div className="BCWimgconthalf">
                                            <img className="windowimgboxNW" src={selectedPPRepBC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepBC.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        isRepPPOpenBC
                        &&
                        !selectedPPRepBC.image_src
                        &&
                        <div className="BCWimgconthalf">
                            <img className="windowimgboxNW" src={selectedPPRepBC.image_url}
                            ></img>
                        </div>
                    }

                    <div ref={mapContainerBC} className="mapcontainerhalf" />
            </div>
            </>
    )
}

export default BarAndClubWindow;