import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import mapboxgl from 'mapbox-gl';

import {
    IonPage,
    IonContent,
    IonicSlides,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonHeader,
    IonModal,
    IonMenuButton
} from '@ionic/react';

import { 
    globeOutline,
    callOutline,
    logoFacebook,
    logoInstagram,
    heartOutline,
    heartSharp,
    alertCircle,
    shareOutline,
    close,
    arrowBack,
    calendarOutline,
    timeOutline,
    arrowForwardOutline,
    tvOutline,
    logoTiktok,
    logoSoundcloud
} from 'ionicons/icons';

// import NewWindPPSimp from './NewWindPPSimp';


// import 'swiper/css';

import '../theme/windowstyle.css';
import '../theme/gridview.css';
import '../theme/maprelated.css';
import '../theme/swipers.css';


mapboxgl.accessToken = 'pk.eyJ1IjoiZHVuY2FuZ2VvZmZyZXkiLCJhIjoiY2w3MWNmYW40MG41OTN2bWp5a2dmeG8yeSJ9.k_fvb4VrR2wKN5_FZDvS7w';


const CircWindow: React.FC<{
    handleCloseWindBC:any;
    selectedBarClub: any;
    barsAndClubs: any[];
    parties: any[];
    currentDateAndTime: any;
    currentDateAndTimePlusTwoWeeks: any
    // likedBarsAndClubs: any;
    // setLikedBarsAndClubs: any;
    // handleIncrement: any;
    // handleDecrement: any;
    gridNo: any;
    peopleOth: any;
    handleIncrClick: any;
}> = (props) => {

    

    // theres no reload in bars and clubs so current date plus two weeks would depend on poeple relaoding .. but i assume given its two weeks it wont ever get that off..like would app reload are a certain period


    const [imgOrTextPC, setImgOrTextPC] = useState<any>("text");
    const [imgOrMapPC, setImgOrMapPC] = useState<any>("img");


    // const [isRepPartyOpen, setIsRepPartyOpen] = useState<any>(false);
    // const [selectedPartyRep, setSelectedPartyRep] = useState<any>(null);

    const [isRepPPOpenPC, setIsRepPPOpenPC] = useState(false);
    const [selectedPPRepPC, setSelectedPPRepPC] = useState<any>(null);

    
    const mapContainerPC = useRef<any>(null);

    const mapContainerPC2 = useRef<any>(null);

    const handleImgOrTextPCW = (viewClicked: string) => {
        console.log(viewClicked, imgOrTextPC, 'check whats happening');
        if (viewClicked === imgOrTextPC) return;
        if (viewClicked === "img") setImgOrTextPC("img");
        if (viewClicked === "text") setImgOrTextPC("text");
      };

      const handleImgOrMapPCW = (viewClicked: string) => {
        if (viewClicked === imgOrTextPC) return;
        if (viewClicked === "img") setImgOrMapPC("img");
        if (viewClicked === "map") setImgOrMapPC("map");
      };

      

      const handleOpenWindPPRepPC = (person:any) => {
        // setSelectedPartyRep(null);
        setSelectedPPRepPC(person);
        setImgOrTextPC("text");
        setImgOrMapPC("img");
        setIsRepPPOpenPC(true);
      }

      const handleClBackWindPPRepPC = () => {
        // setIsRepPPOpenBC(false);
        setSelectedPPRepPC(null);
        // setSelectedPPRep(null);
        //above not necessary since comp will be demounted
        setImgOrTextPC("text");
        setImgOrMapPC("img");
        setIsRepPPOpenPC(false);
      }

      const handleClickLinkPC = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedBarClub.website, '_system', 'location=yes');
        // props.handleIncrClick("parties", props.selectedParty.hash);
    }

    const handleClickLinkPCInsta = (e:any) => {
        e.stopPropagation();
        window.open(`https://www.instagram.com/${props.selectedBarClub.instagram}`, '_system', 'location=yes');
        // props.handleIncrClick("people", props.selectedPerson.hash);
    }


    const handleClickLinkPRep2 = (e:any) => {
        e.stopPropagation();
        window.open(props.selectedBarClub.website, '_system', 'location=yes');
        // props.handleIncrClick("circuit", selectedPPRepPC.hash);
    }

    useEffect(() => {
        console.log(imgOrTextPC, 'check pp');
    })


    useEffect(() => {

        // if (!mapContainerBC.current) {
        //     return
        // }
       

        const mapPC = new mapboxgl.Map({
            container: mapContainerPC.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [props.selectedBarClub.lng, props.selectedBarClub.lat],
            zoom: 8,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([props.selectedBarClub.lng, props.selectedBarClub.lat]).addTo(mapPC);

        

        return () => mapPC.remove();

    },[]);

    useEffect(() => {

        // if (!mapContainerBC.current) {
        //     return
        // }
        if (imgOrMapPC !== "map") {
            return
        }

        const mapPC = new mapboxgl.Map({
            container: mapContainerPC2.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [props.selectedBarClub.lng, props.selectedBarClub.lat],
            zoom: 8,
            attributionControl: false
        });

        new mapboxgl.Marker({color: '#FF00FF'}).setLngLat([props.selectedBarClub.lng, props.selectedBarClub.lat]).addTo(mapPC);

        

        return () => mapPC.remove();

    },[imgOrMapPC]);

 

    useEffect(() => {
        console.log(props.selectedBarClub, 'hello bc');
        console.log(props.parties.filter((party:any) => party.circuit === props.selectedBarClub.name), 'hello bc 2');
        console.log(props.parties, 'hello bc bc bc bc');
    })

    return(
        <>
        <div className="bigcardcont">
            <div className="BCtopcont">
                                    <div className="ionclosecontnorm">
                                            <IonIcon icon={close} className="closeic" onClick={props.handleCloseWindBC}></IonIcon>
                                    </div>

            </div>
            <div className="bigcardBC" onClick={(e) => e.stopPropagation()}>
                                    
                
                                    
                                <div className={(selectedPPRepPC) ? "segmentconBCWP" : "segmentconBCW"}>
                                            <div className={imgOrTextPC === "text" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrTextPCW("text")}
                                            >INFO</div>
                                            <div className={imgOrTextPC === "img" ? "segon" : "segoff"}
                                            onClick={()=>handleImgOrTextPCW("img")}
                                            >IMAGE</div>
                                </div>
                                
                                
                                
                                {
                                    (isRepPPOpenPC)
                                    &&
                                    <div className="segmentconrepBC"
                                            onClick={handleClBackWindPPRepPC}
                                            >
                                                <IonIcon icon={arrowBack} className="bckicn"></IonIcon>
                                </div>
                                }
                                

                    {
                        imgOrTextPC === "img"
                        &&
                        (!selectedPPRepPC)
                        &&
                        
                        props.selectedBarClub.image_src
                                            &&
                                            <div className="BCWimgcont">
                                            <img className="windowimgboxNW" src={props.selectedBarClub.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        imgOrTextPC === "img"
                        &&
                        (!selectedPPRepPC)
                        &&
                        !props.selectedBarClub.image_src
                        &&
                        <div className="BCWimgcont">
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        </div>
                    }

                <div className="BCWimgcontsm">
                                            {
                                                !isRepPPOpenPC
                                                &&
                                                <div className="segconNWBCoth">
                                                <div className={imgOrMapPC === "img" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapPCW("img")}
                                                >IMAGE</div>
                                                <div className={imgOrMapPC === "map" ? "segon" : "segoff"}
                                                onClick={()=>handleImgOrMapPCW("map")}
                                                >MAP</div>
                                            </div>
                                            }
                                            

                    {
                        imgOrMapPC === "map"
                        &&
                        <div ref={mapContainerPC2} className="mapcontainer" />
                    }

                    
                    
                    {
                        imgOrMapPC === "img"
                        &&
                        (isRepPPOpenPC)
                        &&
                        
                        selectedPPRepPC.image_src
                                            &&
                                            <img className="windowimgboxNW" src={selectedPPRepPC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepPC.image_url}
                                            ></img>
                    }
                    {
                        imgOrMapPC === "img"
                        &&
                        (isRepPPOpenPC)
                        &&
                        !selectedPPRepPC.image_src
                        &&
                            <img className="windowimgboxNW" src={selectedPPRepPC.image_url}
                            ></img>
                    }
                    {
                        imgOrMapPC === "img"
                        &&
                        (!selectedPPRepPC)
                        &&
                        
                        props.selectedBarClub.image_src
                                            &&
                                           
                                            <img className="windowimgboxNW" src={props.selectedBarClub.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub.image_url}
                                            ></img>
                                           
                    }
                    {
                        imgOrMapPC === "img"
                        &&
                        (!selectedPPRepPC)
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                       
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        
                    }

                    

                </div>



                    
                    

                    {
                        imgOrTextPC === "img"
                        &&
                        isRepPPOpenPC
                        &&
                        
                        selectedPPRepPC.image_src
                                            &&
                                            <div className="BCWimgcont">
                                            <img className="windowimgboxNW" src={selectedPPRepPC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepPC.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        imgOrTextPC === "img"
                        &&
                        isRepPPOpenPC
                        &&
                        !selectedPPRepPC.image_src
                        &&
                        <div className="BCWimgcont">
                            <img className="windowimgboxNW" src={selectedPPRepPC.image_url}
                            ></img>
                        </div>


                    }

                    
            {
                            // imgOrTextBC === "text"
                            // &&
                            !isRepPPOpenPC
                        &&
            <IonContent className={imgOrTextPC === "text" ? "contentBBCW" : "nocontentBBCW"}>
              
      
                <div className="windowcontainerwithgrad">
                        
                            
                    

                    <div className="windowmainsetwidthNW">
                        

                                

                        <div className="windowtitleboxBCW">
                            
                                    <div className="windowtitlefullNW">
                                        {props.selectedBarClub.name}
                                    </div>
                        </div>

                       
                                            {/* <div className="windowdatetimecont">
                                                <div className="datetimeboxfll">
                                                    <IonIcon icon={calendarOutline} className="datetimeicon" />
                                                    <div className="datebox">
                                                        <div className="datetimetext">
                                                            {props.selectedBarClub?.start_day.slice(0,3)}{", "}{props.selectedBarClub?.start_date}{" "}{props.selectedBarClub?.start_month.slice(0,3)}{props.selectedBarClub?.start_year !== props.selectedBarClub?.finish_year ? ` ${props.selectedBarClub?.start_year}` : ""} to {props.selectedBarClub?.finish_day.slice(0,3)}{", "}{props.selectedBarClub?.finish_date}{" "}{props.selectedBarClub?.finish_month.slice(0,3)}{" "}{props.selectedBarClub?.finish_year}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                               
                                            </div> */}

                                            <div className="gridviewsectionheadercirc2">
                                                <div className="gridleftline">
                                                <div className="topline"></div>
                                                <div className="botline"></div>
                                                </div>
                                                    <div className="gridviewsectionname">{props.selectedBarClub?.city}{", "}{props.selectedBarClub?.country}
                                                    </div>
                                                <div className="gridrightline">
                                                <div className="topline"></div>
                                                <div className="botline"></div>
                                                </div>
                                            </div>

                                            <div className="gridviewsectionheadercirc">
                                                <div className="gridleftline2">
                                                <div className="topline2"></div>
                                                <div className="botline2"></div>
                                                </div>
                                                    <div className="gridviewsectionname2">{props.selectedBarClub?.start_day.slice(0,3)}{", "}{props.selectedBarClub?.start_date}{" "}{props.selectedBarClub?.start_month.slice(0,3)}{props.selectedBarClub?.start_year !== props.selectedBarClub?.finish_year ? ` ${props.selectedBarClub?.start_year}` : ""}{" - "}{props.selectedBarClub?.finish_day.slice(0,3)}{", "}{props.selectedBarClub?.finish_date}{" "}{props.selectedBarClub?.finish_month.slice(0,3)}{" "}{props.selectedBarClub?.finish_year}
                                                    </div>
                                                <div className="gridrightline">
                                                <div className="topline2"></div>
                                                <div className="botline2"></div>
                                                </div>
                                            </div>
                                            
                        
                    
                        {
                        (props.selectedBarClub?.description !== "" || props.selectedBarClub.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>
                            <div className="windowmaintextboxstd">
                                {props.selectedBarClub.description}
                            </div>
                            {
                            props.selectedBarClub.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{props.selectedBarClub.extra_info}</div>
                            </div>
                            }
                        </div>
                        }
                        {
                                props.parties.filter((party:any) => party.circuit === props.selectedBarClub.name).length > 0
                                &&
                    <div className="windowmainboxstd">
                        <div className="windowmainheadingstd">
                                Parties
                        </div>
                        {/* <Link to={`/cruises/${selectedCruise?.id}/map`} className="canichangelinkcruises"> */}
                        {/* <div className="viewonmapcruises"> */}
                            {/* <IonButton fill="outline" color="primary" size="small">Map view</IonButton> */}
                            
                            
                            {/* <div className="mapviewbutton"
                            onClick={() => setIsWindowMapOpenDE(true)}
                            >View map</div> */}
                        <div className="pricingflexboxcirccontainer">
                            {
                                props.parties.filter((party:any) => party.circuit === props.selectedBarClub.name)
                                .map((option: any, index: number) => {
                                    return <div key={index} className="pricingoptionscircname">{option.name}
                                            </div>
                                            
                             
                                })
                            }

                        </div>

                    </div>
                    }

                                            {
                                            (props.selectedBarClub?.entertainers !== "" || props.selectedBarClub.entertainers.length === 0)
                                            &&
                                            props.peopleOth.filter((person: any) => props.selectedBarClub?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                            &&
                                            <div className="windowmainheadingstd">
                                                Featured entertainers
                                            </div>
                                            }




                        


                    </div> 
                    {/* end of first set width main section */}


                                    {
                                        (props.selectedBarClub?.entertainers !== "" || props.selectedBarClub.entertainers.length === 0)
                                        &&
                                        props.peopleOth.filter((person: any) => props.selectedBarClub?.entertainers.some((entertainer: any) => entertainer === person.name)).length > 0
                                        &&
                                        <div className="windowgrid"
                                        // style={{gridTemplateColumns: `repeat(${Math.max(Math.min(props.gridNo-1, 3),2)}, calc(100% / ${Math.max(Math.min(props.gridNo-1, 3),2)}))`}}
                                        >
                                            {props.peopleOth.filter((person: any) => props.selectedBarClub?.entertainers.some((entertainer: any) => entertainer === person.name))
                                            .map((person: any, index: any) => {
                                                return <div key={index} className="gridviewcardstd"
                                                onClick={() => handleOpenWindPPRepPC(person)}
                                                >
                                                                {
                                                                    person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_src} alt=""
                                                                    onError={(e: any) => e.target.src = person.image_url}
                                                                    />
                                                                }
                                                                {
                                                                    !person.image_src
                                                                    &&
                                                                    <img className="gridviewcardimage" src={person.image_url} alt="" />
                                                                }
                                                                
                                                                <div className="gridviewcardimagecover">
                                                                    {
                                                                    person.type[0] === "Drag queen"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedrag ">Drag</div>
                                                                    }      
                                                                    {
                                                                    person.type[0] === "DJ"
                                                                    &&
                                                                    <div className="gridviewtypebubble typedj">DJ</div>
                                                                    }         
                                                                    {
                                                                    person.type[0] === "Singer"
                                                                    &&
                                                                    <div className="gridviewtypebubble typesing">Singer</div>
                                                                    }          
                                                                    {
                                                                    person.type[0] === "Other"
                                                                    &&
                                                                    <div className="gridviewtypebubble typeoth">Other</div>
                                                                    }
                                                                    
                                                                    
                                                                    <div className="gridviewbottomcontainermod">               
                                                            
                                                                            <div className="gridviewtitlecontainerfull">
                                                                                <div className="gridviewtitlefull">
                                                                                    {person.name}
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            })}
                                        </div>
                                        }
                    
                </div>   
                                              
            </IonContent>
            }

                    {
                        isRepPPOpenPC
                        &&
                        // imgOrTextBC === "text"
                        //     &&
                        <IonContent className={imgOrTextPC === "text" ? "contentBBCW" : "nocontentBBCW"}>
                
                

                                    
                    
                        
                <div className="windowcontainerwithgrad">

               
                        

                    <div className="windowmainsetwidthNW">

                        <div className="windowtitleboxBCW">
                                
                                <div className="windowtitlefullNW">
                                    {selectedPPRepPC.name}
                                </div>
                        </div>

                        <div className="windowmainboxstd">
                                <div className="windowmaintextboxstd seeothersecbox">
                                    For a full list of {selectedPPRepPC.name}{"'"}s upcoming performances, please refer to the {"'"}LGBT+ Entertainers{"'"} section of the website.
                                </div>
                            </div>


                        
                   


                        

                        {
                        (selectedPPRepPC.description !== "" || selectedPPRepPC.extra_info !== "")
                        &&
                        <div className="windowmainboxstd">
                            <div className="windowmainheadingstd">
                                About
                            </div>
                            <div className="windowmaintextboxstd">
                                {selectedPPRepPC.description}
                            </div>
                            {
                            selectedPPRepPC.extra_info !== ""
                            &&
                            <div className="windowmaintextboxext">
                                <div className="exclambox">
                                    <IonIcon icon={alertCircle} className="exclam">
                                    </IonIcon>
                                </div>
                                <div className="exclamtext">{selectedPPRepPC.extra_info}</div>
                            </div>
                            }
                        </div>
                        }

{
                            selectedPPRepPC.tv_shows.length > 0
                            &&
                            <div className="windowmainboxstd">
                                    <div className="windowmainheadingstd">
                                        Credits
                                    </div>  
                                    {
                                       selectedPPRepPC.tv_shows.map((ele: any, index: number) => {
                                            return <div key={index} className="tvshowlistcont">
                                                        <div className="tvshowlistitem">
                                                            <IonIcon icon={tvOutline} size="small" color="primary" />
                                                        </div>
                                                        <div className="tvshowlistitem">{ele}</div>
                                                </div>
                                        })
                                    }
                            </div>
                            }

                        

                           
                               
                    
                    </div> 
                  
                   

                        

                        

                    

                    
                    
                </div>   
                                              
            </IonContent>
                    }



            </div>


            {
                           !selectedPPRepPC && props.selectedBarClub.website
                        &&
                        <div className="BCbotcont">
                        <div className="flotbtncontBC">
                        {
                                                        (props.selectedBarClub.tickets_available)
                                                        &&
                                                        <div className="flotbtncontBC">
            
                                                            
                                                        
                                                            <div className="mainwindowbtnweb"
                                                            onClick={handleClickLinkPRep2}
                                                            >
                                                                <div className="floatbtnskew">
                                                                    GET TICKETS
                                                                    <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                                </div>
                                                            </div>
            
                                                        </div>
                                                        }
                                                        
                                                        {
                                                        (!props.selectedBarClub.tickets_available)
                                                        &&
                                                        <div className="flotbtncontBC">
            
                                                            
            
                                                            <div className="mainwindowbtnweb"
                                                            onClick={handleClickLinkPRep2}
                                                            >
                                                                <div className="floatbtnskew">
                                                                    MORE INFO
                                                                    <IonIcon icon={arrowForwardOutline} className="clickawayarrow" />
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                        }
            
                                                        
                                    
            
                                    
            
                                    
            
                                    
                                        
                                                        
                                                       
                                </div>
                        </div>
            }

            {
                            // imgOrTextBC === "text"
                            // &&
                            isRepPPOpenPC
                        &&
            <div className="BCbotcont">
            <div className="flotbtncontBC">
          
                                          
            {
                               selectedPPRepPC.soundcloud !== ""
                                &&
                                <div className="socialmediabtnwebBC btnmarg"
                                // onClick={handleClickLinkPPSC}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlg" />
                                </div>
                            </div>
                            }
                            {
                                selectedPPRepPC.soundcloud === ""
                                &&
                                <div className="socialmediabtnwebBC btnmarg cursorno"
                                // onClick={()=>window.open(`https://www.soundcloud.com/${selectedPerson?.soundcloud}`, '_system', 'location=yes')}
                                onClick={(e) => e.stopPropagation()}
                                >
                                <div className="floatbtnskewsocialmed">
                                <IonIcon icon={logoSoundcloud} className="sharebtniconlgx btnopac" />
                                </div>
                            </div>
                            }

                        

                        {
                            selectedPPRepPC.website !== ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg"
                            // onClick={handleClickLinkPP}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPPRepPC.website === ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg cursorno"
                            // onClick={()=>window.open(selectedPerson?.website, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={globeOutline} className="sharebtniconlgx btnopac" />
                            </div>
                        </div>
                        }
                        


                        <div className="socialmediabtninstawebBC instabtn btnmarg"
                        // onClick={handleClickLinkPPInst}
                        >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoInstagram} className="sharebtniconinsta" />
                            </div>
                        </div>

                        {/* spotify now equals tiktok */}
                        {
                            selectedPPRepPC.spotify !== ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg"
                            // onClick={handleClickLinkPPTT}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlg" />
                            </div>
                        </div>
                        }
                        {
                            selectedPPRepPC.spotify === ""
                            &&
                            <div className="socialmediabtnwebBC btnmarg cursorno"
                            // onClick={()=>window.open(`https://www.youtube.com/${selectedPerson?.youtube}`, '_system', 'location=yes')}
                            onClick={(e) => e.stopPropagation()}
                            >
                            <div className="floatbtnskewsocialmed">
                            <IonIcon icon={logoTiktok} className="sharebtniconlgx btnopac" />
                            </div>
                        </div>
                        }

                        

                        
                            
                                            
                                           
                    </div>
            </div>
            }
            </div>
            <div className="mapimgcont">
                        {
                        !isRepPPOpenPC
                        &&
                        props.selectedBarClub?.image_src
                                            &&
                                            <div className="BCWimgconthalf">
                                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_src}
                                            onError={(e: any) => e.target.src = props.selectedBarClub?.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        !isRepPPOpenPC
                        &&
                        !props.selectedBarClub?.image_src
                        &&
                        <div className="BCWimgconthalf">
                            <img className="windowimgboxNW" src={props.selectedBarClub?.image_url}
                            ></img>
                        </div>
                    }
                    
                    {
                        isRepPPOpenPC
                        &&
                        selectedPPRepPC.image_src
                                            &&
                                            <div className="BCWimgconthalf">
                                            <img className="windowimgboxNW" src={selectedPPRepPC.image_src}
                                            onError={(e: any) => e.target.src = selectedPPRepPC.image_url}
                                            ></img>
                                            </div>
                    }
                    {
                        isRepPPOpenPC
                        &&
                        !selectedPPRepPC.image_src
                        &&
                        <div className="BCWimgconthalf">
                            <img className="windowimgboxNW" src={selectedPPRepPC.image_url}
                            ></img>
                        </div>
                    }

                    <div ref={mapContainerPC} className="mapcontainerhalf" />
            </div>
            </>
    )
}

export default CircWindow;